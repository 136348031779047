import s from './styles.module.css'


const Spinner = ({textOfSpinner}) => {
    return (
        <div className={s['content']}>
            <div className={s["spinner"]}>
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="31.4092" width="2.66822" height="16.0093" rx="1.33411" fill="#C4C4C4"/>
                    <rect x="0.724609" y="33.353" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(-90 0.724609 33.353)" fill="#DDDDDD"/>
                    <rect x="17.8892" y="60.415" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(-150 17.8892 60.415)" fill="#DDDDDD"/>
                    <rect x="49.908" y="59.0806" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(150 49.908 59.0806)" fill="#575757"/>
                    <rect x="61.1394" y="46.8726" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(120 61.1394 46.8726)" fill="#6D6D6D"/>
                    <rect x="59.8054" y="14.8535" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(60 59.8054 14.8535)" fill="#989898"/>
                    <rect x="34.0774" y="64.0376" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(180 34.0774 64.0376)" fill="#414141"/>
                    <rect x="64.762" y="30.6846" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(90 64.762 30.6846)" fill="#828282"/>
                    <rect x="47.5974" y="3.62256" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(30 47.5974 3.62256)" fill="#AEAEAE"/>
                    <rect x="15.5786" y="4.95654" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(-30 15.5786 4.95654)" fill="#D9D9D9"/>
                    <rect x="4.34717" y="17.165" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(-60 4.34717 17.165)" fill="#EFEFEF"/>
                    <rect x="5.6814" y="49.1836" width="2.66822" height="16.0093" rx="1.33411"
                          transform="rotate(-120 5.6814 49.1836)" fill="#E7E7E7"/>
                </svg>
            </div>
            <p className={s['text-style']}>{textOfSpinner}</p>
        </div>

    )
}
export default Spinner