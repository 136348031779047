export function FilialIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9902 22.4985V13.481L20.2344 8.77002C20.2812 8.9458 20.3047 9.16846 20.3047 9.43799V16.2847C20.3047 16.9233 20.1963 17.395 19.9795 17.6997C19.7686 17.9985 19.458 18.2651 19.0479 18.4995L12.166 22.4194C12.1367 22.437 12.1074 22.4517 12.0781 22.4634C12.0488 22.481 12.0195 22.4927 11.9902 22.4985ZM10.7861 22.4985C10.7568 22.4927 10.7275 22.481 10.6982 22.4634C10.6748 22.4517 10.6484 22.437 10.6191 22.4194L3.72852 18.4995C3.32422 18.2651 3.01367 17.9985 2.79688 17.6997C2.58008 17.395 2.47168 16.9233 2.47168 16.2847V9.43799C2.47168 9.16846 2.49512 8.9458 2.54199 8.77002L10.7861 13.481V22.4985ZM11.3926 12.4263L3.10449 7.72412C3.2334 7.60693 3.39746 7.49268 3.59668 7.38135L6.82227 5.54443L15.1455 10.2993L11.3926 12.4263ZM16.3672 9.60498L8.00879 4.85889L9.79297 3.84814C10.3379 3.53174 10.8711 3.37354 11.3926 3.37354C11.9082 3.37354 12.4385 3.53174 12.9834 3.84814L19.1885 7.38135C19.3818 7.49268 19.543 7.60693 19.6719 7.72412L16.3672 9.60498Z"
        fill="#100E71"
      />
    </svg>
  );
}
