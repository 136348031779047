import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { useMutation, useQueryClient } from 'react-query';
import { axiosInstances } from '../../../api/axios-instances';
import CustomModal from '../../UI/Modal/Modal';
import ArrayIsEmpty from '../../UI/Array-is-empty/Array-is-empty';
import s from './styles.module.css';
import { jwtDecode } from 'jwt-decode';
import Pagination from '../../UI/Pagination/Pagination';
import { Form, Input, Select, Typography } from 'antd';
import { useBuyers, useCities, useFilias } from '../../../api/user-list-api/user-list-api';
import { Option } from 'antd/es/mentions';
import CustomSelector from '../../UI/CustomSelector/CustomSelector';
import { useForm } from 'antd/es/form/Form';
import Cookies from 'js-cookie';

const EditUserModal = ({ visible, onClose, inputData, onChange, onSave, translation }) => (
    <CustomModal visible={visible} onClose={onClose}>
        <div className={s['modal-div']}>
            <p className={s['modal-title']}>{translation('refUser')}</p>
            <div className={s['input-div']}>
                <div className={s['input-container']}>
                    <input
                        className={s['input-modal']}
                        name="fullName"
                        value={inputData.fullName}
                        onChange={onChange}
                    />
                    <label
                        className={`${s['floating-label']} ${inputData.fullName ? s.filled : ''}`}
                    >
                        {translation('nameUserInput')}
                    </label>
                </div>
                <div className={s['input-container']}>
                    <input
                        className={s['input-modal']}
                        name="lastName"
                        value={inputData.lastName}
                        onChange={onChange}
                    />
                    <label
                        className={`${s['floating-label']} ${inputData.lastName ? s.filled : ''}`}
                    >
                        {translation('surnameUserInput')}
                    </label>
                </div>
                <div className={s['input-container']}>
                    <input
                        className={s['input-modal']}
                        name="phone"
                        value={inputData.phone}
                        onChange={onChange}
                        type={'number'}
                    />
                    <label className={`${s['floating-label']} ${inputData.phone ? s.filled : ''}`}>
                        {translation('phone')}
                    </label>
                </div>
            </div>
            <div className={s['modal-button']}>
                <button className={s['button-modal']} onClick={onClose}>
                    {translation('otmena')}
                </button>
                <button className={s['button-modal']} onClick={onSave}>
                    {translation('save')}
                </button>
            </div>
        </div>
    </CustomModal>
);
const CreateOrderByAdmin = ({
    visible,
    onClose,
    inputData,
    onChange,
    onSave,
    translation,
    handleChangeCity,
    citiesData,
    handleChangeFilial,
    filiasData,
    handleCreateUser,
    successModal,
    role,
}) => {
    const handleSave = () => {
        onSave();
    };
    return (
        <>
            <CustomModal visible={visible} onClose={onClose}>
                <div className={s['modal-div']}>
                    <p className={s['modal-title']}>{translation('orderToChina')}</p>
                    <div className={s['input-div']}>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']}`}
                                name="name"
                                value={inputData.name}
                                onChange={onChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.name ? s.filled : ''}`}
                            >
                                {translation('writeOrderName')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']}`}
                                name="track_code"
                                value={inputData.track_code}
                                onChange={onChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.track_code ? s.filled : ''}`}
                            >
                                {translation('writeTrackCode')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} `}
                                name="price"
                                type="number"
                                value={inputData.price || ''}
                                onChange={onChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.price ? s.filled : ''}`}
                            >
                                {translation('writePrice')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} `}
                                name="weight"
                                type="number"
                                value={inputData.weight || ''}
                                onChange={onChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.weight ? s.filled : ''}`}
                            >
                                {translation('writeWeight')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} `}
                                name="amount"
                                type="number"
                                value={inputData.amount || ''}
                                onChange={onChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.amount ? s.filled : ''}`}
                            >
                                {translation('writeAmount')}
                            </label>
                        </div>
                    </div>
                    <div className={s['modal-button']}>
                        <button className={s['button-modal']} onClick={onClose}>
                            {translation('otmena')}
                        </button>
                        <button className={s['button-modal']} onClick={handleSave}>
                            {translation('save')}
                        </button>
                    </div>
                </div>
            </CustomModal>
            <CustomModal visible={successModal} onClose={onClose}>
                <div className={s['done-modal']}>
                    <p className={s['userPlus']}>{translation('succesOderCreate')}</p>
                    <svg
                        width="101"
                        height="101"
                        viewBox="0 0 101 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M50.1357 100.842C43.3057 100.842 36.8678 99.5346 30.822 96.9202C24.8089 94.3058 19.4985 90.6947 14.8906 86.0869C10.2828 81.479 6.67169 76.1686 4.05731 70.1555C1.44293 64.1098 0.135742 57.6719 0.135742 50.8418C0.135742 44.0117 1.44293 37.5902 4.05731 31.5771C6.67169 25.5313 10.2665 20.2045 14.8416 15.5967C19.4495 10.9889 24.7599 7.37774 30.773 4.76337C36.8187 2.14899 43.2567 0.841797 50.0867 0.841797C56.9168 0.841797 63.3547 2.14899 69.4005 4.76337C75.4462 7.37774 80.773 10.9889 85.3808 15.5967C89.9887 20.2045 93.5998 25.5313 96.2142 31.5771C98.8286 37.5902 100.136 44.0117 100.136 50.8418C100.136 57.6719 98.8286 64.1098 96.2142 70.1555C93.5998 76.1686 89.9887 81.479 85.3808 86.0869C80.773 90.6947 75.4462 94.3058 69.4005 96.9202C63.3874 99.5346 56.9658 100.842 50.1357 100.842ZM44.6455 74.7634C45.4952 74.7634 46.2632 74.5673 46.9495 74.1751C47.6357 73.783 48.2403 73.1947 48.7632 72.4104L71.8024 36.0869C72.0965 35.5967 72.3743 35.0738 72.6357 34.5183C72.8972 33.9627 73.0279 33.4072 73.0279 32.8516C73.0279 31.7078 72.6031 30.8091 71.7534 30.1555C70.9037 29.4692 69.956 29.1261 68.9103 29.1261C67.4723 29.1261 66.2959 29.8777 65.3808 31.381L44.4495 65.0085L34.4985 52.1653C33.8776 51.3483 33.273 50.7928 32.6848 50.4987C32.1292 50.2045 31.492 50.0575 30.773 50.0575C29.6619 50.0575 28.7305 50.466 27.9789 51.283C27.2272 52.0673 26.8514 53.015 26.8514 54.1261C26.8514 54.6817 26.9495 55.2372 27.1455 55.7928C27.3743 56.3157 27.6684 56.8222 28.0279 57.3124L40.3318 72.4104C40.9854 73.2601 41.6553 73.8647 42.3416 74.2242C43.0279 74.5836 43.7959 74.7634 44.6455 74.7634Z"
                            fill="white"
                        />
                    </svg>
                    <button className={s['button-done']} onClick={onClose}>
                        {translation('continue')}
                    </button>
                </div>
            </CustomModal>
        </>
    );
};

const UserInformationTable = ({
    handlePageChange,
    handlePageSizeChange,
    refetch,
    initialData,
    translation,
    language,
    pageSize,
    setPageSize,
    page,
    setPage,
}) => {
    const queryClient = useQueryClient();
    const [filteredData, setFilteredData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openModalTurkeyUse, setOpenModalTurkeyUse] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedBuyer, setSelectedBuyer] = useState(null);
    const [inputData, setInputData] = useState({ fullName: '', lastName: '', phone: '' });
    const [orderCreate, setOrderCreate] = useState({
        name: '',
        track_code: '',
        price: 0,
        weight: '',
        amount: '',
        customer_id: 0,
        city_id: 0,
        filial_id: 0,
    });
    const [orderCreateTurkey, setOrderCreateTurkey] = useState({
        name: '',
        track_code: '',
        price: 0,
        weight: 0,
        amount: '',
        customer_id: 0,
        city_id: 0,
        filial_id: 0,
        order_source: '',
        buyer_id: 0,
    });
    const [successModal, setSuccessModal] = useState(false);
    const [createOpenModal, setCreateOpenModal] = useState(false);
    const [role, setRole] = useState(null);

    const [selectedCity, setSelectedCity] = useState(null);
    const [filial, setFilial] = useState(null);
    const { data: citiesData } = useCities();
    const { data: buyersData } = useBuyers();
    const { data: filiasData } = useFilias(selectedCity);
    const [form] = useForm();
    const handleChangeCity = (value) => {
        setSelectedCity(value);
        setFilial(null);
        setInputData((prevState) => ({
            ...prevState,
            filialId: null,
        }));
    };
    const handleChangeFilial = (value) => {
        setFilial(value);
        setInputData((prevState) => ({
            ...prevState,
            filialId: value,
        }));
    };
    const handleChangeOrderSource = (value) => {
        setSelectedBuyer(value);
        setOrderCreateTurkey((prevState) => ({
            ...prevState,
            order_source: value,
        }));
    };
    const handleChangeBuyer_d = (value) => {
        setOrderCreateTurkey((prevState) => ({
            ...prevState,
            buyer_id: value,
        }));
    };

    useEffect(() => {
        if (initialData && initialData.content) {
            setFilteredData(initialData.content);
        }
    }, [initialData]);
    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setRole(decodedToken.role);
            } catch (error) {
                console.error('Ошибка декодирования токена', error);
                setRole('');
            }
        } else {
            setRole('');
        }
    }, []);

    const deleteUserMutation = useMutation({
        mutationFn: async (id) => {
            const response = await axiosInstances.put(
                `/amantrans/customers/is-active-change/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                    },
                },
            );
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['customers']);
        },
        onError: (error) => {
            console.error('Error changing customer status:', error);
        },
    });

    const updateUserMutation = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.put(
                `/amantrans/customers/admin/update/${user.id}`,
                user,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                    },
                },
            );
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['customers']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });
    const createOrderByAdmin = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.post(`/amantrans/orders/create`, user, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['customers']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });
    const createOrderByAdminTurkey = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.post(
                `/amantrans/order_turkey/admin/create`,
                user,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['customers']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });

    const handleDeleteRow = (id) => {
        deleteUserMutation.mutate(id, {
            onSuccess: () => {
                refetch();
            },
            onError: (error) => {
                console.error('Error deleting row:', error);
            },
        });
    };

    const handleSaveUserChanges = () => {
        const updateData = {
            id: selectedUser.id,
            fullName: inputData.fullName,
            lastName: inputData.lastName,
            phone: inputData.phone,
        };
        updateUserMutation.mutate(updateData, {
            onSuccess: () => {
                setOpenModal(false);
                setSelectedUser(null);
                setInputData({ fullName: '', lastName: '', phone: '' });
                refetch();
            },
            onError: (error) => {
                console.error('Error updating customer:', error);
            },
        });
    };
    const handleSaveOrder = () => {
        const updateData = {
            name: orderCreate.name,
            track_code: orderCreate.track_code,
            price: orderCreate.price,
            weight: orderCreate.weight,
            amount: orderCreate.amount,
            customer_id: selectedUser.id,
        };
        createOrderByAdmin.mutate(updateData, {
            onSuccess: () => {
                setSuccessModal(true);
                setCreateOpenModal(false);
                setSelectedUser(null);
                setOrderCreate({
                    name: '',
                    track_code: '',
                    price: 0,
                    weight: '',
                    amount: '',
                    customer_id: 0,
                });
                refetch();
            },
            onError: (error) => {
                console.error('Error updating customer:', error);
            },
        });
    };
    const handleSaveOrderTirkey = () => {
        const updateData = {
            name: orderCreateTurkey.name,
            track_code: orderCreateTurkey.track_code,
            price: Number(orderCreateTurkey.price),
            weight: Number(orderCreateTurkey.weight),
            amount: orderCreateTurkey.amount,
            customer_id: selectedUser.id,
            tariff_id: filial,
            order_source: orderCreateTurkey.order_source,
            buyer_id: selectedBuyer === 'От Байера' ? orderCreateTurkey.buyer_id : null,
        };
        createOrderByAdminTurkey.mutate(updateData, {
            onSuccess: () => {
                setSuccessModal(true);
                setOpenModalTurkeyUse(false);
                setOrderCreateTurkey(false);
                setSelectedUser(null);
                setOrderCreateTurkey({
                    name: '',
                    track_code: '',
                    price: 0,
                    weight: 0,
                    amount: '',
                    customer_id: 0,
                    city_id: null,
                    filial_id: null,
                    tariff_id: 0,
                    order_source: null,
                    buyer_id: null,
                });
                refetch();
            },
            onError: (error) => {
                console.error('Error updating customer:', error);
            },
        });
    };

    const handleOpenModal = (row) => {
        setSelectedUser(row.original);
        setInputData({
            fullName: row.original.fullName,
            lastName: row.original.lastName,
            phone: row.original.phone,
        });
        setOpenModal(true);
    };
    const handleCreateOpenModal = (row) => {
        setSelectedUser(row.original);
        setOrderCreate({
            name: '',
            track_code: '',
            price: 0,
            weight: '',
            amount: '',
            customer_id: row.original.id,
        });
        setCreateOpenModal(true);
    };

    const typeBuyer = ['От Байера', 'От оптовика', 'Само-зарегистрированный'];
    const handleCloseModal = () => {
        setOpenModal(false);
        setSuccessModal(false);
    };
    const handleCreateCloseModal = () => {
        setCreateOpenModal(false);
        setSuccessModal(false);
        setSelectedUser(null);
        setOrderCreate({
            name: '',
            track_code: '',
            price: 0,
            weight: '',
            amount: '',
            customer_id: 0,
        });
    };
    const openModalTurkey = (row) => {
        setSelectedUser(row.original);
        setOpenModalTurkeyUse(true);
    };
    const handleCloseModalTurkey = () => {
        setOpenModalTurkeyUse(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData((prevState) => ({ ...prevState, [name]: value }));
    };
    const handleInputCreate = (e) => {
        const { name, value } = e.target;
        setOrderCreate((prevState) => ({ ...prevState, [name]: value }));
    };
    const columns = useMemo(
        () => [
            {
                Header: translation('fullNameWithLastName'),
                accessor: 'fullName',
                Cell: ({ row }) => (
                    <span>
                        {row.original.fullName} {row.original.lastName}
                    </span>
                ),
            },
            {
                Header: translation('idCode'),
                accessor: 'idCode',
            },
            {
                Header: translation('phone'),
                accessor: 'phone',
            },
            {
                Header: translation('createdDateCol'),
                accessor: 'createdDate',
                Cell: ({ value }) => new Date(value).toLocaleDateString(),
            },
            {
                Header: translation('cityName'),
                accessor: 'filialCityName',
            },
            {
                Header: translation('filialCol'),
                accessor: 'filialAddress',
            },
            {
                Header: translation('plus-information'),
                accessor: 'plusInformation',
                Cell: ({ row }) => (
                    <div className={s['button-div']}>
                        <button className={s['button-table']} onClick={() => handleOpenModal(row)}>
                            {translation('refactorsCol')}
                        </button>
                        <button
                            className={s['button-table']}
                            onClick={() => handleCreateOpenModal(row)}
                        >
                            {translation('orderToChina')}
                        </button>
                        <button className={s['button-table']} onClick={() => openModalTurkey(row)}>
                            {translation('orderToTurkey')}
                        </button>
                        {role === 'RoleLeadAdmin' && (
                            <button
                                className={s['button-table']}
                                onClick={() => handleDeleteRow(row.original.id)}
                            >
                                {translation('deleteCol')}
                            </button>
                        )}
                    </div>
                ),
            },
        ],
        [role, translation, language],
    );
    const totalPages = initialData && initialData.totalPages;
    const tableInstance = useTable({
        columns,
        data: filteredData,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    const options = [
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: 200, label: '200' },
    ];

    return (
        <>
            <div>
                <div>
                    <CustomSelector
                        options={options}
                        value={pageSize}
                        onChange={handlePageSizeChange}
                    />

                    <CustomModal visible={openModalTurkeyUse} onClose={handleCloseModalTurkey}>
                        <Form style={{ color: 'white', gap: '10px' }} layout="vertical" form={form}>
                            <Form.Item
                                name="name"
                                label={
                                    <Typography.Text
                                        style={{ color: 'white', margin: '0', padding: '0' }}
                                    >
                                        Название товара
                                    </Typography.Text>
                                }
                            >
                                <Input
                                    value={orderCreateTurkey.name}
                                    style={{ padding: '5px, 10px' }}
                                    placeholder={`Введите название товара`}
                                    className={s['register-input']}
                                    onChange={(e) =>
                                        setOrderCreateTurkey((prevState) => ({
                                            ...prevState,
                                            name: e.target.value,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name="track_code"
                                label={
                                    <Typography.Text
                                        style={{ color: 'white', margin: '0', padding: '0' }}
                                    >
                                        Трек код
                                    </Typography.Text>
                                }
                            >
                                <Input
                                    value={orderCreateTurkey.track_code}
                                    style={{ padding: '5px, 10px' }}
                                    placeholder={`Введите название трек кода`}
                                    className={s['register-input']}
                                    onChange={(e) =>
                                        setOrderCreateTurkey((prevState) => ({
                                            ...prevState,
                                            track_code: e.target.value,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name="price"
                                label={
                                    <Typography.Text
                                        style={{ color: 'white', margin: '0', padding: '0' }}
                                    >
                                        Цена
                                    </Typography.Text>
                                }
                            >
                                <Input
                                    value={orderCreateTurkey.price}
                                    type={'number'}
                                    style={{ padding: '5px, 10px' }}
                                    placeholder={`Введите цену товара`}
                                    className={s['register-input']}
                                    onChange={(e) =>
                                        setOrderCreateTurkey((prevState) => ({
                                            ...prevState,
                                            price: e.target.value,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name="weight"
                                label={
                                    <Typography.Text
                                        style={{ color: 'white', margin: '0', padding: '0' }}
                                    >
                                        Вес товара
                                    </Typography.Text>
                                }
                            >
                                <Input
                                    value={orderCreateTurkey.weight}
                                    type={'number'}
                                    style={{ padding: '5px, 10px' }}
                                    placeholder={`Введите вес товара`}
                                    className={s['register-input']}
                                    onChange={(e) =>
                                        setOrderCreateTurkey((prevState) => ({
                                            ...prevState,
                                            weight: e.target.value,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name="amount"
                                label={
                                    <Typography.Text
                                        style={{ color: 'white', margin: '0', padding: '0' }}
                                    >
                                        Количества товара
                                    </Typography.Text>
                                }
                            >
                                <Input
                                    value={orderCreateTurkey.amount}
                                    type={'number'}
                                    style={{ padding: '5px, 10px' }}
                                    placeholder={`Введите количества товара`}
                                    className={s['register-input']}
                                    onChange={(e) =>
                                        setOrderCreateTurkey((prevState) => ({
                                            ...prevState,
                                            amount: e.target.value,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name="order_source"
                                label={
                                    <Typography.Text
                                        style={{ color: 'white', margin: '0', padding: '0' }}
                                    >
                                        Продавец
                                    </Typography.Text>
                                }
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={handleChangeOrderSource}
                                    placeholder={`Выберите продавца`}
                                >
                                    {typeBuyer?.map((item) => (
                                        <Option value={item} key={item}>
                                            {item}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {selectedBuyer === 'От Байера' && (
                                <Form.Item
                                    name="buyer_id"
                                    label={
                                        <Typography.Text
                                            style={{ color: 'white', margin: '0', padding: '0' }}
                                        >
                                            Байер
                                        </Typography.Text>
                                    }
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        onChange={handleChangeBuyer_d}
                                        placeholder={`Выберите байера`}
                                    >
                                        {buyersData?.map((item) => (
                                            <Option value={item.id} key={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}

                            <div className={s['modal-button']}>
                                <button
                                    className={s['button-modal']}
                                    onClick={handleCloseModalTurkey}
                                >
                                    {translation('otmena')}
                                </button>
                                <button
                                    className={s['button-modal']}
                                    onClick={handleSaveOrderTirkey}
                                >
                                    {translation('save')}
                                </button>
                            </div>
                        </Form>
                    </CustomModal>
                </div>
            </div>
            <div className={s['table-order']}>
                {filteredData.length === 0 ? (
                    <ArrayIsEmpty textOfEmpty={translation('refactorsCol')} />
                ) : (
                    <>
                        <EditUserModal
                            visible={openModal}
                            onClose={handleCloseModal}
                            inputData={inputData}
                            onChange={handleInputChange}
                            onSave={handleSaveUserChanges}
                            translation={translation}
                        />
                        <CreateOrderByAdmin
                            handleChangeCity={handleChangeCity}
                            citiesData={citiesData}
                            filiasData={filiasData}
                            handleChangeFilial={handleChangeFilial}
                            translation={translation}
                            visible={createOpenModal}
                            onClose={handleCreateCloseModal}
                            inputData={orderCreate}
                            onChange={handleInputCreate}
                            onSave={handleSaveOrder}
                            successModal={successModal}
                            role={role}
                        />
                        <table className={s['table']} {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => {
                                    const { key: headerGroupKey, ...headerGroupRest } =
                                        headerGroup.getHeaderGroupProps();
                                    return (
                                        <tr key={headerGroupKey} {...headerGroupRest}>
                                            {headerGroup.headers.map((column) => {
                                                const { key: columnKey, ...columnRest } =
                                                    column.getHeaderProps();
                                                return (
                                                    <th
                                                        key={columnKey}
                                                        className={s['th-style']}
                                                        {...columnRest}
                                                    >
                                                        {column.render('Header')}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    const { key: rowKey, ...rowRest } = row.getRowProps();
                                    return (
                                        <tr className={s['tbody']} key={rowKey} {...rowRest}>
                                            {row.cells.map((cell) => {
                                                const { key: cellKey, ...cellRest } =
                                                    cell.getCellProps();
                                                return (
                                                    <td
                                                        key={cellKey}
                                                        className={s['td-style']}
                                                        {...cellRest}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className={s['mobile-view']}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <div key={row.id} className={s['mobile-row']}>
                                        {row.cells.map((cell, cellIndex) => (
                                            <div
                                                key={cell.column.id}
                                                className={`${s['mobile-cell']} ${cellIndex === 3 ? s['with-line'] : ''}`}
                                            >
                                                <div className={s['mobile-header']}>
                                                    <span className={s['row-text-1']}>
                                                        {cellIndex === row.cells.length - 1
                                                            ? ''
                                                            : `${cell.column.Header}:`}
                                                    </span>
                                                </div>
                                                <div className={s['mobile-content']}>
                                                    <span className={s['row-text']}>
                                                        {cell.render('Cell')}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
            <div className={s['div-pages']}>
                <Pagination totalPages={totalPages} currentPage={page} setPage={handlePageChange} />
            </div>
        </>
    );
};

export default UserInformationTable;
