import s from '../../pages/footer-info/styles.module.css';

export function ChinaIconPlane({ styleSVG }) {
    return (
        <svg className={s[`${styleSVG}`]} viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.83 11.0509C14.6502 11.8891 13.846 13.1565 13.59 14.5809L12.05 11.6309L8.16 15.5009L8.5 18.0009L7.45 19.0609L5.68 15.8709L2.5 14.1109L3.56 13.0509L6.04 13.4009L9.93 9.50091L2.5 5.62091L3.91 4.21091L13.11 6.33091L17 2.44091C17.1386 2.30062 17.3036 2.18923 17.4856 2.11321C17.6676 2.03719 17.8628 1.99805 18.06 1.99805C18.2572 1.99805 18.4524 2.03719 18.6344 2.11321C18.8164 2.18923 18.9814 2.30062 19.12 2.44091C19.7 3.03091 19.7 4.00091 19.12 4.56091L15.23 8.45091L15.83 11.0509Z" />
            <path d="M18.59 11.0509C15.8287 11.0509 13.59 13.2896 13.59 16.0509C13.59 18.8122 15.8287 21.0509 18.59 21.0509C21.3513 21.0509 23.59 18.8122 23.59 16.0509C23.59 13.2896 21.3513 11.0509 18.59 11.0509ZM19.1198 12.2814L19.3573 12.5704L19.719 12.4322L19.5022 12.7467L19.7372 13.0334L19.3588 12.9479L19.1352 13.2646L19.1262 12.8914L18.7538 12.7996L19.1212 12.6586L19.1198 12.2814ZM18.2677 16.3787L16.951 15.4562L15.6342 16.3787L16.1313 14.8781L14.8203 13.9204H16.4592L16.9508 12.4454L17.4573 13.9204H19.0815L17.7705 14.8781L18.2677 16.3787ZM19.7188 17.0472L19.3552 16.9117L19.1198 17.1982L19.1212 16.8209L18.7538 16.6796L19.1262 16.5881L19.1355 16.2149L19.3588 16.5314L19.7372 16.4461L19.5 16.7351L19.7188 17.0472ZM20.0237 15.6764L19.9435 16.0509L19.7843 15.6936L19.4093 15.7227L19.6897 15.4719L19.5392 15.1149L19.865 15.3154L20.1532 15.0676L20.0727 15.4456L20.393 15.6434L20.0237 15.6764ZM20.0155 14.2104L19.8955 14.5759L19.7828 14.2037L19.4093 14.1909L19.7128 13.9711L19.6058 13.5927L19.9082 13.8319L20.2073 13.6162L20.0927 13.9779L20.3925 14.2154L20.0155 14.2104Z" />
        </svg>
    );
}
