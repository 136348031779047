import s from '../../pages/info-page/styles.module.css';

export function TurkeyIconPlane({ styleSVG }) {
    return (
        <svg className={s[`${styleSVG}`]} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.33 11.0509C14.1502 11.8891 13.346 13.1565 13.09 14.5809L11.55 11.6309L7.66 15.5009L8 18.0009L6.95 19.0609L5.18 15.8709L2 14.1109L3.06 13.0509L5.54 13.4009L9.43 9.50091L2 5.62091L3.41 4.21091L12.61 6.33091L16.5 2.44091C16.6386 2.30062 16.8036 2.18923 16.9856 2.11321C17.1676 2.03719 17.3628 1.99805 17.56 1.99805C17.7572 1.99805 17.9524 2.03719 18.1344 2.11321C18.3164 2.18923 18.4814 2.30062 18.62 2.44091C19.2 3.03091 19.2 4.00091 18.62 4.56091L14.73 8.45091L15.33 11.0509Z" />
            <path d="M18.09 10.6854C15.3287 10.6854 13.09 12.9241 13.09 15.6854C13.09 18.4468 15.3287 20.6854 18.09 20.6854C20.8513 20.6854 23.09 18.4468 23.09 15.6854C23.09 12.9241 20.8513 10.6854 18.09 10.6854ZM17.5705 13.2271C18.3007 13.2271 18.9557 13.5529 19.4012 14.0686C19.0772 13.8408 18.6908 13.7186 18.2948 13.7188C17.2208 13.7188 16.3502 14.5993 16.3502 15.6854C16.3502 16.7716 17.2208 17.6521 18.2948 17.6521C18.7058 17.6521 19.087 17.5229 19.4012 17.3026C18.9555 17.8179 18.3005 18.1438 17.5705 18.1438C16.228 18.1438 15.14 17.0433 15.14 15.6854C15.14 14.3276 16.228 13.2271 17.5705 13.2271ZM21.04 16.3944L20.1628 16.1214L19.6218 16.8328L19.6238 15.9549L18.7457 15.6854L19.624 15.4159L19.622 14.5383L20.163 15.2494L21.0402 14.9764L20.496 15.6854L21.04 16.3944Z" />
        </svg>
    );
}
