
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { UserInfoContext } from "../index";
import s from "../styles.module.css";
import useCreateUser from "../../../../api/auth/auth-hooks";

const FirstStep = ({ setIsNext, handleLanguageChange, language, translation }) => {
    const [form] = useForm();
    const context = useContext(UserInfoContext);
    const { mutate } = useCreateUser(setIsNext);
    const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    form.setFieldsValue({ phoneNumber: "+7" });
  }, [form]);

    const onNext = () => {
        const phoneNumber = form.getFieldValue('phoneNumber');
        if (phoneNumber.length !== 12) {
            setPhoneError('Неверный номер телефона');
            return;
        }
        const formattedPhoneNumber = phoneNumber.replace('+', '');

        context.setUser({
            username: '',
            phone: formattedPhoneNumber,
            password: '',
            smsCode: '',
            cityId: '',
            filialId: '',
            address: '',
            house: '',
        });
        mutate({
            username: '',
            email: '',
            phone: formattedPhoneNumber,
            password: '',
            cityId: '',
            filialId: '',
            smsCode: '',
            address: '',
            house: '',
        });
        setIsNext('smsCode');
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;

        if (!value.startsWith('+7')) {
            form.setFieldsValue({ phoneNumber: '+7' });
            return;
        }

        const numericValue = value.replace(/[^+\d]/g, '');

        const formattedValue = numericValue.length <= 12 ? numericValue : numericValue.slice(0, 12);

        form.setFieldsValue({ phoneNumber: formattedValue });

        if (formattedValue.length <= 12) {
            setPhoneError('');
        }
    };
    return (
        <>

            <Typography.Title style={{ color: 'white', fontWeight: '400' }} level={3}>
                {translation('register')}
            </Typography.Title>
            <Form style={{ color: 'white' }} layout="vertical" form={form}>
                <Form.Item
                    label={<Typography.Text style={{ color: 'white' }}>Телефон</Typography.Text>}
                    name="phoneNumber"
                >
                    <Input
                        type={'tel'}
                        placeholder="+7"
                        className={s['register-input']}
                        onChange={handlePhoneNumberChange}
                    />
                </Form.Item>
                {phoneError && (
                    <Typography.Text style={{ color: 'red', fontSize: '12px' }}>
                        {phoneError}
                    </Typography.Text>
                )}
                <Button
                    style={{ width: '100%', height: '35px', backgroundColor: 'white' }}
                    onClick={onNext}
                >
                    <Typography.Text style={{ color: '#100E71', fontWeight: '500' }}>
                        {translation('continue')}
                    </Typography.Text>
                </Button>
            </Form>
        </>
    );
}
export default FirstStep
