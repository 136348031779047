import { Button, Flex, Form, Select, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useUpdateUser } from '../../../api/auth/auth-hooks';
import { Option } from 'antd/es/mentions';
import React, { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useCities, useFilias } from '../../../api/user-list-api/user-list-api';
import Cookies from 'js-cookie';

export default function FilialForms({ data, closeModal, refretch }) {
    const { data: cities } = useCities();
    const [city, setCity] = useState();
    const [filial, setFilial] = useState(null);
    const { mutate } = useUpdateUser();
    const { data: filiasData } = useFilias(city);
    const decoded = jwtDecode(Cookies.get('token'));

    const handleChangeCity = (e) => {
        setCity(e);
    };
    const [form] = useForm();
    const handleChangeFilial = (value) => {
        setFilial(value);
    };
    const onSubmit = () => {
        mutate(
            {
                fullName: '',
                lastName: '',
                email: '',
                cityId: city,
                filialId: filial,
                street: '',
                house: '',
                phone: '',
                userId: decoded.id,
            },
            {
                onSuccess: () => {
                    closeModal();
                    refretch();
                },
            },
        );
    };

    return (
        <>
            <Typography.Title
                level={3}
                style={{ color: '#100E71', fontFamily: 'Roboto, san-serif', fontWeight: '600' }}
            >
                Выберите филиал
            </Typography.Title>
            <Select
                className={'selector-style'}
                style={{ width: '100%' }}
                onChange={handleChangeCity}
                placeholder={`Выберите город`}
            >
                {cities?.map((item) => (
                    <Option value={item.id} key={item.id}>
                        {item.name}
                    </Option>
                ))}
            </Select>
            <Form form={form} style={{ padding: '0', marginTop: '20px' }}>
                <Select
                    className={'selector-style'}
                    style={{ width: '100%' }}
                    onChange={handleChangeFilial}
                    placeholder={`Выберите филиал`}
                >
                    {filiasData?.content?.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            </Form>
            <Flex justify="center">
                <Button style={{ marginTop: '24px' }} className={'button-style'} onClick={onSubmit}>
                    Изменить
                </Button>
            </Flex>
        </>
    );
}
