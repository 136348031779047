export function EmailIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6953 10.9C10.5094 10.9 10.3235 10.8172 10.1377 10.6515L4.06412 5.28106C4.38335 5.08306 4.78341 4.98405 5.26428 4.98405H16.1263C16.6113 4.98405 17.0113 5.08306 17.3265 5.28106L11.253 10.6515C11.0711 10.8172 10.8852 10.9 10.6953 10.9ZM3.42767 14.555C3.37918 14.4379 3.34079 14.3065 3.3125 14.161C3.28825 14.0156 3.27613 13.8479 3.27613 13.658V6.95402C3.27613 6.74793 3.29027 6.57013 3.31856 6.42061C3.35089 6.26706 3.38524 6.15189 3.42161 6.07511L7.9313 10.0635L3.42767 14.555ZM5.11274 15.6279C4.89049 15.6279 4.69248 15.6037 4.51872 15.5552C4.34496 15.5107 4.19747 15.4481 4.07624 15.3673L8.7011 10.7363L9.53152 11.4758C9.72144 11.6415 9.91137 11.7648 10.1013 11.8456C10.2912 11.9264 10.4892 11.9668 10.6953 11.9668C10.9014 11.9668 11.0994 11.9264 11.2893 11.8456C11.4833 11.7648 11.6752 11.6415 11.8652 11.4758L12.6956 10.7363L17.3204 15.3673C17.1992 15.4481 17.0517 15.5107 16.878 15.5552C16.7042 15.6037 16.5062 15.6279 16.2839 15.6279H5.11274ZM17.969 14.555L13.4654 10.0635L17.9751 6.07511C18.0074 6.15189 18.0397 6.26706 18.0721 6.42061C18.1044 6.57013 18.1206 6.74793 18.1206 6.95402V13.658C18.1206 13.8479 18.1064 14.0156 18.0781 14.161C18.0498 14.3065 18.0135 14.4379 17.969 14.555Z"
        fill="#100E71"
      />
    </svg>
  );
}
