import s from './styles-info.module.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { forbiddenData } from './data';

const ClientForbiddenPage = ({ t }) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className={s['container']}>
            <p className={s['breadcrumps']} onClick={() => navigate('/client-main')}>
                {'назад'}
            </p>
            <h1 className={s['title']}>{t('forbiddenStuff')}</h1>
            <div className={s['table-outside']}>
                <div className={s['table-columns-wrap']}>
                    <span className={s['table-header']}>{t('forbiddenColumn')}</span>
                    <span className={s['table-header']}>{t('explanation')}</span>
                </div>
                <div className={s['table-data']}>
                    {forbiddenData.map((row, index) => (
                        <div key={index} className={s['table-columns-wrap']}>
                            <span className={s['table-cell-icon']}>
                                {row.iconsList.map((icon, index) => (
                                    <img src={icon} alt={index} key={index} />
                                ))}
                            </span>
                            <span className={s['table-cell']}>
                                {row.content.map((item, index) => (
                                    <div key={index}>
                                        <h1 className={s['table-cell-header']}>{t(item.title)}</h1>
                                        <ul style={{ padding: '0 1rem' }}>
                                            {item.list?.map((liItem, index) => (
                                                <li key={index}>{t(liItem)}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </span>
                        </div>
                    ))}
                </div>
                <div className={s['table-footer']}>
                    <h1 className={s['table-cell-header']}>{t('forbiddenFooterTitle')}</h1>
                    <h1 className={s['table-cell-header']}>{t('forbiddenFooterSubTitle')}</h1>
                    <ul style={{ padding: '0 1rem' }}>
                        <li className={s['table-cell-footer']}>{t('forbiddenFooterInfo')}</li>
                    </ul>
                </div>
            </div>
            <p className={s['breadcrumps']} onClick={() => navigate('/client-main')}>
                {'назад'}
            </p>
        </div>
    );
};

export default ClientForbiddenPage;
