import { Button, Flex, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useChangePassword } from '../../../api/auth/auth-hooks';
import { useState } from 'react';

export default function PasswordForm({ data }) {
    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();
    const { mutate } = useChangePassword();
    const handleChange = (e) => {
        setPassword(e.target.value);
    };
    const handleChangeConfirm = (e) => {
        setPasswordConfirm(e.target.value);
    };
    const [form] = useForm();

    const onSubmit = () => {
        mutate({
            password,
            data,
        });
    };

    return (
        <>
            <Typography.Title level={4} style={{ color: '#100E71' }}>
                Новый пароль
            </Typography.Title>

            <Form form={form} style={{ padding: '0', marginTop: '20px' }}>
                <Form.Item style={{ width: '100%' }}>
                    <Input placeholder="Пароль" onChange={handleChange} />
                </Form.Item>
                <Form.Item style={{ width: '100%' }}>
                    <Input placeholder="Повторите пароль" onChange={handleChangeConfirm} />
                </Form.Item>
            </Form>
            <Flex justify="center">
                <Button
                    style={{
                        backgroundColor: '#100E71',
                        width: '145px',
                        color: 'white',
                        height: '40px',
                    }}
                    disabled={password !== passwordConfirm}
                    onClick={onSubmit}
                >
                    Изменить
                </Button>
            </Flex>
        </>
    );
}
