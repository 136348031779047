import React , {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter, useLocation} from "react-router-dom";
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));
function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scroll(0, 0);
    }, [pathname]);

    return null;
}
root.render(
    <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <ScrollToTop />
                <App />
            </QueryClientProvider>
    </BrowserRouter>
);

reportWebVitals();
