import React, { useEffect, useReducer } from 'react';
import { isIOS as deviceIsIOS, isMobile as deviceIsMobile } from 'react-device-detect';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LayoutW from './pages/layout/layout';
import UserList from './pages/user-list/user-list';
import LostItems from './pages/lost-items/lost-items';
import UserOrders from './pages/user-orders/user-orders';
import MyProfilePage from './pages/my-profile-page';
import AdminMainPage from './pages/admin-main-page';
import AdminBranchPage from './pages/admin-branches';
import AdminTariffsPage from './pages/admin-tariffs';
import TarifPage from './pages/tarif-page/tarif-page';
import CargoModerator from './pages/cargo-moderator/cargo-moderator';
import './App.css';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useLocalstorage } from './hooks/use-localstorage';
import i18n from './i18n';
import LandingPage from './pages/landing-page';
import OrderTurkeyInfo from './pages/order-turkey-info/order-turkey-info';
import CargoTurkey from './pages/cargo-turkey/cargo-turkey';
import Spinner from './pages/UI/Spinner/Spinner';
import UserOrdersTurkey from './pages/user-orders-turkey/user-orders-turkey';
import Cookies from 'js-cookie';
import OrderChinaInfo from './pages/order-china-info/order-china-info';
import UserAgreement from './pages/documents/user-agrement';
import PublicContactOffer from './pages/documents/public-contact-offer';
import ClientMainPage from './pages/client-main-page';
import ClientAggreementPage from './pages/client-main-page/ClientAggreementPage';
import ClientForbiddenPage from './pages/client-main-page/ClientForbiddenPage';
import RegisterPage from './pages/register-page';
import ReportPage from './pages/report-page/ReportPage';
import DeletedCargoPage from './pages/deleted-cargo';
import DeletedUsersPage from './pages/deleted-users';
import ClientStatusesInfoPage from './pages/client-main-page/ClientStatusesInfoPage';

const initialState = {
    role: null,
    loading: true,
    isMobile: false,
    isIOS: false,
    isPushEnabled: false,
    language: 'kz',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const App = () => {
    const location = useLocation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation();
    const [language, setLanguage] = useLocalstorage('language', state.language);

    const updateState = (newState) => {
        dispatch({ type: 'SET_STATE', payload: newState });
    };

    const handleLanguageChange = () => {
        const newLanguage = language === 'kz' ? 'ru' : 'kz';
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
        updateState({ language: newLanguage });
    };

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        updateState({ language: lang });
    };

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                updateState({ role: decodedToken.role });
            } catch (error) {
                console.error('Ошибка декодирования токена', error);
                Cookies.remove('token');
                updateState({ role: '' });
            }
        } else {
            updateState({ role: '' });
        }
        updateState({ loading: false });
    }, [location.pathname]);
    // useEffect(() => {
    //     const queryParams = new URLSearchParams(location.search);
    //     const isMobileParam = queryParams.get('isMobile');
    //     const isIOSParam = queryParams.get('iSIOS') === 'true';
    //     const isPushEnabledParam = queryParams.get('isPushEnabled') === 'true';
    //
    //     if (isMobileParam) {
    //         updateState({
    //             isMobile: true,
    //             isIOS: isIOSParam,
    //             isPushEnabled: isPushEnabledParam,
    //         });
    //     }
    // }, [location.search]);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const isMobileParam = queryParams.get('isMobile');
        const isIOSParam = queryParams.get('iSIOS') === 'true';
        const isPushEnabledParam = queryParams.get('isPushEnabled') === 'true';

        if (isMobileParam !== null) {
            updateState({
                isMobile: isMobileParam === 'true' ? true : deviceIsMobile,
                isIOS: isIOSParam !== null ? isIOSParam : deviceIsIOS,
                isPushEnabled: isPushEnabledParam,
            });
        } else {
            updateState({
                isMobile: deviceIsMobile,
                isIOS: deviceIsIOS,
                isPushEnabled: false,
            });
        }
    }, [location.search]);

    if (state.loading) {
        return <Spinner />;
    }
    const renderRoutes = () => {
        switch (state.role) {
            case 'RoleClient':
                return (
                    <>
                        <Route
                            path="/info-china"
                            element={<OrderChinaInfo t={t} language={language} />}
                        />
                        <Route path="/client-main" element={<ClientMainPage t={t} />} />
                        <Route
                            path="/info-turkey"
                            element={<OrderTurkeyInfo t={t} language={language} />}
                        />
                        <Route
                            path="/my-orders-turkey"
                            element={
                                <UserOrdersTurkey
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    t={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/my-orders"
                            element={
                                <UserOrders
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    t={t}
                                    language={language}
                                />
                            }
                        />
                        <Route path="/rates" element={<TarifPage t={t} />} />
                        <Route
                            path="/my-profile"
                            element={
                                <MyProfilePage
                                    t={t}
                                    language={language}
                                    changeToRussian={() => changeLanguage('ru')}
                                    changeToKazakh={() => changeLanguage('kz')}
                                />
                            }
                        />
                        <Route
                            path="/client-main/aggreement"
                            element={<ClientAggreementPage t={t} />}
                        />
                        <Route
                            path="/client-main/forbidden"
                            element={<ClientForbiddenPage t={t} />}
                        />
                        <Route
                            path="/client-main/statuses"
                            element={<ClientStatusesInfoPage t={t} />}
                        />
                        <Route path="*" element={<Navigate to="/client-main" />} />
                        <Route path="/" element={<Navigate to="/client-main" />} />
                    </>
                );
            case 'RoleAdmin':
                return (
                    <>
                        <Route
                            path="/admin-main"
                            element={<AdminMainPage language={language} translation={t} />}
                        />
                        <Route path="/admin" element={<AdminMainPage />} />
                        <Route path="/user-list" element={<UserList />} />
                        <Route
                            path="/my-cargo"
                            element={
                                <CargoModerator
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    translation={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/turkey-cargo"
                            element={
                                <CargoTurkey
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    t={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/my-customers"
                            element={<UserList translation={t} language={language} />}
                        />
                        <Route
                            path="/lost-items"
                            element={<LostItems t={t} language={language} />}
                        />
                        <Route
                            path="/info-turkey"
                            element={<OrderTurkeyInfo t={t} language={language} />}
                        />
                        <Route path="/branches" element={<AdminBranchPage role={state.role} />} />
                        <Route path="/rates" element={<AdminTariffsPage role={state.role} />} />
                        <Route path="/" element={<Navigate to="/admin-main" />} />
                        <Route path="*" element={<p>Not found This page</p>} />
                        <Route
                            path="/info-china"
                            element={<OrderChinaInfo t={t} language={language} />}
                        />
                    </>
                );
            case 'RoleLeadAdmin':
                return (
                    <>
                        <Route
                            path="/admin-main"
                            element={<AdminMainPage language={language} translation={t} />}
                        />
                        <Route path="/admin" element={<AdminMainPage />} />
                        <Route path="/user-list" element={<UserList />} />
                        <Route
                            path="/turkey-cargo"
                            element={
                                <CargoTurkey
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    t={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/lost-items"
                            element={<LostItems t={t} language={language} />}
                        />
                        <Route
                            path="/info-china"
                            element={<OrderChinaInfo t={t} language={language} />}
                        />
                        <Route
                            path="/info-turkey"
                            element={<OrderTurkeyInfo t={t} language={language} />}
                        />
                        <Route
                            path="/my-cargo"
                            element={
                                <CargoModerator
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    translation={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/my-customers"
                            element={<UserList translation={t} language={language} />}
                        />
                        <Route path="/deleted-cargo" element={<DeletedCargoPage />} />
                        <Route path="/deleted-users" element={<DeletedUsersPage />} />
                        <Route path="/branches" element={<AdminBranchPage role={state.role} />} />
                        <Route path="/rates" element={<AdminTariffsPage role={state.role} />} />
                        <Route path="/" element={<Navigate to="/admin-main" />} />
                        <Route path="*" element={<p>Not found This page</p>} />
                    </>
                );
            case 'RoleUchet':
                return <Route path="/report" element={<ReportPage t={t} language={language} />} />;
            default:
                return <Route path="*" element={<Navigate to="/" />} />;
        }
    };

    return (
        <LayoutW
            changeToRussian={() => changeLanguage('ru')}
            changeToKazakh={() => changeLanguage('kz')}
            translation={t}
            handleLenguageChange={handleLanguageChange}
            language={language}
            isMobile={state.isMobile}
            isIOS={state.isIOS}
        >
            <Routes>
                {renderRoutes()}
                <Route path="/" element={<LandingPage isMobile={state.isMobile} />} />
                <Route path="/user-agreement" element={<UserAgreement />} />
                <Route path="/public-contract-offer" element={<PublicContactOffer />} />
                <Route
                    path="/auth"
                    element={
                        <RegisterPage
                            t={t}
                            handleLanguageChange={handleLanguageChange}
                            language={language}
                            isModerator={false}
                        />
                    }
                />
                <Route
                    path="/auth/moderator"
                    element={
                        <RegisterPage
                            t={t}
                            handleLanguageChange={handleLanguageChange}
                            language={language}
                            isModerator={true}
                        />
                    }
                />
                <Route
                    path="/register"
                    element={
                        <RegisterPage
                            t={t}
                            handleLenguageChange={handleLanguageChange}
                            language={language}
                            showRegisterForm
                        />
                    }
                />
                {!state.role && <Route path="/error" element={<h3>Ошибка с вашим аккаунтом</h3>} />}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </LayoutW>
    );
};

export default App;
