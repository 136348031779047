import s from './styles.module.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CubeIconsFooter } from '../../icons/footer-info-icons/CubeIconsFooter';
import { FaceIconsFooter } from '../../icons/footer-info-icons/FaceIconsFooter';
import { TarifIconsFooter } from '../../icons/footer-info-icons/TarifIconsFooter';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { ChinaIconPlane } from '../../icons/footer-info-icons/ChinaIconPlane';
import { TurkeyIconPlane } from '../../icons/footer-info-icons/TurkeyIconPlane';

const FooterInfo = ({ translation }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [userId, setUserId] = useState(null);
    const [messageSent, setMessageSent] = useState(false);
    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);
    useEffect(() => {
        const token = Cookies.get('token');
        if (token && typeof token === 'string') {
            try {
                const decodedToken = jwtDecode(token);
                setUserId(decodedToken.id);
            } catch (error) {
                setUserId('');
            }
        } else {
            setUserId('');
        }
    }, []);
    useEffect(() => {
        const messageSentFlag = sessionStorage.getItem('messageSent');

        if (!messageSentFlag && userId && !messageSent) {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ userId }));
                setMessageSent(true);
                sessionStorage.setItem('messageSent', 'true');
            }
        }
    }, [userId, messageSent]);
    const handleLinkClick = (path) => {
        setActiveLink(path);
        navigate(path);
    };
    return (
        <div className={s['container']}>
            <div className={s['type']}>
                <div
                    onClick={() => handleLinkClick('/client-main')}
                    className={`${s['type-div']} ${activeLink === '/client-main' ? s['active'] : ''}`}
                >
                    <CubeIconsFooter styleSVG={'style-cube-svg'} />
                    <p className={s['type-text']}>{translation('mainPage')}</p>
                </div>
                <div
                    onClick={() => handleLinkClick('/my-orders')}
                    className={`${s['type-div']} ${activeLink === '/my-orders' ? s['active'] : ''}`}
                >
                    <ChinaIconPlane styleSVG={'style-cube-svg'} />
                    <p className={s['type-text']}>{translation('ordersFromChina')}</p>
                </div>
                <div
                    onClick={() => handleLinkClick('/my-orders-turkey')}
                    className={`${s['type-div']} ${activeLink === '/my-orders-turkey' ? s['active'] : ''}`}
                >
                    <TurkeyIconPlane styleSVG={'style-cube-svg'} />
                    <p className={s['type-text']}>{translation('ordersFromTurkey')}</p>
                </div>
                <div
                    onClick={() => handleLinkClick('/my-profile')}
                    className={`${s['type-div']} ${activeLink === '/my-profile' ? s['active'] : ''}`}
                >
                    <FaceIconsFooter styleSVG={'style-cube-svg'} />
                    <p className={s['type-text']}>{translation('profile')}</p>
                </div>
                <div
                    onClick={() => handleLinkClick('/rates')}
                    className={`${s['type-div']} ${activeLink === '/rates' ? s['active'] : ''}`}
                >
                    <TarifIconsFooter styleSVG={'style-cube-svg'} />
                    <p className={s['type-text']}>{translation('rates')}</p>
                </div>
            </div>
        </div>
    );
};
export default FooterInfo;
