export const feedbackList = [
    [
        {
            name: 'name1',
            text: 'feedback1',
        },
        {
            name: 'name2',
            text: 'feedback2',
        },
        {
            name: 'name3',
            text: 'feedback3',
        },
    ],
    [
        {
            name: 'name4',
            text: 'feedback4',
        },
        {
            name: 'name5',
            text: 'feedback5',
        },
        {
            name: 'name6',
            text: 'feedback6',
        },
    ],
];
