import { Button, Input, InputNumber, notification, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import s from '../styles.module.css';
import { forgotPassword } from '../../../api/auth/auth';

const ForgotPasswordForm = ({ translation }) => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const [step, setStep] = useState(1);
    const [code, setCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const data = {
        username: '',
        phone: '',
        password: '',
        smsCode: '',
        cityId: 0,
        filialId: 0,
        address: '',
        house: '',
    };

    const showErrorNotification = () => {
        api.error({
            message: 'Произошла ошибка, попробуйте позже',
            duration: 1,
        });
    };

    const handleContinueClick = () => {
        if (step === 1) {
            forgotPassword({ ...data, phone: `7${phoneNumber.toString()}` })
                .then(() => setStep(step + 1))
                .catch(() => showErrorNotification());
        } else if (step === 2) {
            forgotPassword({
                ...data,
                phone: `7${phoneNumber.toString()}`,
                smsCode: code.toString(),
            })
                .then((res) => {
                    if (res.first === 'Смс код неверный') {
                        setErrorMessage('Смс код неверный');
                        return;
                    } else {
                        setStep(step + 1);
                    }
                })
                .catch(() => showErrorNotification());
        } else if (step === 3) {
            if (password !== repeatPassword) {
                setErrorMessage('Пароли не совпадают');
                return;
            }
            forgotPassword({
                ...data,
                phone: `7${phoneNumber.toString()}`,
                smsCode: code.toString(),
                password,
            })
                .then(() => navigate('/'))
                .catch(() => showErrorNotification());
        }
    };

    const isButtonDisabled =
        (step === 1 && phoneNumber.toString().length !== 10) ||
        (step === 2 && code.toString().length < 4);

    return (
        <>
            {contextHolder}
            {step === 1 && (
                <>
                    <Typography.Title
                        style={{ color: 'white', fontWeight: '400', textAlign: 'center' }}
                        level={3}
                    >
                        {translation('enterPhoneNumber')}
                    </Typography.Title>
                    <InputNumber
                        prefix="+7"
                        style={{ width: '100%' }}
                        type="number"
                        maxLength={10}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                    />
                </>
            )}
            {step === 2 && (
                <>
                    <Typography.Title
                        style={{ color: 'white', fontWeight: '400', textAlign: 'center' }}
                        level={3}
                    >
                        {translation('enterSms')}
                    </Typography.Title>
                    <Typography.Paragraph
                        style={{ color: 'white', fontWeight: '400', padding: '0rem 1rem' }}
                        level={3}
                    >
                        {translation('smsSendYou')} +7{phoneNumber}
                    </Typography.Paragraph>
                    <InputNumber
                        style={{ width: '100%' }}
                        type="number"
                        placeholder="Код"
                        value={code}
                        onChange={setCode}
                    />
                </>
            )}
            {step === 3 && (
                <>
                    <Typography.Title
                        style={{ color: 'white', fontWeight: '400', textAlign: 'center' }}
                        level={3}
                    >
                        {translation('createNewPassword')}
                    </Typography.Title>
                    <div style={{ marginBottom: '1rem' }}>
                        <Typography.Paragraph
                            style={{
                                color: 'white',
                                fontWeight: '400',
                                marginBottom: 0,
                                marginTop: '0.5rem',
                            }}
                        >
                            {translation('writePasswordSen')}
                        </Typography.Paragraph>
                        <Input
                            placeholder={translation('writePasswordSen')}
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setErrorMessage('');
                            }}
                        />
                        <Typography.Paragraph
                            style={{
                                fontSize: '12px',
                                color: 'white',
                                fontWeight: '400',
                                marginBottom: '0.5rem',
                                lineHeight: '16px',
                            }}
                        >
                            Пароль должен содержать не менее 4-ми символов
                        </Typography.Paragraph>
                        <Typography.Paragraph
                            style={{
                                color: 'white',
                                fontWeight: '400',
                                marginBottom: 0,
                                marginTop: '0.5rem',
                            }}
                        >
                            Повторите пароль
                        </Typography.Paragraph>
                        <Input
                            placeholder="Повторите пароль"
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => {
                                setRepeatPassword(e.target.value);
                                setErrorMessage('');
                            }}
                        />
                        {errorMessage && (
                            <Typography.Paragraph
                                style={{
                                    color: 'red',
                                    fontWeight: '400',
                                    marginBottom: '0.5rem',
                                    marginTop: '0.5rem',
                                    fontSize: '14px',
                                }}
                            >
                                {errorMessage}
                            </Typography.Paragraph>
                        )}
                    </div>
                </>
            )}
            <div className={s['login-button-group']}>
                <Button
                    style={{
                        width: '100%',
                        height: '35px',
                        backgroundColor: 'white',
                        marginTop: '10px',
                    }}
                    disabled={isButtonDisabled}
                    onClick={handleContinueClick}
                >
                    <Typography.Text style={{ color: '#100E71', fontWeight: '500' }}>
                        {translation('continue')}
                    </Typography.Text>
                </Button>
            </div>
        </>
    );
};
export default ForgotPasswordForm;
