import axios from "axios";
import { baseURL } from "../baseURL";
import Cookies from "js-cookie";

export const createBranch = async(form) => {
  const { data } = await axios.post(`${baseURL}amantrans/filials/add`, 
    form,
    {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    },
  );

  return data;
}

export const updateBranch = async(id, form) => {
  const { data } = await axios.put(`${baseURL}amantrans/filials-update/${id}`, 
    form,
    {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    },
  );

  return data;
}

export const getBranchList = async() => {
  const { data } = await axios.get(`${baseURL}amantrans/filials`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  });

  return data;
}

export const getBranchListByFilter = async({name, address, cityId}) => {
  const { data } = await axios.get(`${baseURL}amantrans/full-info-filials-by-filter`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
    params: { name, address, cityId }
  });

  return data;
}

export const changeModeratorPassword = async(id, password) => {
  const { data } = await axios.put(`${baseURL}amantrans/moderators/change-password-moderator/${id}`, { password }, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  });

  return data;
}
  