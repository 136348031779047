import useOrderReport from "../../api/admin-main/admin-main-hooks";
import CountryStatCard from "./country-stat-card";
import axios from "axios";
import {baseURL} from "../../api/baseURL";
import Cookies from "js-cookie";
import {useQuery} from "react-query";
const  getOrderReportTurkey = async () =>  {
    const { data: turkeyOrder } = await axios.get(`${baseURL}amantrans/order_turkey/orders/report`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
        },
    });

    return turkeyOrder;
}
const AdminMainPage = ({translation, language}) => {
  const { data } = useOrderReport();
  const turkeyOrder =  useQuery(["turkey-main"], getOrderReportTurkey);
  return (
    <div style={{ margin: '0 auto', width: '100%', display: "flex", justifyContent: "center" }}>
      <CountryStatCard turkeyOrder={turkeyOrder} language={language} translation={translation} data={data} />
    </div>
  );
}
export default AdminMainPage
