import {useNavigate} from "react-router-dom";
import React from "react";


const PublicContractOffer = () => {
    const navigate = useNavigate()
    return  (
        <div style={{
            fontFamily: 'Roboto, sans-serif',
            fontSize: '12px',
            lineHeight: '1.6',
            margin: '0',
            padding: '20px'
        }}>
            <div style={{
                maxWidth: '800px',
                margin: 'auto',
                backgroundColor: '#f9f9f9',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0,0,0,0.1)'
            }}>
                <h1>ПУБЛИЧНЫЙ ДОГОВОР</h1>
                <p>ПОЛЬЗОВАНИЯ УСЛУГАМИ ПРЕДЛАГАЕМЫМИ ВЕБ-САЙТОМ КОМПАНИИ ТОО «AMAN TRANS 1»</p>
                <h2>Глава 1. ОБЩИЕ УСЛОВИЯ</h2>
                <p>1. Перечисленные положения и условия определяют и регулируют правоотношения, возникающие у Сторон при
                    предоставлении услуг (далее-Услуги), предусмотренных веб-сайтом http://amantans.kz (далее-Сайт)
                    компании ТОО «AMAN TRANS 1» БИН 211140017211 (далее-Компания). Компания предоставляет Услуги в
                    других странах с помощью своих партнеров.</p>
                <p>Осуществляя вход на Сайт Компании, пользуясь любыми Услугами Сайта или загружая как пользователь и
                    (или) посетитель, (далее-Пользователь) любые сведения о себе полностью или частично, а также иную
                    информацию необходимую для оказания Услуги, Пользователь подтверждает, что полностью прочитал,
                    ознакомлен и соглашается с публичным договором пользования веб-сайтом (далее-Договор), с его
                    условиями, положениями и безоговорочно принимает их.</p>
                <p>2. Компания оставляет за собой право без предварительного уведомления, в одностороннем порядке, в
                    любой момент изменить условия, предусмотренные Договором. При изменении условий, Компания размещает
                    на Сайте новые условия Договора, при этом отметив дату последних внесенных изменений. Кроме этого,
                    Компания не обязана уведомлять Пользователей Сайта о вносимых или внесенных изменениях, которые
                    является обязанностью Пользователя, в связи с чем, последний обязуется, самостоятельно посещать сайт
                    и проверять изменения и актуальность условий Договора. Новые условия Договора будут иметь
                    юридическую силу, и будут распространяться на обязательства Сторон со дня размещения на Сайте.</p>
                <p>3. В случае несогласия Пользователя с перечисленными условиями Договора, Пользователь вправе
                    отказаться от использования Сайта.</p>
                <p><strong>Пребывание на сайте, открытие счета, регистрация в качестве Пользователя и использование
                    услуг Компании, является согласием (акцептом) Пользователя и подтверждением, что Пользователь
                    безоговорочно принимает условия Договора.</strong></p>

                <h2>Глава 2. ДЕЕСПОСОБНОСТЬ ПРИ ПОДПИСАНИИ ДОГОВОРА.</h2>
                <p>4. Соглашаясь с условиями Договора, Пользователь подтверждает, что в соответствии с законами страны
                    Пользователя, полностью дееспособен и имеет право пользоваться Услугами Компании. Если по законам
                    своей страны Пользователь является ограниченно дееспособным и не может самостоятельно пользоваться
                    услугами Компании, то Пользователь должен получить согласие родителей Пользователя или других
                    законных представителей, чтобы воспользоваться услугами Компании. В случае, если Пользователь
                    представляет интересы юридического лица, то он обязан удостоверить, наличие документов
                    подтверждающие его полномочия, предоставляемые юридическим лицом, а также пользоваться Услугами в
                    соответствии с законами страны, где зарегистрировано юридическое лицо.</p>
                <p>5. Соглашаясь с настоящими условиями Договора, Пользователь принимает во внимание, что Компания не
                    имеет возможности проверить информацию и сведения, предоставленные Пользователем на подтверждение их
                    подлинности, в связи с чем, при любом нарушении со стороны Пользователя данного пункта, всю
                    ответственность Пользователь несет самостоятельно.</p>
                <p>6. Право иностранных физических лиц на пользование Услугами Компании: в соответствии с Кодексом РК «О
                    таможенном регулировании в Республике Казахстан», иностранное физическое лицо может выступать
                    декларантом в отношении товаров для личного пользования только при документальном подтверждении
                    своего постоянного или временного проживания на территории Республики Казахстан.</p>
                <p>7. Перечень документов, один из которых обязано предоставить иностранное физическое лицо
                    длятаможенного оформления:</p>
                <ul>
                    <li>Вид на жительство</li>
                    <li>Удостоверение беженца</li>
                    <li>Справка о временной регистрации иностранца</li>
                    <li>Удостоверение лица без гражданства</li>
                    <li>Виза, выданное загранучреждениями Республики Казахстан.</li>
                </ul>
                <p>В случае отсутствия указанных документов, Компания оставляет за собой право отказа в предоставлении
                    услуг и таможенном оформлении товара. При этом, при превышении порога на беспошлинный ввоз товара
                    для личного пользования, иностранное физическое лицо обязуется предоставить Компании индивидуальный
                    идентификационный номер (ИИН) присвоенный на территории Республики Казахстан.</p>

                <h2>Глава 3. УСЛУГИ, ПРЕДОСТАВЛЯЕМЫЕ КОМПАНИЕЙ И ПОРЯДОК ПРЕДОСТАВЛЕНИЯУСЛУГ</h2>
                <p>8. Компания предоставляет своим Пользователям услуги (далее-Услуги), направленные для совершения
                    покупок (далее-Заказ) через онлайн магазины от имени и за счет Пользователя и доставляет их
                    Пользователю.</p>
                <p>9. Чтобы воспользоваться всеми услугами, предоставляемыми Компанией, Пользователь должен
                    зарегистрироваться на Сайте.</p>
                <p>10. Услуги, предоставляемые Компанией, включают в себя, но не ограничиваются следующим:</p>
                <ul>
                    <li>Покупка товаров из интернет-магазинов от имени Пользователя и за его счет на основании
                        заключенного Сторонами агентского договора и/или других соответствующих договоров.
                    </li>
                    <li>Организация транспортировки или доставку заказов Пользователя, купленные через Компанию.</li>
                    <li>Организация транспортировки или доставку заказов, купленные Пользователями самостоятельно.</li>
                </ul>
                <p>11. При заказе, Пользователь должен иметь необходимые денежные средства для приобретения данной
                    покупки.</p>
                <p>12. Компания, оставляет за собой право, самостоятельного выбора способа доставки посылок
                    Пользователей предпочтительным для Компании путем, а также отказывать физическим и юридическим лицам
                    в предоставлении услуг по перевозке документов или посылок не относящиеся к интернет-покупкам. В
                    случае изменения способа доставки посылок из-за форс-мажорных обстоятельств, Компания имеет право на
                    перерасчет тарифов.</p>
                <p>13. В случаях, если размер или вес Заказа Пользователя, будет являться или послужит причиной смены
                    Компанией авиаперевозчика, или если Заказ может относится к опасным грузам или препятствовать
                    отправке других Заказов, Компания имеет право отказаться от доставки Заказа или установить новый
                    тариф для конкретного Заказа. При возникновении случаев, указанных в настоящем пункте, Компания
                    имеет право потребовать от Пользователя оплаты всей стоимости доставки или предоставленных Услуг до
                    отправки Заказа с международного склада по указанному Пользователем адресу. Компания оставляет за
                    собой право отказаться от дальнейшей транспортировки посылок, принятых для доставки, в любое время,
                    если эти посылки каким-либо образом ставят под угрозу или препятствуют транспортировке других
                    отправлений, или, если они представляют опасность для работников или оборудования, участвующих в
                    процессе транспортировки, а также, если содержимое посылки запрещено Законом или правилами третьей
                    стороны, используемой для перевозки.</p>
                <p>14. Компания имеет право в период, когда общий объем заказов растет (праздничные дни, дисконтные
                    программы или мероприятия и т.д.) изменить сроки поставки заказов, а также приостановить
                    предоставление определенных услуг.</p>

                <h2>Глава 4. ПРИЕМ ЗАКАЗОВ И ПОРЯДОК АДРЕСАЦИИ</h2>
                <p>15. Принятые к доставке посылки делятся на два класса: внутренние и международные.</p>
                <p>16. Внутренними посылками транспортируются товары, доставка которых не запрещена на территории
                    Республики Казахстан.</p>
                <p>17. Международными посылками транспортируются или доставляются товары, не запрещенные: к доставке на
                    территорию какой-либо страны к вывозу с территории какой-либо страны и доставке в страну
                    назначения.</p>
                <p>18. В случае приобретения продукции из изделий стекла, керамики, фарфора и других материалов, для
                    которых требуется обязательная упаковка, то Пользователь обязан заранее информировать Компанию о
                    приобретении таких продукции и до отправки Заказа запросить информацию о способах упаковки Заказа,
                    общем весе и их стоимости. Эта информация может быть предоставлена по запросу, либо отправлена
                    автоматически на электронный адрес или на номер WhatsApp, указанный, как контактный. В данном случае
                    Пользователь обязан оплатить стоимость упаковки.</p>
                <p>19. В случае отказа Пользователя от упаковки Заказа Компания не несет в этом случае ответственности
                    за целостность и за повреждение товара, полученные в процессе его транспортировки.</p>
                <p>20. Компания имеет право отказать в приеме посылки, если она не упакована или не оформлена должным
                    образом.</p>
                <p>21. Посылки принимаются к транспортировке в упакованном виде. Тем не менее, Компания имеет право
                    проверить содержимое упакованной посылки с целью исключить наличие запрещенных к перевозке
                    предметов.</p>
                <p>22. Упаковка посылки является исключительной ответственностью Пользователя услугами. Компания не
                    несет ответственности за ущерб, причиненный по причине неправильной или некачественной упаковки.</p>
                <p>23. Компания оставляет за собой право переупаковать заказ, деконсолидировать, консолидировать,
                    сохраняя нормы и требования безопасности.</p>
                <p>24. Пользователь осознает, что вес Заказа может отличаться в местах доставки и приема, в результате
                    вес заказа будет округлен до 100 граммов точно в большую сторону. Оплата от Пользователя принимается
                    согласно округленному весу. Минимальный округленный вес Заказа начинается со 100 грамм.</p>
                <p>25. Каждый заказ должен быть с обязательным указанием данных отправителя и получателя. Адресные
                    данные по просьбе Пользователя записываются в сопроводительных документах.</p>
                <p>26. Адреса отправителя и адресата посылки, доставляемой по территории одного государства, должны
                    записываться на посылке на официальном языке указанного государства.</p>
                <p>27. На международных посылках адреса пишутся латинскими буквами и арабскими цифрами. Допускается
                    написание адреса получателя на языке страны назначения с условием, что название страны назначения
                    будет также написано и на казахском или русском языке.</p>
                <p>28. Пользователь обязуется самостоятельно и за свой счет нести расходы, связанные с хранением Заказа
                    на таможенном складе, а в случае, если расходы за хранение были оплачены Компанией, Пользователь
                    обязуется компенсировать или возместить указанные расходы.</p>
                <p>29. Если Заказ Пользователя останется на хранении на складе более 30 (тридцати) дней, Пользователь
                    обязуется выплачивать по 500 (пятьсот) тенге за каждый день хранения сверх срока, указанного в
                    настоящем пункте. Хранение посылки на заграничном складе Компании или её партнеров запрещено. При
                    получении Заказа от продавца или курьера, Заказ формируется для целей транспортировки, а не
                    хранения.</p>
                <p>30. В целях соблюдения таможенного законодательства и таможенного оформления Заказа, Пользователь
                    обязан вместе с трек-номером (tracking) своего Заказа или новым трек-номером предоставленным
                    Компанией Пользователю, указать цену Заказа на Сайте Компании в течение 48 часов после получения
                    уведомления о доставке Заказа. Пользователю известно, и Пользователь признает, что в случае, если
                    Пользователь не укажет цену Заказа на сайте Компании, то в целях соблюдения таможенного
                    законодательства и для обеспечения получения Пользователем Заказа, Компания или таможенные органы
                    имеют право самостоятельно определить и указать сумму или таможенную стоимость Заказа, при этом
                    Пользователь самостоятельно несет полную ответственность за все сопутствующие последствия.</p>
                <p>31. Данные отправителя и адресата отмечаются на посылке следующим образом:</p>
                <ul>
                    <li>имя (фамилия, имя отчество физического лица или наименование юридического лица), улица, номер
                        здания, дома и квартиры, название населенного пункта (города, области, деревни и т.д.)
                    </li>
                    <li>номер городского или мобильного телефона (если таковой имеется), название страны (для
                        международных посылок).
                    </li>
                </ul>
                <p>32. При покупке Заказа, Пользователь должен предоставить продавцу идентификационный код: «address
                    line 2» адрес 2, доступный на личной странице Пользователя Сайта в разделе адреса: и, в случае, если
                    Пользователь получит трэк-номер от продавца, сразу же объявить или разместить его на веб-сайте
                    Компании, в соответствующем поле на аккаунте Пользователя. Вышеуказанные два номера - являются
                    идентификационными номерами для Пользователя и определяют метод доставки Заказа. Если на Заказе
                    отсутствует один из вышеуказанных идентификационных номеров или номер несовместим с введенными
                    номерами, то Компания, если это возможно, попробует связаться с Пользователем по номеру
                    предоставленному при регистрации на Сайте, и в случае, если до Пользователя невозможно будет
                    дозвониться или связаться в течении того же дня, посылка будет доставлена по методу доставки,
                    доступному при объявлении или размещении введенного вами трэк-номера, и в странице того получателя,
                    на чьей странице был введен номер, и если трэк-номер отсутствует, в качестве основы будет
                    использоваться адрес 2 «address line 2» доступный на Заказе. Пользователь признает, что в случае
                    несоблюдения условий настоящего пункта, все риски, связанные с Заказом, покрываются или возмещаются
                    последним и Пользователь отказывается от права предъявлять любые претензии к Компании.</p>
                <p>33. Компания не предоставляет услуг по доставке посылок «до востребования» или на адрес абонентского
                    ящика.</p>
                <p>34. При курьерской доставке посылок Пользователям, с которыми Компанией заключены соответствующие
                    договоры, заполняется форма, которая подписывается отправителем или его доверенным лицом. Курьер
                    подтверждает количество принятых посылок. Доверенным лицом считается любое лицо, передающее посылку
                    от лица отправителя. Один экземпляр формы остается у отправителя. Компания не обязана проверять
                    полномочия лиц, передающие посылки для отправок и не несет какой-либо ответственности перед
                    Пользователями.</p>
                <p>35. В некоторых случаях, независимо от Компании, форма, заполняемая для каждой международной посылки,
                    имеет специальный идентификационный номер, с помощью которого клиент может отслеживать передвижение
                    посылки в интернете, вплоть до момента ее передачи адресату. Этот номер также указывается на
                    свободном месте посылки, что позволит быстро восстановить адресные данные в случае утери
                    накладной.</p>
                <p>36. Компания уведомляет Пользователя о том, что в соответствии с законодательством Республики
                    Казахстан, в зависимости от характера, количества товаров, а также частоты перемещения товаров,
                    ввозимых Пользователем на территорию Таможенного союза, к товарам для личного пользования
                    относятся:</p>
                <ul>
                    <li>изделия из меха (в том числе головные уборы) - 1 (один) предмет одного наименования, размера и
                        фасона на одного Пользователя;
                    </li>
                    <li>мобильный телефон - 2 (две) единицы на Пользователя;</li>
                    <li>планшет - 2 (две) единицы на Пользователя;</li>
                    <li>портативный переносной компьютер и принадлежности к нему (ноутбук, нэтбук) - 1 (одна) единица на
                        Пользователя;
                    </li>
                    <li>ювелирные изделия - 5 (пять) предметов на Пользователя;</li>
                    <li>велосипед - 1 (одна) штука на Пользователя;</li>
                    <li>детская коляска - 1 (одна) штука на Пользователя.</li>
                </ul>
                <p>Кроме этого, частота перемещения указанных товаров Пользователя через таможенную границу Таможенного
                    союза не должна превышать 1 (одного) раза в месяц, количество иных ввозимых товаров одной
                    номенклатуры (одного вида) не должно превышать 2 (две) единицы на одного Пользователя.
                    Дополнительно, для прохождения упрощенной процедуры оформления товара, вес посылки Пользователя не
                    должен превышать 31 (тридцать один) килограммов и (или) общую стоимость посылки не превышающей суммы
                    в размере 500 (Пятьсот) Евро. В случае несоблюдения требований и ограничений, предусмотренных
                    настоящим пунктом, посылка будет относится к товарам предпринимательской деятельности, в связи с
                    чем, Пользователь самостоятельно и за свой счет осуществляет таможенное оформление посылки с оплатой
                    определенных таможенных платежей и налогов, подлежащих обязательной уплате в соответствие с
                    законодательством Республики Казахстан.</p>

                <h2>Глава 5. ПОРЯДОК ОПЛАТЫ ПРЕДОСТАВЛЕННЫХ УСЛУГ</h2>
                <p>37. Оплата за оказанные Услуги взимается с Пользователя при получении посылки в соответствии с
                    действующим тарифом. Если нет иных договоренностей, услуги международных почтовых отправлений не
                    включают таможенные сборы и налоги, так же, как и любые иные расходы, связанные с таможенным
                    декларированием. Посылку можно получить в офисе Компания или курьерской доставкой по адресу,
                    указанному Пользователем.</p>
                <p>38. Пользователь понимает и принимает, что в некоторых случаях, установленных законодательством,
                    Заказ подлежит таможенному оформлению, за что несет ответственность Пользователь. При этом
                    Пользователь принимает, что Компания не оказывает услуги по предоставлению консультаций связанные с
                    таможенным оформлением. Это обязанность Пользователя - самостоятельно выяснить порядок таможенного
                    оформления. Пользователь обязан выполнить все шаги, необходимые для таможенного оформления и
                    получения Заказа в порядке, предусмотренном законодательством Республики Казахстан. В случае, если
                    во время осуществления таможенного оформления в результате действий или бездействий, небрежности
                    Пользователя, Компания несет потери, Пользователь обязан возместить ущерб. В случае, когда Заказ,
                    подлежащий таможенному оформлению, не принимается получателем в течение 14 (четырнадцать) дней, то
                    Компания имеет право организовать возврат Заказа обратно отправителю, при этом Пользователь
                    обязуется возместить Компании все расходы и платежи, связанные с возвратом Заказа.</p>
                <p>39. Пользователь обязуется самостоятельно выяснить нужна ли ему специальная лицензия или разрешение
                    для импорта, определенных товаров. Пользователь за свой счет и своими силами получает лицензии,
                    разрешения и иные документы необходимые для импорта определенных товаров, в том числе и по оплате
                    соответствующих платежей.</p>
                <p>40. Бонусные очки, предоставляемые Компанией в связи с началом осуществления деятельности Компании на
                    территории Республики Казахстан, а также другие проводимые акции, считаются недействительными, если
                    Пользователь не осуществляет доставку или покупку товаров в течении 3 (трех) месяцев подряд.</p>
                <p>41. Пользователь понимает и принимает во внимание, что Компания оказывает услуги в разных странах,
                    которые имеют различные (собственные национальные) валюты, поэтому в некоторых случаях стоимость
                    доставки Заказа или другие котировки цен могут быть указаны в долларах США или в других валютах,
                    однако в случае оплаты наличными в соответствии с законодательством страны получателя оплата должна
                    подлежать исполнению в валюте этой страны в соответствии с обменным курсом валюты определенным
                    Компанией на день оплаты.</p>
                <p>42. Для заказов на доставку в Республику Казахстан, в некоторых случаях цены на Заказы или перевозку
                    могут быть указаны в долларах США или в других валютах, но платежи осуществляются в национальной
                    валюте тенге по курсу продажи иностранной валюты обслуживающим Компанию банком по дате платежа.</p>
                <p>43. Пользователь соглашается, что во время предоставления ему услуг, могут потребоваться
                    дополнительные услуги. В связи с чем, Пользователь обязуется оплатить дополнительный платеж,
                    выставленный Компанией, который может быть помещен в сервис центрах Компании и на странице «Часто
                    задаваемые вопросы» сайта или в любом другом разделе.</p>
                <p>44. Пользователь должен оплатить полную стоимость оказания услуги до получения Заказа. Прежде чем вы
                    получите Заказ, Пользователь обязуется погасить все обязательства перед Компанией. При этом
                    Компания, оставляет за собой право на удержание Заказа в качестве гарантии уплаты соответствующих
                    платежей, включая оплаты, связанные с дополнительными Услугами.</p>

                <h2>Глава 6. ПОРЯДОК ВРУЧЕНИЯ ПОСЫЛКИ</h2>
                <p>45. Если Заказ доставляется сотрудником Компании или другими субъектами сотрудничающим с Компанией
                    через услугу, доставка на дом, то лицо, принявшее посылку, подтверждает факт получения и указывает
                    на сопровождающем документе: имя, фамилию и отечество, дату и время получения посылки, а также
                    ставит свою подпись.</p>
                <p>46. Если Заказ получен в сервис центрах Компании, через автоматизированную систему, то сканирование
                    кода Заказа или ручной ввод соответствующего кода, будет являться основанием для выдачи Заказа
                    получателю. Компания не несет какой-либо ответственности за небрежное отношение к хранению кода,
                    необходимого для выдачи Заказа в сервис центрах.</p>
                <p>47. Порядок передачи посылок, юридическим лицам, определяется в соответствии с договором, заключенным
                    между юридическим лицом и Компанией.</p>
                <p>48. В случае, если передача посылок адресату невозможна, посылки хранятся в Компании в течение 14
                    (четырнадцать) календарных дней. Пользователь (отправитель) или адресат, доверенное лицо адресата
                    могут посредством письменной заявки продлить срок хранения посылок до трех месяцев. За продление
                    услуги, по хранению посылки, допускается оплата адресатом или его доверенным лицом.</p>
                <p>49. В соответствии с законодательством Республики Казахстан и в порядке, установленном международным
                    договором, посылка может быть переправлена на адрес отправителя:</p>
                <ul>
                    <li>на основании заявления отправителя, до момента передачи адресату,</li>
                    <li>в случае отказа адресата принять посылку,</li>
                    <li>в случае отсутствия получателя по указанному адресу,</li>
                    <li>в случае нечитабельности адреса получателя (стерт, оторван, размыто и т.д.),</li>
                    <li>по причине других обстоятельств, которые исключают возможность выполнения Компанией обязательств
                        по договору на оказание услуг.
                    </li>
                </ul>
                <p>50. Если получатель не принимает заказ в течение 14 (четырнадцати) календарных дней и отсутствует
                    заявление на продление срока хранения посылки, Компания имеет право произвести отчуждение посылки по
                    выгодной ему цене, при этом из полученной суммы удержать затраты Компании, а оставшуюся сумму
                    вернуть Пользователю или сдать в депозит нотариуса. В случае экспроприации заказа, если
                    обязательства Пользователя перед Компанией будут погашены не полностью, Пользователь обязуется
                    компенсировать оставшуюся задолженность.</p>
                <p>51. Компания в соответствии с Законом РК «О почте» не является оператором почты, но руководствуясь
                    Правилами предоставления услуг почтовой связи и Гражданским кодексом Республики Казахстан, по не
                    врученным Заказам адресату в течение 14 (четырнадцать) календарных дней со дня прибытия Заказа на
                    склад Компании, имеет право совершать следующие действия:</p>
                <ul>
                    <li>Требовать оплаты услуги по хранению Заказа в размере 500 (пятьсот) тенге за каждый день
                        хранения.
                    </li>
                    <li>Хранить на складе Заказ адресата не более 90 (девяносто) календарных дней.</li>
                    <li>По истечению 90 (девяносто) календарных дней, в одностороннем внесудебном порядке удержать Заказ
                        адресата в счет оплаты услуг за хранение Заказа. При этом удержание производится независимо от
                        стоимости Заказа.
                    </li>
                    <li>Удержанный Заказ Компания имеет право по своему выбору утилизировать или реализовать. При этом
                        Клиент предоставляет свое согласие на совершение любых действий в отношении не полученного в
                        срок Заказа и в последующем не будет иметь никаких претензий к Компании.
                    </li>
                    <li>Если в случае реализации Заказа, полученной суммы недостаточно для погашения задолженности по
                        услуге хранения Заказа, то Клиент обязуется компенсировать оставшуюся задолженность.
                    </li>
                </ul>
                <p>52. Таможенный контроль и таможенное оформление международных посылок, транспортируемых через
                    таможенную границу данной страны, а также выдача адресату международных посылок оператором Компания
                    осуществляются в соответствии с требованиями таможенного законодательства данной страны.</p>
                <p>53. Компания не несет ответственности за таможенные декларации независимо от способа их заполнения,
                    равно как за решения, принятые таможенной службой в процессе таможенного досмотра посылки, также при
                    определении таможенной стоимости товара.</p>

                <h2>Глава 7. ДОКУМЕНТЫ, НЕОБХОДИМЫЕ ДЛЯ ПОЛУЧЕНИЯ ПОСЫЛКИ</h2>
                <p>54. Посылка выдается адресату при наличии документа, удостоверяющего личность. Посылки, адресованные
                    лицам, не достигшим 16 лет, выдаются адресату на основании свидетельства о рождении или его
                    законному представителю на основании соответствующего документа.</p>
                <p>55. Посылки выдаются только при предоставлении оригинального документа или копии документа,
                    нотариально заверенной в соответствии с законодательством Республики Казахстан.</p>
                <p>56. Условия компенсации и материальной ответственности в случае утери посылки, определяются
                    законодательством Республики Казахстан.</p>
                <p>57. Посылка также может быть передана доверенному лицу адресата, если тот имеет нотариальную
                    оформленную доверенность и удостоверение личности. В случае, если посылка была получена по
                    специальному терминалу, путем наличия баркода (штрих-код, QR-код), где не требуется предоставление
                    документов и их физическая проверка, то Компания не несет какой-либо ответственности за утерю или
                    отсутствие посылки на складе.</p>
                <p>58. Заказ считается доставленным должным образом, если адресат или его доверенное лицо представляют
                    документы, удостоверяющие личность и нотариальную оформленную доверенность. Представление документа,
                    удостоверяющего личность Пользователя или адресата, доверенного лица адресата и нотариальную
                    оформленную доверенность будет сочтено надлежащим разрешением получить Заказ.</p>
                <p>59. Заказ также считается доставленным должным образом, если Заказ получен в сервис центрах Компаний
                    через автоматизированную систему. Пользователи безоговорочно принимают во внимание, что ввод
                    соответствующего кода в систему означает, что лицо, вводившее код, является надлежащим лицом
                    получения Заказа.</p>

                <h2>Глава 8. ПРЕДЪЯВЛЕНИЕ И ОБСУЖДЕНИЕ ПРЕТЕНЗИИ, ВОЗМЕЩЕНИЕ ПРИЧИНЕННОГО УЩЕРБА</h2>
                <p>60. В случае невыполнения или ненадлежащего выполнения Компанией или партнерами Компании обязательств
                    по предоставлению Услуг, Пользователи услуг имеют право предъявить претензию (требование, жалобу) в
                    письменном виде, в том числе с требованием возмещения причиненного фактического ущерба в случае
                    утери Заказа.</p>
                <p>61. Претензия о не доставленной посылке, а также порче или утере посылки предъявляется в Компанию в
                    течение 30 (тридцати) календарных дней со дня получения посылки или с момента окончания срока,
                    установленного для выдачи посылки. Кроме того, если имеется два основания для расчета срока
                    доставки, окончательным сроком считается более поздняя дата.</p>
                <p>62. Претензия о поиске международных посылок в случае их утери принимаются и обсуждаются в
                    соответствии и в сроки, определенные законодательством Республики Казахстан и международными
                    договорами.</p>
                <p>63. Претензия подлежит регистрации в соответствии с Законодательством Республики Казахстан. При
                    подаче письменной претензии Пользователь или его представитель должен предоставить документ,
                    удостоверяющий личность нотариальную оформленную доверенность.</p>
                <p>64. К претензии прилагаются копия Договора о предоставлении услуг Компании или Партнеров компании, и
                    (или) копия квитанции, и (или) копия описи содержимого посылки, пояснение оператора курьерской
                    службы о причинах невыполнения или ненадлежащего выполнения обязанностей, установленных Договором, а
                    также сведения о размере причиненного ущерба в случае предъявления претензии о возмещении
                    причиненного ущерба.</p>
                <p>65. В письменном требовании (жалобе) должны содержаться:</p>
                <ul>
                    <li>сведения из документов, удостоверяющих личность заявителя (представителя) суть требования
                        (жалобы).
                    </li>
                </ul>
                <p>66. Компания изучает письменные требования (жалобы) и письменно предоставляет ответ в сроки,
                    установленные законодательством Республики Казахстан.</p>
                <p>67. В случае, если не будет разрешен спор по письменному требованию (жалобе), Пользователи услугами
                    имеют право обратиться в соответствующий суд.</p>
                <p>68. Выплата денежных средств, составляющих возмещение причиненного ущерба, осуществляется Компанией в
                    соответствии и в сроки, определенные законодательством Республики Казахстана и международным
                    договором.</p>
                <p>69. За утерю или повреждение Заказа ответственность Компании по усмотрению Компании может быть
                    ограничено 10 000 (десять тысяч) тенге за каждый килограмм посылки или стоимостью ремонта в случае
                    повреждения Заказа.</p>
                <p>70. Страхование Заказа и другие связанные с этим расходы, осуществляется Пользователем самостоятельно
                    за свой счет.</p>
                <p>71. В случае обнаружения посылки, после выплаты заявителю возмещения за его утрату посылка вручается
                    адресату или возвращается отправителю. При этом с заявителя (адресата или отправителя) взыскивается
                    выплаченная сумма возмещения за вычетом суммы возмещения за задержку отправки посылки, если такая
                    сумма была предоставлена. При отказе заявителя от возвращения суммы возмещения, посылка хранится в
                    Компании в качестве не выданных Заказов.</p>
                <p>72. Компания не несет ответственности, за комплектность и качество заказа, доставленного магазином
                    или иным лицом на его зарубежный склад.</p>
                <p>73. Обязанностью Пользователя является подтверждение целостности Заказа непосредственно при его
                    получении. Компания не несет ответственности за недостатки и дефекты, которые будут выявлены после
                    получения Заказа и которые были не отмечены Пользователем при получении.</p>

                <h2>Глава 10. САЙТЫ, ПРОДУКТЫ И РЕКЛАМЫ ТРЕТЬИХ ЛИЦ</h2>
                <p>74. Веб сайт Компании может содержать ссылки или гиперссылки других сайтов (далее Другие Сайты),
                    статьи, фото, картинки, продукты, графические картинки, звуковые и видео, информацию, программы и
                    так далее, которые размещены или принадлежат Третьим лицам (далее материалы Третьих Лиц).</p>
                <p>75. Компания не является представителем и не имеет какого-либо отношения к указанным Третьим лицам,
                    Компания не обязана проверять целостность, правдивость и подлинность размещенных материалов и не
                    несет ответственности за материалы Третьих Лиц или Других Сайтов.</p>
                <p>76. Компания не несет ответственности за предлагаемые или предоставленные услуги Третьими Лицами
                    через сайт Компании.</p>
                <p>77. Компания не несет ответственности за качество, исправность, целостность продуктов приобретенных
                    на сайтах третьих сторон в том числе за соответствие продуктов ожиданиям Пользователя.</p>
                <p>78. Пользователь несет полную личную ответственность за получение таких Заказов, их таможенную
                    очистку, ведение переговоров с третьими лицами, возврата Заказа и любых других действий, связанные с
                    Заказом.</p>

                <h2>Глава 11. ЗАВЕРШЕНИЕ ИЛИ РАСТОРЖЕНИЕ ДОГОВОРА</h2>
                <p>79. Вы имеете право в любое время расторгнуть Договор, подписанный с Компанией, при этом Пользователь
                    обязуется исполнить все платежные обязательства перед Компанией. В случае наличии просроченных
                    обязательств у Пользователя, Договор не может считаться расторгнутым.</p>
                <p>80. В целях прекращения Договора Пользователь обязуется представить заявление о расторжении Договора
                    по электронной почте amantrans.kz@gmail.com или в офисе Компании, отметив электронную почту,
                    предоставленную во время регистрации.</p>
                <p>81. Компания имеет право в одностороннем порядке расторгнуть Договор, если Пользователь своими
                    действиями распространяет недостоверную и (или) ложную информацию об Услугах, предоставляемых
                    Компанией, или своими действиями пытается сформировать негативное мнение о Компании у других
                    субъектов, или в других случаях, когда Компания считает, что действия Пользователя могут нанести
                    вред деловой репутации Компании. В случае расторжения Договора на основании указанных пунктов, все
                    бонусные очки Пользователя аннулируются.</p>
                <button onClick={() => navigate(-1)} style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    fontSize: '14px',
                    fontFamily: 'Roboto, sans-serif',
                    cursor: 'pointer',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px'
                }}>
                    Назад
                </button>
            </div>
        </div>
    )
}
export default PublicContractOffer
