import s from './styles.module.css';
import icons from '../../../assets/emptyIcons.png'

const ArrayIsEmpty = ({textOfEmpty}) => {
    return (
        <div className={s['empty-container']}>
            <img className={s['icons-style']} src={icons} alt={icons}/>
            <p className={s['text-style']}>{textOfEmpty}</p>
        </div>
    )
}
export default ArrayIsEmpty