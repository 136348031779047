import React from 'react';
import s from './styles.module.css';

const CustomModal = ({ visible, children }) => {
    if (!visible) return null;

    return (
        <>
            <div className={s["custom-modal-backdrop"]}></div>
            <div className={s["custom-modal"]}>
                <div className={s["custom-modal-content"]} >
                    {children}
                </div>
            </div>
        </>
    );
};

export default CustomModal;
