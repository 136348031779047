import { createContext, useState, useMemo } from 'react';
import PasswordStep from './password-step';
import SmsCodeForm  from './sms-code-form';
import CityStep from './city-step';
import  FilialStep  from './filial-step';
import FirstStep from "./first-step";
export const UserInfoContext = createContext();
const  RegisterForm = ({ handleLanguageChange, language, translation }) => {
    const [user, setUser] = useState({
        username: '',
        email: '',
        phoneNumber: '',
        password: '',
        smsCode: '',
        cityId: 0,
        filialId: 0,
        address: '',
        house: '',
    });
    const [isNextStep, setIsNextStep] = useState('first');
    const onChangeContent = () => {
        switch (isNextStep) {
            case 'first':
                return (
                    <FirstStep
                        translation={translation}
                        handleLanguageChange={handleLanguageChange}
                        language={language}
                        setIsNext={setIsNextStep}
                    />
                );
            case 'smsCode':
                return <SmsCodeForm setIsNextStep={setIsNextStep} />;
            case 'password':
                return <PasswordStep setIsNextStep={setIsNextStep} />;
            case 'city':
                return <CityStep setIsNextStep={setIsNextStep} />;
            case 'filial':
                return <FilialStep />;
            default:
                return <div />;
        }
    };
    return (
        <>
            <UserInfoContext.Provider value={useMemo(() => ({ user, setUser }), [user, setUser])}>
                {onChangeContent()}
            </UserInfoContext.Provider>
        </>
    );
}
export default RegisterForm
