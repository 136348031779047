export function AddressIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.29943 5.82047C9.29943 5.24576 9.43789 4.72329 9.71479 4.25307C9.9917 3.78285 10.3627 3.40929 10.8276 3.13238C11.2979 2.85025 11.8203 2.70919 12.395 2.70919C12.975 2.70919 13.4974 2.85025 13.9624 3.13238C14.4327 3.40929 14.8062 3.78285 15.0831 4.25307C15.36 4.72329 15.4985 5.24576 15.4985 5.82047C15.4985 6.29592 15.3992 6.74001 15.2007 7.15276C15.0022 7.56028 14.7305 7.91033 14.3856 8.20292C14.0408 8.4955 13.6516 8.69926 13.2179 8.8142V16.181C13.2179 16.7191 13.1892 17.1998 13.1317 17.623C13.0795 18.0462 13.0116 18.4067 12.928 18.7045C12.8444 19.0075 12.7529 19.2374 12.6537 19.3941C12.5596 19.5509 12.4734 19.6292 12.395 19.6292C12.3167 19.6292 12.2305 19.5483 12.1364 19.3863C12.0424 19.2296 11.9509 19.0023 11.8621 18.7045C11.7785 18.4067 11.708 18.0462 11.6505 17.623C11.5983 17.1998 11.5722 16.7191 11.5722 16.181V8.8142C11.1333 8.69403 10.7414 8.49027 10.3966 8.20292C10.057 7.91033 9.78794 7.56028 9.5894 7.15276C9.39609 6.74001 9.29943 6.29592 9.29943 5.82047ZM11.5095 5.99288C11.802 5.99288 12.0528 5.88839 12.2618 5.67941C12.4708 5.46519 12.5753 5.21441 12.5753 4.92705C12.5753 4.6397 12.4708 4.39153 12.2618 4.18254C12.0528 3.97355 11.802 3.86906 11.5095 3.86906C11.2273 3.86906 10.9792 3.97355 10.765 4.18254C10.556 4.39153 10.4515 4.6397 10.4515 4.92705C10.4515 5.21441 10.556 5.46519 10.765 5.67941C10.9792 5.88839 11.2273 5.99288 11.5095 5.99288Z"
        fill="#100E71"
      />
    </svg>
  );
}
