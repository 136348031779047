import {axiosInstances} from "../axios-instances";
import Cookies from "js-cookie";

export const getBarcode = async () => {
    const token = Cookies.get('token');
    if (!token) {
        throw new Error('Token is not available in localStorage');
    }
    const response = await axiosInstances.get('/amantrans/admin/china-box-code/cities-with-orders');

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};