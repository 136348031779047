import axios from 'axios';
import { baseURL } from '../baseURL';

export default async function getCities() {
    const { data } = await axios.get(`${baseURL}amantrans/cities`);

    return data;
}

export async function getFilials(cityId) {
    const { data } = await axios.get(`${baseURL}amantrans/filials-by-filter?cityId=${cityId}`);

    return data;
}

export const getTariffByCity = async (cityId) => {
    const { data } = await axios.get(`${baseURL}amantrans/tariffs/price-by-city/${cityId}`);

    return data;
};

export const getCurrencies = async () => {
    const { data } = await axios.get(`${baseURL}amantrans/exchange-rate/current/usd`);

    return data;
};
