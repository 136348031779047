import { Button,  Select, Typography } from "antd";
import { useFilias } from "../../../../api/common/common-hooks";
import {useContext, useState} from "react";
import { UserInfoContext } from "../index";
import { Option } from "antd/es/mentions";
import useCreateUser from "../../../../api/auth/auth-hooks";
import {Link,  useNavigate} from "react-router-dom";

export default function FilialStep() {
  const [filial, setFilial] = useState();
  const { mutate, isSuccess } = useCreateUser();
  const context = useContext(UserInfoContext);
  const { data } = useFilias(context.user.cityId);
  const navigate = useNavigate()
  const handleChange = (e) => {
    setFilial(e);
  };

    const number= context.user.phone
  const onNext = () => {
    mutate({
      username: "",
      phone: number,
      password: context.user.password,
      smsCode: "",
      cityId: context.user.cityId,
      filialId: filial,
      address: "",
      house: "",
    });
  };


    const handleNavigate = () => {
        if (isSuccess && filial === 200) {
            navigate('/client-main');
        }
    };
  return (
    <>
      <Typography.Title style={{ color: "white", fontWeight: "400" }} level={3}>
        Выберите филиал
      </Typography.Title>
      <Select style={{ width: "100%" }} onChange={handleChange}>
        {data &&
          data.content?.map((item) => (
            <Option value={item.id} key={item.id}>
              {item.name}
            </Option>
          ))}
      </Select>
      <Button
        style={{
          width: "100%",
          height: "35px",
          backgroundColor: "white",
          marginTop: "20px",
        }}
        onClick={onNext}
      >
          <Link to={'/client-main'} onClick={handleNavigate}>
              <Typography.Text style={{ color: "#100E71", fontWeight: "500" }}>
                  Продолжить
              </Typography.Text>
          </Link>

      </Button>
    </>
  );
}
