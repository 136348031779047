import React, { useEffect, useState } from 'react';
import { getTariffListByFilter, deleteTariff } from '../../api/admin/functions';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import TariffFormModal from './TariffFormModal';

import s from './styles.module.css';

export const columns = ['name', 'price2', 'west_price', 'work_price', 'weight', 'actions'];

const AdminTariffsPage = ({ role }) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();

    const [searchInput, setSearchInput] = useState('');
    const [tariffList, setTariffList] = useState([]);
    const [selectedTariff, setSelectedTariff] = useState({});

    const [openModal, setOpenModal] = useState(false);

    const handleCreateTariffClick = () => {
        setOpenModal(true);
    };

    const handleEditTariffClick = (item) => {
        setSelectedTariff(item);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedTariff({});
        setOpenModal(false);
    };

    const handleDeleteTariffClick = (id) => {
        deleteTariff(id)
            .then(() => {
                getAndUpdateData();
                api.success({
                    message: 'Тариф успешно удален',
                    duration: 1,
                });
            })
            .catch(() =>
                api.error({
                    message: 'Произошла ошибка, попробуйте позже',
                    duration: 1,
                }),
            );
    };

    const getAndUpdateData = () => {
        getTariffListByFilter({}).then((data) => setTariffList(data.content));
    };

    useEffect(() => {
        if (searchInput) {
            getTariffListByFilter({ name: searchInput }).then((data) =>
                setTariffList(data.content),
            );
        } else {
            getAndUpdateData();
        }
    }, [searchInput]);

    useEffect(() => {
        getAndUpdateData();
    }, []);

    return (
        <div className={s['container']}>
            {contextHolder}
            <div className={s['search-wrap']}>
                <h1 className={s['title']}>{t('rates')}</h1>
                {role === 'RoleLeadAdmin' && (
                    <button className={s['primary-button']} onClick={handleCreateTariffClick}>
                        {t('createTariff')}
                    </button>
                )}
            </div>
            <input
                className={s['input-style']}
                placeholder={t('searchTariffPlaceholder')}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />
            <div className={s['table-outside']}>
                <div className={s['table-columns-wrap']}>
                    {columns.map((column, index) => (
                        <span key={index} className={s['table-cell']}>
                            {t(column)}
                        </span>
                    ))}
                </div>
                <div className={s['table-data']}>
                    {tariffList.map((row, index) => (
                        <div key={index} className={s['table-columns-wrap']}>
                            <span className={s['table-cell']}>{row.name}</span>
                            <span className={s['table-cell']}>{row.price}</span>
                            <span className={s['table-cell']}>{row.west_price}</span>
                            <span className={s['table-cell']}>{row.work_price}</span>
                            <span className={s['table-cell']}>{row.weight} кг</span>
                            <span className={s['table-cell-buttons']}>
                                {role === 'RoleLeadAdmin' && (
                                    <>
                                        <button
                                            className={s['primary-button']}
                                            onClick={() => handleEditTariffClick(row)}
                                        >
                                            {t('edit')}
                                        </button>
                                        <button
                                            className={s['secondary-button']}
                                            onClick={() => handleDeleteTariffClick(row.id)}
                                        >
                                            {t('delete')}
                                        </button>
                                    </>
                                )}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <TariffFormModal
                visible={openModal}
                defaultForm={selectedTariff}
                onCloseModal={handleCloseModal}
                updateData={getAndUpdateData}
            />
        </div>
    );
};
export default AdminTariffsPage;
