import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserAgreement = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div
            style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '12px',
                lineHeight: '1.6',
                margin: '0',
                padding: '20px',
            }}
        >
            <div
                style={{
                    maxWidth: '800px',
                    margin: 'auto',
                    backgroundColor: '#f9f9f9',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                }}
            >
                <h1>{t('userAgreement.title')}</h1>
                <p>{t('userAgreement.bannerTitle')}</p>
                <p>{t('userAgreement.bannerText')}</p>

                <h2>{t('userAgreement.sections.privacyPolicy.title')}</h2>
                {Object.entries(
                    t('userAgreement.sections.privacyPolicy.items', { returnObjects: true }),
                ).map(([key, value]) => (
                    <p key={key}>{value}</p>
                ))}

                <h2>{t('userAgreement.sections.minors.title')}</h2>
                {Object.entries(
                    t('userAgreement.sections.minors.items', { returnObjects: true }),
                ).map(([key, value]) => (
                    <p key={key}>{value}</p>
                ))}

                <h2>{t('userAgreement.sections.dataUsage.title')}</h2>
                {Object.entries(
                    t('userAgreement.sections.dataUsage.items', { returnObjects: true }),
                ).map(([key, value]) => (
                    <p key={key}>{value}</p>
                ))}

                <h2>{t('userAgreement.sections.dataSecurity.title')}</h2>
                {Object.entries(
                    t('userAgreement.sections.dataSecurity.items', { returnObjects: true }),
                ).map(([key, value]) => (
                    <p key={key}>{value}</p>
                ))}

                <h2>{t('userAgreement.sections.cookieUsage.title')}</h2>
                {Object.entries(
                    t('userAgreement.sections.cookieUsage.items', { returnObjects: true }),
                ).map(([key, value]) => (
                    <p key={key}>{value}</p>
                ))}

                <h2>{t('userAgreement.sections.disputeResolution.title')}</h2>
                {Object.entries(
                    t('userAgreement.sections.disputeResolution.items', { returnObjects: true }),
                ).map(([key, value]) => (
                    <p key={key}>{value}</p>
                ))}

                <button
                    onClick={() => navigate(-1)}
                    style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        fontSize: '14px',
                        fontFamily: 'Roboto, sans-serif',
                        cursor: 'pointer',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                    }}
                >
                    {t('userAgreement.backButton')}
                </button>
            </div>
        </div>
    );
};

export default UserAgreement;
