import { Button, Flex, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useUpdateUser } from '../../../api/auth/auth-hooks';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

export default function EmailForm({ data, closeModal, refretch }) {
    const { mutate } = useUpdateUser();
    const decoded = jwtDecode(Cookies.get('token'));
    const [form] = useForm();

    const onSubmit = () => {
        mutate(
            {
                fullName: '',
                lastName: '',
                email: '',
                iin: form.getFieldValue('iin'),
                cityName: '',
                filialId: '',
                street: '',
                house: '',
                phone: '',
                userId: decoded.id,
            },
            {
                onSuccess: () => {
                    closeModal();
                    refretch();
                },
            },
        );
    };

    return (
        <>
            <Typography.Title
                level={3}
                style={{ color: '#100E71', fontFamily: 'Roboto, san-serif', fontWeight: '600' }}
            >
                ИИН
            </Typography.Title>

            <Form form={form} style={{ padding: '0', marginTop: '20px' }}>
                <Flex gap="50px">
                    <Form.Item name="iin" style={{ width: '100%' }}>
                        <Input className={'input-style'} placeholder="ИИН" />
                    </Form.Item>
                </Flex>
            </Form>
            <Flex justify="center">
                <Button className={'button-style'} onClick={onSubmit}>
                    Изменить
                </Button>
            </Flex>
        </>
    );
}
