import axios from 'axios';
import { baseURL } from '../baseURL';
import { axiosInstances } from '../axios-instances';
import Cookies from 'js-cookie';

export async function loginUser({ password, phoneNumber, clientType }) {
    const { data } = await axiosInstances.post(
        `auth/sign-in-${clientType === 'admin' ? 'moderators' : 'client'}`,
        {
            phone: phoneNumber,
            password,
            clientType,
        },
    );

    return data;
}
export default async function createUser({
    username,
    phone,
    password,
    smsCode,
    cityId,
    filialId,
    address,
    house,
}) {
    const payload = {
        username: username,
        phone: phone,
        password: password,
        smsCode: smsCode,
        cityId: cityId,
        filialId: filialId,
        address: address,
        house: house,
    };

    const { data } = await axios.post(`${baseURL}auth/sign-up`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
}

export async function updateUser({
    fullName,
    lastName,
    email,
    iin,
    cityId,
    cityName,
    filialId,
    street,
    house,
    phone,
    userId,
}) {
    const { data } = await axios.put(`${baseURL}amantrans/customers/update/${userId}`, {
        fullName,
        lastName,
        email,
        iin,
        cityName,
        cityId,
        filialId,
        street,
        house,
        phone,
    });

    return data;
}

export async function changePassword({ password, data }) {
    const { dataTemp } = await axios.post(
        `${baseURL}auth/forgot-password`,
        {
            email: data.email,
            username: data.username,
            phone: '',
            password,
            smsCode: '',
            cityId: 0,
            filialId: 0,
            address: '',
            house: '',
        },
        {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}`,
            },
        },
    );

    return dataTemp;
}

export const forgotPassword = async (form) => {
    const { data } = await axios.post(`${baseURL}auth/forgot-password`, form, {});

    return data;
};

export async function getUserDetail() {
    const { data } = await axios.get(`${baseURL}amantrans/customers/my-profile`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
        },
    });

    return data;
}
