import { axiosInstances } from '../axios-instances';
import Cookies from 'js-cookie';

export const fetchOrders = async () => {
    const response = await axiosInstances.get('/amantrans/enums/china/all-states', {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};

export const fetchStatus = async ({ state, number, page }) => {
    const response = await axiosInstances.get(`/amantrans/order`, {
        params: {
            cityIdString: '',
            filialIdString: '',
            trackCode: '',
            statusFromString: state,
            page: page,
            perPage: number,
            sortBy: 'id',
            sortByAsk: true,
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
export const getByDate = async ({ start, end, number, page }) => {
    const token = Cookies.get('token');
    if (!token) {
        throw new Error('Token is not available in localStorage');
    }
    const params = {
        dateFrom: start,
        dateTo: end,
        page: page,
        perPage: number,
        sortBy: '',
        sortByAsk: '',
    };

    const response = await axiosInstances.get('/amantrans/orders-by-date', {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        params: params,
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
export const findUser = async ({ searchTerm, page, number }) => {
    const response = await axiosInstances.get(`/amantrans/order/search`, {
        params: {
            forSearch: searchTerm,
            page: page,
            perPage: number,
            sortBy: 'id',
            sortByAsk: true,
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};

export const findByCity = async ({ city, number, page }) => {
    const response = await axiosInstances.get(`/amantrans/lead/orders-by-city`, {
        params: {
            cityId: city,
            page: page,
            perPage: number,
            sortBy: '',
            sortByAsk: true,
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
export const filterByStatusChina = async ({ state, number, page }) => {
    const response = await axiosInstances.get('/amantrans/orders/all-by-state', {
        params: {
            state: state,
            page: page,
            perPage: number,
            sortBy: 'id',
            sortByAsk: true,
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }

    return response.data;
};
export const postBarcode = async ({ barcode }) => {
    const response = await axiosInstances.get('/amantrans/status/update', {
        params: {
            page: '',
            size: '',
            barcodeData: barcode,
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }

    return response.data;
};
export const sortOrder = async ({ cityId, date, page, size }) => {
    const response = await axiosInstances.get('/amantrans/sorted-orders/by-city', {
        params: {
            page: page,
            size: size,
            date: date,
            cityId: cityId,
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }

    return response.data;
};

export const getDeletedCargo = async ({ start, end, number, page, sort }) => {
    const token = Cookies.get('token');
    if (!token) {
        throw new Error('Token is not available in localStorage');
    }
    const params = {
        dateFrom: start,
        dateTo: end,
        page,
        size: number,
        sort,
    };

    const response = await axiosInstances.get('/amantrans/orders/false', {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        params: params,
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
