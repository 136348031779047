import React, { useEffect, useState } from 'react';
import s from './styles.module.css';
import logo from '../../assets/logo-aman.png';
import { useNavigate } from 'react-router-dom';
import RegisterForm from './register-form';
import LoginForm from './login-form';
import ForgotPasswordForm from './forgot-password-form';
import { NewLogo } from '../../assets';

const RegisterPage = ({ isModerator, showRegisterForm, t, handleLanguageChange, language }) => {
    const [contentType, setContentType] = useState('login');
    const navigate = useNavigate();
    const handleForgotPasswordClick = () => {
        setContentType('forgotPassword');
    };

    useEffect(() => {
        setContentType(showRegisterForm ? 'register' : 'login');
    }, [showRegisterForm]);

    const onChangeContent = () => {
        switch (contentType) {
            case 'forgotPassword':
                return <ForgotPasswordForm translation={t} />;
            case 'register':
                return (
                    <RegisterForm
                        handleLanguageChange={handleLanguageChange}
                        language={language}
                        translation={t}
                    />
                );
            case 'login':
            default:
                return (
                    <LoginForm
                        setContentType={setContentType}
                        handleLanguageChange={handleLanguageChange}
                        language={language}
                        isModerator={isModerator}
                        translation={t}
                    />
                );
        }
    };

    const handleBackToLogin = () => {
        setContentType('login');
    };

    return (
        <div className={s['outer-wrap']}>
            <div className={s['register-container']}>
                <div className={s['register-container-images']}>
                    <img className={s['image-logo']} alt={logo} src={NewLogo} preview={false} />
                    {contentType !== 'login' && (
                        <button className={s['go-back']} onClick={handleBackToLogin}>
                            <svg
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.83301 2.6665L12.1663 11.9998M12.1663 11.9998L21.4997 2.6665M12.1663 11.9998L2.83301 21.3332M12.1663 11.9998L21.4997 21.3332"
                                    stroke="white"
                                    strokeWidth="3.33333"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </button>
                    )}
                    {contentType === 'login' && !isModerator && (
                        <button className={s['go-back-main']} onClick={() => navigate('/')}>
                            {t('backToMain')}
                        </button>
                    )}
                </div>
                {onChangeContent()}
                {contentType === 'login' && (
                    <p className={s['forgetPassword']} onClick={handleForgotPasswordClick}>
                        {t('forgotPassword')}
                    </p>
                )}
            </div>
        </div>
    );
};

export default RegisterPage;
