import s from "../../pages/info-page/styles.module.css";


export function QuestionsIcons({styleSVG}) {
    return (
        <svg  className={s[`${styleSVG}`]} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.2305 18.1763C12.7828 18.1763 13.2305 17.7286 13.2305 17.1763C13.2305 16.624 12.7828 16.1763 12.2305 16.1763C11.6782 16.1763 11.2305 16.624 11.2305 17.1763C11.2305 17.7286 11.6782 18.1763 12.2305 18.1763Z"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.5863 8.73661C12.1007 8.75792 11.8418 8.95329 11.7522 9.14374C11.517 9.64345 10.9212 9.85788 10.4215 9.6227C9.92181 9.38751 9.70737 8.79176 9.94256 8.29206C10.4647 7.18256 11.5897 6.77843 12.4986 6.73853C13.3729 6.70016 14.461 6.97865 15.063 7.76361C15.5262 8.36766 15.7864 9.13337 15.6813 9.93847C15.5748 10.7548 15.1091 11.4871 14.3536 12.0429C13.3446 12.7852 13.3464 13.1639 13.3467 13.219C13.3467 13.2198 13.3467 13.2206 13.3467 13.2212C13.3467 13.7735 12.899 14.2212 12.3467 14.2212C11.7944 14.2212 11.3467 13.7735 11.3467 13.2212C11.3467 12.2645 11.9032 11.3627 13.1684 10.4319C13.5573 10.1458 13.673 9.87209 13.6982 9.6796C13.7247 9.47584 13.667 9.22987 13.4759 8.98067C13.4624 8.96314 13.3894 8.8902 13.2065 8.82447C13.0319 8.76172 12.8123 8.72669 12.5863 8.73661Z"
            />
            <path
                d="M12.2305 23.1763C6.13047 23.1763 1.23047 18.2763 1.23047 12.1763C1.23047 6.07627 6.13047 1.17627 12.2305 1.17627C18.3305 1.17627 23.2305 6.07627 23.2305 12.1763C23.2305 18.2763 18.3305 23.1763 12.2305 23.1763ZM12.2305 3.17627C7.23047 3.17627 3.23047 7.17627 3.23047 12.1763C3.23047 17.1763 7.23047 21.1763 12.2305 21.1763C17.2305 21.1763 21.2305 17.1763 21.2305 12.1763C21.2305 7.17627 17.2305 3.17627 12.2305 3.17627Z"
            />
        </svg>
    )
}