export default function EnterIcons() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_115_10399)">
                <path
                    d="M6.00001 12.4347C5.99151 12.2117 6.03288 11.9896 6.12111 11.7846C6.20934 11.5796 6.3422 11.3969 6.51001 11.2497L15.51 3.31472C15.811 3.07227 16.194 2.95538 16.5791 2.9884C16.9642 3.02142 17.3217 3.20182 17.577 3.49197C17.8323 3.78213 17.9658 4.15964 17.9496 4.54581C17.9334 4.93197 17.7688 5.29698 17.49 5.56472L9.75001 12.4797L17.565 19.9797C17.8182 20.2612 17.9555 20.628 17.9495 21.0065C17.9435 21.385 17.7946 21.7473 17.5327 22.0206C17.2707 22.2939 16.9151 22.4581 16.5372 22.4802C16.1593 22.5023 15.787 22.3807 15.495 22.1397L6.49501 13.5747C6.33583 13.4311 6.20928 13.255 6.12389 13.0584C6.0385 12.8617 5.99626 12.6491 6.00001 12.4347V12.4347Z"
                    fill="#100E71"/>
            </g>
            <defs>
                <clipPath id="clip0_115_10399">
                    <rect width="19.5" height="12" fill="white" transform="translate(18 3) rotate(90)"/>
                </clipPath>
            </defs>
        </svg>

    )
}