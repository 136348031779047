import React, { useState, useEffect } from 'react';
import { DatePicker, Checkbox } from 'antd';
import CustomModal from '../UI/Modal/Modal';
import s from './styles.module.css';
import { ArrowIcon } from '../../assets';
import { getChinaParameters, getTurkeyParameters } from '../../api/report/report';
import { getTariffListByFilter } from '../../api/admin/functions';
import { getBranchList } from '../../api/admin-branch/functions';
import { getStatusTurkey } from '../../api/turkey-order-api/turkey-order-api';
import { fetchOrders } from '../../api/cargo-moderator-api/cargo-moderator-api';

const ReportPage = ({ t, language }) => {
    const { RangePicker } = DatePicker;

    const [selectedCountry, setSelectedCountry] = useState('china');

    const [sectionsList, setSectionsList] = useState([]);
    const [filialsList, setFilialsList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [tarifList, setTarifList] = useState([]);

    const [form, setForm] = useState([]);

    const [selectedModalType, setSelectedModalType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState('');

    const handleSectionsChecked = (name, e) => {
        console.log(name, e);
    };

    const handleDateRangeChecked = (e) => {
        console.log('e ', e);
    };

    const handleParametersClick = (name) => {
        setSelectedModalType(name);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedModalType('');
        setIsModalOpen(false);
    };

    useEffect(() => {
        const getSections = selectedCountry === 'china' ? getChinaParameters : getTurkeyParameters;
        const getStatuses = selectedCountry === 'china' ? fetchOrders : getStatusTurkey;
        getSections().then((res) => setSectionsList(res));
        getStatuses().then((res) => setStatusList(res));
    }, [selectedCountry]);

    useEffect(() => {
        getTariffListByFilter({}).then((data) => setTarifList(data.content));
        getBranchList().then((data) => setFilialsList(data));
    }, []);

    return (
        <div className={s['container']}>
            <h1 className={s['title']}>{t('report-finances')}</h1>
            <div className={s['tabs']}>
                <span
                    className={s[selectedCountry === 'turkey' ? 'selectedTab' : 'tab']}
                    onClick={() => setSelectedCountry('turkey')}
                >
                    {t('ordersFromTurkey')}
                </span>
                <span
                    className={s[selectedCountry === 'china' ? 'selectedTab' : 'tab']}
                    onClick={() => setSelectedCountry('china')}
                >
                    {t('china')}
                </span>
            </div>
            <div className={s['card']}>
                <h3 className={s['title2']}>{t('sortByDate')}</h3>
                <div className={s['withTop']}>
                    <RangePicker
                        onCalendarChange={handleDateRangeChecked}
                        size={'large'}
                        placeholder={[t('from'), t('to')]}
                        className={s['fullWidth']}
                    />
                </div>
            </div>
            <h3 className={s['title2']}>{t('sections')}</h3>
            <div className={s['card']}>
                <div className={s['grid']}>
                    {sectionsList.map((section) => (
                        <Checkbox onChange={handleSectionsChecked}>
                            {language === 'ru' ? section.name_ru : section.name_kz}
                        </Checkbox>
                    ))}
                </div>
            </div>
            <h3 className={s['title2']}>{t('parameters')}</h3>
            <div className={s['card']}>
                <h3 className={s['title2']}>{t('filialCol')}</h3>
                <div
                    className={s['select-parameter']}
                    onClick={() => handleParametersClick('filialCol')}
                >
                    <span>
                        {form.selectedFilials?.lenght
                            ? form.filials.map((filial) => filial + ', ')
                            : t('selectFilials')}
                    </span>
                    <img src={ArrowIcon} alt="arrow" />
                </div>
                <h3 className={s['title2']}>{t('status')}</h3>
                <div
                    className={s['select-parameter']}
                    onClick={() => handleParametersClick('status')}
                >
                    <span>
                        {form.selectedStatuses?.lenght
                            ? form.statuses.map((status) => status + ', ')
                            : t('selectStatuses')}
                    </span>
                    <img src={ArrowIcon} alt="arrow" />
                </div>
                <h3 className={s['title2']}>{t('taridId')}</h3>
                <div
                    className={s['select-parameter']}
                    onClick={() => handleParametersClick('taridId')}
                >
                    <span>
                        {form.selectedTariffs?.lenght
                            ? form.tariffs.map((tarif) => tarif + ', ')
                            : t('selectTariffs')}
                    </span>
                    <img src={ArrowIcon} alt="arrow" />
                </div>
            </div>
            <button className={s['primary-button']}>{t('downloadFile')}</button>
            <CustomModal visible={isModalOpen}>
                <div className={s['modal-container']}>
                    <h3>{t(selectedModalType)}</h3>
                    <ul className={s['list']}>
                        {selectedModalType === 'filialCol' &&
                            filialsList.map((item) => (
                                <li className={s['list-item']}>{item.address}</li>
                            ))}
                        {selectedModalType === 'status' &&
                            statusList.map((item) => (
                                <li className={s['list-item']}>
                                    {item[language === 'ru' ? 'name_ru' : 'name_kz']}
                                </li>
                            ))}
                        {selectedModalType === 'taridId' &&
                            tarifList.map((item) => (
                                <li className={s['list-item']}>{item.name}</li>
                            ))}
                    </ul>
                </div>
                <div className={s['buttons-wrap']}>
                    <button onClick={handleCloseModal} className={s['cancel-button']}>
                        {t('otmena')}
                    </button>
                    <button onClick={handleCloseModal} className={s['secondary-button']}>
                        {t('select')}
                    </button>
                </div>
            </CustomModal>
        </div>
    );
};

export default ReportPage;
