import { useQuery } from "react-query";
import getCities from "./common";
import { getFilials } from "./common";

export default function useCities() {
  const { data } = useQuery(["cities"], getCities);

  return { data };
}

export function useFilias(cityId) {
  const { data, refetch } = useQuery(
      ["filials", cityId],
      () => getFilials(cityId),
      {
        enabled: !!cityId,
      }
  );
  return { data, refetch };
}
