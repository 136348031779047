import s from './styles.module.css';
import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import ArrayIsEmpty from '../../UI/Array-is-empty/Array-is-empty';
import Pagination from '../../UI/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { axiosInstances } from '../../../api/axios-instances';
import Cookies from 'js-cookie';
import { useMutation, useQueryClient } from 'react-query';
import StatusColor from '../../UI/statusColor/statusColor';

const changeStatus = async (state, id) => {
    const encodedState = encodeURIComponent(state);
    const response = await axiosInstances.post(
        `/amantrans/order_turkey/change-status?orderId=${id}&newStatus=${encodedState}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}`,
                'Content-Type': 'application/json',
            },
        },
    );
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
const TurkeyTable = ({ t, language, initialData, page, setPage, ordersData, refetch, role }) => {
    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        if (initialData && initialData.content) {
            setTableData(initialData.content);
        }
    }, [initialData]);
    const totalPages = initialData && initialData.totalPages;
    const handleStatusChange = async (event, row) => {
        const newStatus = event.target.value;
        setSelectedStatus((prevState) => ({
            ...prevState,
            [row.id]: newStatus,
        }));
        try {
            await changeStatus(newStatus, row.original.id);
            refetch();
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };
    const queryClient = useQueryClient();
    const backStatus = useMutation({
        mutationFn: async (id) => {
            const response = await axiosInstances.get(
                `/amantrans/status/update/turkey/back?orderId=${id}`,
            );
            if (response.status !== 200) {
                refetch();
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['orders']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });
    const sendOrder = (id) => {
        backStatus.mutate(id);
    };
    const deleteButton = useMutation({
        mutationFn: async (id) => {
            const response = await axiosInstances.put(
                `/amantrans/order_turkey/is-active-change/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                    },
                },
            );
            if (response.status !== 200) {
                refetch();
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['orders']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });

    const deleteOrder = (id) => {
        deleteButton.mutate(id);
    };
    useEffect(() => {
        const newTotal = tableData
            .filter((row) => row.status.name === 'ISSUE')
            .reduce((sum, row) => {
                const boxPrice = parseFloat(row.box_price) || 0;
                const deliveryPrice = parseFloat(row.delivery_price) || 0;
                const usd = parseFloat(row.usd) || 0;
                return sum + (boxPrice + deliveryPrice) * usd;
            }, 0)
            .toFixed(1);
        setTotal(parseFloat(newTotal));
    }, [tableData]);
    const columns = useMemo(
        () => [
            {
                Header: t('nameOrder'),
                accessor: 'name',
            },
            {
                Header: t('trackCode'),
                accessor: 'track_code',
                Cell: ({ value }) => value || 'N/A',
            },
            {
                Header: 'ID code',
                accessor: 'customer.id_code',
            },
            {
                Header: 'Байер',
                accessor: 'buyer_name',
            },
            {
                Header: t('price'),
                accessor: 'price',
                Cell: ({ value }) => (value ? `${parseFloat(value).toFixed(2)}$` : 'N/A'),
            },
            {
                Header: t('phone'),
                accessor: 'customer.phone',
                Cell: ({ value }) => value || 'N/A',
            },
            {
                Header: t('cityName'),
                accessor: 'city_name',
                Cell: ({ row }) => {
                    const cityName = row.original.city_name;
                    const shippingMethod = row.original.shipping_method;
                    return (
                        <>
                            {cityName}
                            <br />
                            Вид перевозки ({shippingMethod})
                        </>
                    );
                },
            },
            {
                Header: t('amount'),
                accessor: 'amount',
            },
            {
                Header: t('taridId'),
                accessor: 'tariff_price',
                Cell: ({ value }) => (value ? `${value}$` : 'N/A'),
            },
            {
                Header: t('weight'),
                accessor: 'weight',
            },

            {
                Header: t('boxPrice'),
                accessor: 'box_price',
            },
            {
                Header: t('delivery'),
                accessor: 'delivery_price',
            },
            {
                Header: () => (
                    <>
                        {t('allPrices')}
                        {total !== 0.0 ? <p className={s['totalStyle']}>{total}</p> : ``}
                    </>
                ),
                accessor: 'total_price',
                Cell: ({ row }) => {
                    const boxPrice = parseFloat(row.original.box_price) || 0;
                    const deliveryPrice = parseFloat(row.original.delivery_price) || 0;
                    const usd = parseFloat(row.original.usd) || 0;
                    const totalPrice = ((boxPrice + deliveryPrice) * usd).toFixed(1);
                    return `(${boxPrice} + ${deliveryPrice}) * ${usd} = ${totalPrice} тг`;
                },
            },
            {
                Header: 'Статус',
                accessor: 'status',
                Cell: ({ value, row }) => {
                    if (role === 'RoleLeadAdmin') {
                        return (
                            <select
                                className={s['select-status']}
                                value={selectedStatus[row.id] || value.name_ru || ''}
                                onChange={(event) => handleStatusChange(event, row)}
                            >
                                <option
                                    key={`${row.id}-${value.name_ru}`}
                                    className={
                                        s[
                                            `option-status ${getStatusClassName(value.name_ru || 'N/A')}`
                                        ]
                                    }
                                    value={value.name_ru}
                                >
                                    {language === 'ru' ? value.name_ru : value.name_kz}
                                </option>
                                {ordersData.map((stat) => (
                                    <option
                                        key={`${stat.id}-${stat.name_ru}`}
                                        className={
                                            s[
                                                `option-status ${getStatusClassName(stat.name_ru || 'N/A')}`
                                            ]
                                        }
                                        value={stat.name_ru}
                                    >
                                        {language === 'ru' ? stat.name_ru : stat.name_kz}
                                    </option>
                                ))}
                            </select>
                        );
                    } else {
                        return (
                            <p
                                className={s['status-color-name']}
                                style={StatusColor(value.name_ru)}
                            >
                                {language === 'ru' ? value.name_ru : value.name_kz}
                            </p>
                        );
                    }
                },
            },
            {
                Header: t('plus-information'),
                accessor: 'plus-information',
                Cell: ({ row }) => (
                    <div className={s['button-div']}>
                        {row.original.status.name === 'ISSUE' ? (
                            <p
                                onClick={() => sendOrder(row.original.id)}
                                className={s['button-table']}
                            >
                                Назад
                            </p>
                        ) : (
                            ''
                        )}
                        {row.original.status.name !== 'NEW' ? (
                            <p
                                className={s['button-table']}
                                onClick={() =>
                                    navigate(`/info-turkey`, { state: { rowData: row.original } })
                                }
                            >
                                {t('fullInfoButton')}
                            </p>
                        ) : (
                            ''
                        )}
                        {role === 'RoleLeadAdmin' ? (
                            <button
                                onClick={() => deleteOrder(row.original.id)}
                                className={s['button-table']}
                            >
                                {t('delete')}
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                ),
            },
        ],
        [t, total, language, selectedStatus, role],
    );
    const tableInstance = useTable({
        columns,
        data: tableData,
    });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    const getStatusClassName = (status) => {
        switch (status) {
            case 'Выдан':
                return s['status-waiting'];
            case 'Новый':
                return s['status-cargo'];
            case 'На складе':
                return s['status-postomat'];
            case 'В Алматы':
                return s['status-lost'];
            case 'Общий':
                return s['status-lost'];
            default:
                return '';
        }
    };
    return (
        <div className={s['table-container']}>
            <div className={s['table-order']}>
                {tableData.length === 0 ? (
                    <ArrayIsEmpty textOfEmpty={'Нету грузов'} />
                ) : (
                    <>
                        <table className={s['table']} {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => {
                                    const { key: headerGroupKey, ...headerGroupRest } =
                                        headerGroup.getHeaderGroupProps();
                                    return (
                                        <tr key={headerGroupKey} {...headerGroupRest}>
                                            {headerGroup.headers.map((column) => {
                                                const { key: columnKey, ...columnRest } =
                                                    column.getHeaderProps();
                                                return (
                                                    <th
                                                        key={columnKey}
                                                        className={s['th-style']}
                                                        {...columnRest}
                                                    >
                                                        {column.render('Header')}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    const { key: rowKey, ...rowRest } = row.getRowProps();
                                    return (
                                        <tr className={s['tbody']} key={rowKey} {...rowRest}>
                                            {row.cells.map((cell) => {
                                                const { key: cellKey, ...cellRest } =
                                                    cell.getCellProps();
                                                return (
                                                    <td
                                                        key={cellKey}
                                                        className={s['td-style']}
                                                        {...cellRest}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className={s['mobile-view']}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <div key={row.id} className={s['mobile-row']}>
                                        {row.cells.map((cell, cellIndex) => (
                                            <div
                                                key={cell.column.id}
                                                className={`${s['mobile-cell']} ${cellIndex === 3 ? s['with-line'] : ''}`}
                                            >
                                                <div className={s['mobile-header']}>
                                                    <span className={s['row-text-1']}>
                                                        {cellIndex === row.cells.length - 1
                                                            ? ''
                                                            : `${cell.column.Header}:`}
                                                    </span>
                                                </div>
                                                <div className={s['mobile-content']}>
                                                    <span className={s['row-text']}>
                                                        {cell.render('Cell')}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>

            <div className={s['div-pages']}>
                {totalPages > 1 && (
                    <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
                )}
            </div>
        </div>
    );
};
export default TurkeyTable;
