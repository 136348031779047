import React from 'react';
import s from './styles.module.css';

const Pagination = ({ totalPages, currentPage, setPage }) => {
    const maxButtons = 3;

    const generatePageNumbers = () => {
        let pages = [];

        if (totalPages <= maxButtons) {
            for (let i = 0; i < totalPages; i++) {
                pages.push(i);
            }
        } else {
            let startPage = Math.max(0, currentPage - 2);
            let endPage = Math.min(totalPages - 1, currentPage + 2);

            if (currentPage < 3) {
                endPage = maxButtons - 1;
            } else if (currentPage > totalPages - 3) {
                startPage = totalPages - maxButtons;
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (startPage > 0) {
                pages.unshift('...');
                pages.unshift(0);
            }
            if (endPage < totalPages - 1) {
                pages.push('...');
                pages.push(totalPages - 1);
            }
        }

        return pages;
    };

    const pageNumbers = generatePageNumbers();

    return (
        <div className={s['pagination']}>
            <button
                className={`${s['button-pages']} ${currentPage === 0 ? s['disabled'] : ''}`}
                onClick={() => setPage(0)}
                disabled={currentPage === 0}
            >
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12.4477 12.9023L13.3877 11.9623L10.3344 8.90234L13.3877 5.84234L12.4477 4.90234L8.44769 8.90234L12.4477 12.9023Z"
                        fill="#333333"
                    />
                    <path
                        d="M8.05414 12.9023L8.99414 11.9623L5.94081 8.90234L8.99414 5.84234L8.05414 4.90234L4.05414 8.90234L8.05414 12.9023Z"
                        fill="#333333"
                    />
                </svg>
            </button>

            <button
                className={`${s['button-pages']} ${currentPage === 0 ? s['disabled'] : ''}`}
                onClick={() => setPage(currentPage - 1)}
                disabled={currentPage === 0}
            >
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.7807 12.9023L11.7207 11.9623L8.66737 8.90234L11.7207 5.84234L10.7807 4.90234L6.7807 8.90234L10.7807 12.9023Z"
                        fill="black"
                    />
                </svg>
            </button>

            {pageNumbers.map((page, index) => (
                <button
                    key={index}
                    className={`${s['button-pages']} ${page === currentPage ? s['active'] : ''}`}
                    onClick={() => typeof page === 'number' && setPage(page)}
                    disabled={typeof page !== 'number'}
                >
                    {typeof page === 'number' ? page + 1 : page}
                </button>
            ))}

            <button
                className={`${s['button-pages']} ${currentPage === totalPages - 1 ? s['disabled'] : ''}`}
                onClick={() => setPage(currentPage + 1)}
                disabled={currentPage === totalPages - 1}
            >
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.6607 4.90234L6.7207 5.84234L9.77404 8.90234L6.7207 11.9623L7.6607 12.9023L11.6607 8.90234L7.6607 4.90234Z"
                        fill="black"
                    />
                </svg>
            </button>

            <button
                className={`${s['button-pages']} ${currentPage === totalPages - 1 ? s['disabled'] : ''}`}
                onClick={() => setPage(totalPages - 1)}
                disabled={currentPage === totalPages - 1}
            >
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.99371 4.90234L4.05371 5.84234L7.10704 8.90234L4.05371 11.9623L4.99371 12.9023L8.99371 8.90234L4.99371 4.90234Z"
                        fill="black"
                    />
                    <path
                        d="M9.38727 4.90234L8.44727 5.84234L11.5006 8.90234L8.44727 11.9623L9.38727 12.9023L13.3873 8.90234L9.38727 4.90234Z"
                        fill="black"
                    />
                </svg>
            </button>
        </div>
    );
};

export default Pagination;
