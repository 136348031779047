import { axiosInstances } from "../axios-instances";
import Cookies from "js-cookie";

class UserTarif {
    fetchTarifs = async () => {
        const token = Cookies.get('token');
        const response = await axiosInstances.get('/amantrans/my-tariff', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }
        return response.data;
    }
}

export const userTarif = new UserTarif();
