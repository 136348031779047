import { Flex, Typography } from 'antd';
import { RedirectIcon } from '../../../icons';

const CountryStatCard = ({ turkeyOrder, data, translation, language }) => {
    const orders = turkeyOrder.data;
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
                style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '24px',
                    width: 'fit-content',
                    maxWidth: '100%',
                    height: 'fit-content',
                    margin: '44px 171.5px',
                }}
            >
                <Flex justify="start">
                    <Typography.Title
                        level={3}
                        style={{ margin: '0', gap: '11px', fontSize: '24px', color: '#3B3C5A' }}
                    >
                        {translation('china')}
                        <RedirectIcon />
                    </Typography.Title>
                </Flex>
                <Flex gap="10px">
                    <Typography.Title style={{ margin: '0', color: '#3B3C5A' }}>
                        {data?.all_count?.all}
                    </Typography.Title>
                    <Typography.Title level={5} style={{ color: '#3B3C5A' }}>
                        {language === 'ru' ? data?.rus?.all : data?.kaz?.all}
                    </Typography.Title>
                </Flex>
                <Flex wrap gap="12px" style={{ marginTop: '10px' }}>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.new_count?.new}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.new : data?.kaz?.new}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.china_count?.china}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.china : data?.kaz?.china}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.on_road_china_count?.on_road_china}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru'
                                ? data?.rus?.on_road_china
                                : data?.kaz?.on_road_china}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.on_border_count?.on_border}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.on_border : data?.kaz?.on_border}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.almaty_count?.almaty}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.almaty : data?.kaz?.almaty}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.on_road_count?.on_road}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.on_road : data?.kaz?.on_road}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.city_count?.city}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.city : data?.kaz?.city}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.sorting_count?.sorting}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.sorting : data?.kaz?.sorting}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.in_stock_count?.in_stock}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.in_stock : data?.kaz?.in_stock}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.sub_filial_count?.sub_filial}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.sub_filial : data?.kaz?.sub_filial}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.for_issue_count?.for_issue}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.for_issue : data?.kaz?.for_issue}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.issue_count?.issue}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.issue : data?.kaz?.issue}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {data?.done_count?.done}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.done : data?.kaz?.done}
                        </Typography.Text>
                    </div>
                </Flex>
            </div>
            <div
                style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '24px',
                    width: 'fit-content',
                    maxWidth: '100%',
                    height: 'fit-content',
                    margin: '44px 171.5px',
                }}
            >
                <Flex justify="start">
                    <Typography.Title
                        level={3}
                        style={{ margin: '0', gap: '11px', fontSize: '24px', color: '#3B3C5A' }}
                    >
                        {language === 'ru' ? 'Турция' : 'Түркия'}
                        <RedirectIcon />
                    </Typography.Title>
                </Flex>
                <Flex gap="10px">
                    <Typography.Title style={{ margin: '0', color: '#3B3C5A' }}>
                        {orders?.all_count}
                    </Typography.Title>
                    <Typography.Title level={5} style={{ color: '#3B3C5A' }}>
                        {language === 'ru' ? data?.rus?.all : data?.kaz?.all}
                    </Typography.Title>
                </Flex>
                <Flex wrap gap="12px" style={{ marginTop: '10px' }}>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.new_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.new : data?.kaz?.new}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.turkey_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? 'На складе в Турции' : 'Түркиядағы қоймада'}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.on_road_turkey_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru'
                                ? 'Заказы в пути из Турции'
                                : 'Түркиядан сапарға тапсырыс'}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.on_border_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.on_border : data?.kaz?.on_border}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.almaty_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.almaty : data?.kaz?.almaty}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.on_road_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.on_road : data?.kaz?.on_road}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.city_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.city : data?.kaz?.city}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.sorting_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.sorting : data?.kaz?.sorting}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.in_stock_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.in_stock : data?.kaz?.in_stock}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.sub_filial_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.sub_filial : data?.kaz?.sub_filial}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.for_issue_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.for_issue : data?.kaz?.for_issue}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.issue_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.issue : data?.kaz?.issue}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#F3F4F8',
                            display: 'flex',
                            gap: '8px',
                            padding: '8px 30px 8px 16px',
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                color: '#3B3C5A',
                            }}
                        >
                            {orders?.done_count}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                lineHeight: '30px',
                                fontWeight: '500',
                                color: '#A1A2A7',
                            }}
                        >
                            {language === 'ru' ? data?.rus?.done : data?.kaz?.done}
                        </Typography.Text>
                    </div>
                </Flex>
            </div>
        </div>
    );
};
export default CountryStatCard;
