import s from "../../pages/info-page/styles.module.css";

export function FaceIcons({styleSVG}) {
    return (
        <svg className={s[`${styleSVG}`]} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.99767 15.6407C5.93535 14.7031 7.20712 14.1763 8.5332 14.1763H16.5332C17.8593 14.1763 19.1311 14.7031 20.0687 15.6407C21.0064 16.5784 21.5332 17.8502 21.5332 19.1763V21.1763C21.5332 21.7286 21.0855 22.1763 20.5332 22.1763C19.9809 22.1763 19.5332 21.7286 19.5332 21.1763V19.1763C19.5332 18.3806 19.2171 17.6176 18.6545 17.0549C18.0919 16.4923 17.3289 16.1763 16.5332 16.1763H8.5332C7.73755 16.1763 6.97449 16.4923 6.41188 17.0549C5.84927 17.6176 5.5332 18.3806 5.5332 19.1763V21.1763C5.5332 21.7286 5.08549 22.1763 4.5332 22.1763C3.98092 22.1763 3.5332 21.7286 3.5332 21.1763V19.1763C3.5332 17.8502 4.05999 16.5784 4.99767 15.6407Z"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.5332 4.17627C10.8763 4.17627 9.5332 5.51942 9.5332 7.17627C9.5332 8.83312 10.8763 10.1763 12.5332 10.1763C14.1901 10.1763 15.5332 8.83312 15.5332 7.17627C15.5332 5.51942 14.1901 4.17627 12.5332 4.17627ZM7.5332 7.17627C7.5332 4.41485 9.77178 2.17627 12.5332 2.17627C15.2946 2.17627 17.5332 4.41485 17.5332 7.17627C17.5332 9.93769 15.2946 12.1763 12.5332 12.1763C9.77178 12.1763 7.5332 9.93769 7.5332 7.17627Z"
            />
        </svg>
    )
}