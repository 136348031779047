import Cookies from "js-cookie";
import {axiosInstances} from "../axios-instances";

export const getLostItem = async ({ queryKey }) => {
    const [, { number, page, dateFrom, dateTo, forSearch, city, adminsId }] = queryKey;
    const token = Cookies.get('token');
    if (!token) {
        throw new Error('Token is not available in localStorage');
    }

    const today = new Date();
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(today.getMonth() - 3);

    const formattedDateFrom = dateFrom || threeMonthsAgo.toISOString().split('T')[0];
    const formattedDateTo = dateTo || today.toISOString().split('T')[0];

    const response = await axiosInstances.get('/amantrans/orders/mistakes', {
        params: {
            dateFrom: formattedDateFrom,
            dateTo: formattedDateTo,
            forSearch: forSearch,
            cityId: city,
            adminId: adminsId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
export const getAllAdmins = async ({ queryKey }) => {
    const [, { city }] = queryKey;
    const token = Cookies.get('token');
    if (!token) {
        throw new Error('Token is not available in localStorage');
    }
    const response = await axiosInstances.get('/amantrans/moderators/get-all-admins', {
        params: {
            cityId: city,
        },
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};