import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { userTarif } from '../../api/user-tarif/user-tarif';
import s from './styles.module.css';
import Spinner from '../UI/Spinner/Spinner';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { WeightIcon } from '../../assets';
import useCities from '../../api/common/common-hooks';
import { getCurrencies, getTariffByCity } from '../../api/common/common';

const TarifPage = ({ t }) => {
    const [selectedCityId, setSelectedCityId] = useState(1);
    const { data, error, isLoading } = useQuery('tarifs', userTarif.fetchTarifs);
    const { data: cities } = useCities();
    const [usdCurrency, setUsdCurrency] = useState(0);
    const [selectedWeight, setSelectedWeight] = useState(100);
    const [priceForCity, setPriceForCity] = useState(10);
    const [totalSum, setTotalSum] = useState(0);
    const minWeight = 100;
    const maxWeight = 1000;
    useEffect(() => {
        if (selectedCityId) {
            getTariffByCity(selectedCityId).then((res) => setPriceForCity(res));
        }
    }, [selectedCityId]);

    useEffect(() => {
        setTotalSum((selectedWeight * priceForCity * usdCurrency) / 1000 + 0.2 * usdCurrency);
    }, [selectedWeight, priceForCity, usdCurrency]);

    useEffect(() => {
        getCurrencies().then((currency) => setUsdCurrency(currency));
    }, []);

    const tarifs = Array.isArray(data) ? data : data && data.tarif ? [data] : [];
    const handleSelectCity = (city) => {
        setSelectedCityId(city);
    };
    const handleSliderChange = (e) => {
        setSelectedWeight(e.target.value);
    };
    const getBackgroundColor = () => {
        return {
            background: `linear-gradient(to right, #100e71 ${((selectedWeight - minWeight) * 100) / (maxWeight - minWeight)}%, #FFF 0)`,
        };
    };
    if (!Array.isArray(tarifs)) {
        return <div>{t('error')}</div>;
    }
    if (isLoading) return <Spinner />;
    if (error) return <div>{t('error')}</div>;

    return (
        <div className={s['container']}>
            <div className={s['content']}>
                <div>
                    <h1 className={s['title']}>{t('tarifs')}</h1>
                </div>
                <div key={data.id} className={s['type']}>
                    <div className={s['choose-tarif']}>
                        <p className={s['choose-tarif-text']}>{t('currentTarif')}</p>
                    </div>
                    <div className={s['type-div']}>
                        <p className={s['type-title']}>{`Тариф "${data.name}"`}</p>
                        <p className={s['type-text']}>{t('forLargeShipments')}</p>
                    </div>
                    <div className={s['advantages']}>
                        <p className={s['advantages-text']}>{t('deliveryAnywhere')}</p>
                        <p className={s['advantages-text']}>{t('anyCargoDelivery')}</p>
                        <p className={s['advantages-text']}>{t('doorDelivery')}</p>
                    </div>
                    <div className={s['line-status']} />
                    <div className={s['price']}>
                        <p className={s['price-text']}>
                            <span className={s['price-span']}>{data.price} $</span>{' '}
                            {t('pricePerKg')}
                        </p>
                    </div>
                </div>
                <div className={s['calculator-card']}>
                    <p className={s['primary-text-sm']}>{t('delivery-calculator')}</p>
                    <p className={s['tab-text']}>{t('china')}</p>
                    <span className={s['calc-form-text']}>{t('your-city')}</span>
                    <Select
                        style={{
                            marginTop: '4px',
                            width: '100%',
                            height: '2.5rem',
                            marginBottom: '12px',
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: '16px',
                            color: 'rgba(102, 108, 114, 1)',
                        }}
                        onChange={(value) => handleSelectCity(value)}
                        defaultValue={selectedCityId}
                        size={'large'}
                        className={s['select']}
                    >
                        {cities?.map((item) => (
                            <Option value={item.id} key={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                    <span className={s['calc-form-text']}>{t('parcel-weight')}</span>
                    <div className={s['weight-wrap']}>
                        <div className={s['row-wrap2']}>
                            <img src={WeightIcon} alt="" />
                            <span className={s['weight-text']}>{`${selectedWeight} грамм`}</span>
                        </div>
                        <input
                            type="range"
                            min={minWeight}
                            max={maxWeight}
                            className={s['calculator-slider']}
                            onChange={handleSliderChange}
                            style={getBackgroundColor()}
                        />
                    </div>
                    <span className={s['calc-form-text-1']}>{t('box-price')}</span>
                    <div className={s['weight-wrap-1']}>
                        <span className={s['box-price']}>$ 0.2</span>
                    </div>
                    <div className={s['line']} />
                    <div className={s['grid-wrap']}>
                        <div className={s['with-right-line']}>
                            <p className={s['calc-form-text']}>{t('total')}:</p>
                            <p className={s['primary-text-lg']}>{totalSum.toFixed(2)} тг</p>
                        </div>
                        <div>
                            <p className={s['calc-form-text']}>{t('tariffForKg')}:</p>
                            <p className={s['primary-text-lg']}>${priceForCity}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TarifPage;
