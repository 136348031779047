import { axiosInstances } from '../../api/axios-instances';
import Cookies from 'js-cookie';

export const getOrdersTurkey = async ({ queryKey }) => {
    const [
        ,
        {
            cityId,
            filialId,
            forSearch,
            status,
            dateFrom,
            dateTo,
            selector,
            plagination,
            shippingMethod,
        },
    ] = queryKey;

    const today = new Date();
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(today.getMonth() - 30);

    const formattedDateFrom = dateFrom || threeMonthsAgo.toISOString().split('T')[0];
    const formattedDateTo = dateTo || today.toISOString().split('T')[0];

    const token = Cookies.get('token');
    if (!token) {
        throw new Error('Token is not available');
    }
    const response = await axiosInstances.get('/amantrans/order_turkey', {
        params: {
            cityId,
            filialId,
            forSearch,
            status,
            dateFrom: formattedDateFrom,
            dateTo: formattedDateTo,
            isActive: true,
            page: plagination,
            size: selector,
            ...(shippingMethod ? { shippingMethod } : { shippingMethod: '' }),
        },
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};

export const searchTurkeyOrder = async ({ search, page, size }) => {
    const response = await axiosInstances.get('/amantrans/order_turkey/search-by', {
        params: {
            search: search,
            page: page,
            size: size,
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};

export const searchTurkeyOrderByTrackcode = async (trackCode) => {
    const response = await axiosInstances.get(`/amantrans/order_turkey/track/${trackCode}`);

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};

export const getStatusTurkey = async () => {
    const response = await axiosInstances.get('/amantrans/enums/turkey/all-states', {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
export const filterByStatus = async ({ state, number, page }) => {
    const response = await axiosInstances.get('/amantrans/order_turkey/all-by-state', {
        params: {
            state: state,
            page: page,
            perPage: number,
            sortBy: 'id',
            sortByAsk: true,
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }

    return response.data;
};

export const getByDateTurkey = async ({ start, end, PerPage, page }) => {
    const token = Cookies.get('token');
    if (!token) {
        throw new Error('Token is not available in localStorage');
    }

    const response = await axiosInstances.get('/amantrans/order_turkey/by-date', {
        params: {
            dateFrom: start,
            dateTo: end,
            page: page,
            perPage: PerPage,
            sortBy: '',
            sortByAsk: '',
        },
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
export const findByCityTurkey = async ({ city, number, page }) => {
    const response = await axiosInstances.get(`/amantrans/order_turkey/lead/by-city`, {
        params: {
            cityId: city,
            page: page,
            perPage: number,
            sortBy: '',
            sortByAsk: true,
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
