import s from './styles.module.css';
import { useTable } from 'react-table';
import React, { useEffect, useMemo, useState } from 'react';
import ArrayIsEmpty from '../../UI/Array-is-empty/Array-is-empty';
import { useMutation, useQueryClient } from 'react-query';
import { axiosInstances } from '../../../api/axios-instances';
import CustomModal from '../../UI/Modal/Modal';
import Pagination from '../../UI/Pagination/Pagination';
import Cookies from 'js-cookie';
import StatusColor from '../../UI/statusColor/statusColor';
import { useNavigate } from 'react-router-dom';

const changeStatus = async (state, id) => {
    const encodedState = encodeURIComponent(state);
    const response = await axiosInstances.post(
        `/amantrans/order/change-status?orderId=${id}&newStatus=${encodedState}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}`,
                'Content-Type': 'application/json',
            },
        },
    );
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};

const CargoTable = ({
    ordersData,
    tableOrder,
    handleChange,
    translation,
    language,
    refetch,
    page,
    setPage,
    number,
    role,
    setDataBarcode,
}) => {
    const queryClient = useQueryClient();

    const [total, setTotal] = useState(0);

    const [tableData, setTableData] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [inputData, setInputData] = useState({
        name: '',
        price: 0,
        amount: '',
        weight: 0,
        track_code: '',
    });
    useEffect(() => {
        if (tableOrder && tableOrder.content) {
            setTableData(tableOrder.content);
        }
    }, [tableOrder]);
    useEffect(() => {
        const newTotal = tableData
            .filter((row) => row.status.name === 'ISSUE')
            .reduce((sum, row) => {
                const boxPrice = parseFloat(row.box_price) || 0;
                const deliveryPrice = parseFloat(row.delivery_price) || 0;
                const usd = parseFloat(row.usd) || 0;
                return sum + (boxPrice + deliveryPrice) * usd;
            }, 0)
            .toFixed(1);
        setTotal(parseFloat(newTotal));
    }, [tableData]);

    const handleStatusChange = async (event, row) => {
        const newStatus = event.target.value;
        setSelectedStatus((prevState) => ({
            ...prevState,
            [row.id]: newStatus,
        }));
        try {
            const response = await changeStatus(newStatus, row.original.id);
        } catch (error) {
            console.error('Error updating status:', error);
        } finally {
            refetch();
        }
    };

    const updateOrder = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.put(`/amantrans/orders/update`, user, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['orders']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });
    const backStatus = useMutation({
        mutationFn: async (id) => {
            const response = await axiosInstances.get(
                `/amantrans/status/update/china/back?orderId=${id}`,
            );

            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }

            return response.data;
        },
        onSuccess: (id) => {
            setDataBarcode((prevData) => ({
                ...prevData,
                content: prevData.content.filter((item) => item.id !== id),
            }));
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });

    const deleteButton = useMutation({
        mutationFn: async (id) => {
            const response = await axiosInstances.delete(`/amantrans/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            });
            if (response.status !== 200) {
                refetch();
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['orders']);
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });
    const deleteOrder = (id) => {
        deleteButton.mutate(id);
    };
    const sendOrder = (id) => {
        backStatus.mutate(id);
        refetch();
    };
    const saveUserChanges = () => {
        const updateData = {
            id: selectedUser.id,
            name: inputData.name,
            price: inputData.price,
            amount: inputData.amount,
            weight: inputData.weight,
            track_code: inputData.track_code,
        };
        updateOrder.mutate(updateData, {
            onSuccess: () => {
                setOpenModal(false);
                setSelectedUser(null);
                setInputData({
                    name: '',
                    price: 0,
                    amount: '',
                    weight: 0,
                    track_code: '',
                });
                refetch();
            },
            onError: (error) => {
                console.error('Error updating customer:', error);
            },
        });
    };

    const handleOpenModal = (row) => {
        setSelectedUser(row.original);
        setInputData({
            name: row.original.name,
            price: row.original.price,
            amount: row.original.amount,
            weight: row.original.weight,
            track_code: row.original.track_code,
        });
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedUser(null);
        setInputData({
            name: '',
            price: 0,
            amount: '',
            weight: 0,
            track_code: '',
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const navigate = useNavigate();
    const totalPages = tableOrder.totalPages;
    const columns = useMemo(
        () => [
            {
                Header: translation('nameOrder'),
                accessor: 'name',
            },
            {
                Header: translation('trackCode'),
                accessor: 'track_code',
            },
            {
                Header: 'ID code',
                accessor: 'customer.id_code',
            },
            {
                Header: translation('price'),
                accessor: 'price',
                Cell: ({ value }) => (value ? `${value}$` : 'N/A'),
            },
            {
                Header: translation('phoneNumber'),
                accessor: 'customer',
                Cell: ({ value }) => value.phone,
            },
            {
                Header: translation('cityName'),
                accessor: 'city_name',
            },
            {
                Header: translation('amount'),
                accessor: 'amount',
            },
            {
                Header: translation('taridId'),
                accessor: 'tariff_price',
                Cell: ({ value }) => (value ? `${value}$` : 'N/A'),
            },
            {
                Header: translation('weight'),
                accessor: 'weight',
            },
            {
                Header: translation('boxPrice'),
                accessor: 'box_price',
            },
            {
                Header: translation('delivery'),
                accessor: 'delivery_price',
            },
            {
                Header: () => (
                    <>
                        {translation('allPrices')}
                        {total !== 0.0 ? <p className={s['totalStyle']}>{total}</p> : ``}
                        {/*<p className={s['totalStyle']}>{total !== 0.0 ? `${total} тг` : ``}</p>*/}
                    </>
                ),
                accessor: 'total_price',
                Cell: ({ row }) => {
                    const boxPrice = parseFloat(row.original.box_price) || 0;
                    const deliveryPrice = parseFloat(row.original.delivery_price) || 0;
                    const usd = parseFloat(row.original.usd) || 0;
                    const totalPrice = ((boxPrice + deliveryPrice) * usd).toFixed(1);
                    return `(${boxPrice} + ${deliveryPrice}) * ${usd} = ${totalPrice} тенге`;
                },
                Footer: ({ rows }) => {
                    const total = useMemo(() => {
                        return rows
                            .filter((row) => row.original.status === 'FOR_ISSUE')
                            .reduce((sum, row) => {
                                const boxPrice = parseFloat(row.original.box_price) || 0;
                                const deliveryPrice = parseFloat(row.original.delivery_price) || 0;
                                const usd = parseFloat(row.original.usd) || 0;
                                return sum + (boxPrice + deliveryPrice) * usd;
                            }, 0)
                            .toFixed(1);
                    }, [rows]);
                    return <div>Total: {total} тенге</div>;
                },
            },
            {
                Header: 'Статус',
                accessor: 'status',
                Cell: ({ value, row }) => {
                    if (role === 'RoleLeadAdmin') {
                        return (
                            <>
                                <select
                                    className={s['select-status']}
                                    value={selectedStatus[row.id] || value.name_ru || ''}
                                    onChange={(event) => handleStatusChange(event, row)}
                                >
                                    <option
                                        key={`${row.id}-${value.name_ru}`}
                                        className={
                                            s[
                                                `option-status ${getStatusClassName(value.name_ru || 'N/A')}`
                                            ]
                                        }
                                        value={language === 'ru' ? value.name_ru : value.name_kz}
                                    >
                                        {language === 'ru' ? value.name_ru : value.name_kz}
                                    </option>
                                    {ordersData.map((stat) => (
                                        <option
                                            key={`${stat.id}-${stat.name_ru}`}
                                            className={
                                                s[
                                                    `option-status ${getStatusClassName(stat.name_ru || 'N/A')}`
                                                ]
                                            }
                                            value={language === 'ru' ? stat.name_ru : stat.name_kz}
                                        >
                                            {language === 'ru' ? stat.name_ru : stat.name_kz}
                                        </option>
                                    ))}
                                </select>
                            </>
                        );
                    } else {
                        return (
                            <p
                                className={s['status-color-name']}
                                style={StatusColor(value.name_ru)}
                            >
                                {language === 'ru' ? value.name_ru : value.name_kz}
                            </p>
                        );
                    }
                },
            },

            {
                Header: translation('plus-information'),
                accessor: 'plus-information',
                Cell: ({ row }) => (
                    <div className={s['button-div']}>
                        {row.original.status.name === 'ISSUE' ? (
                            <p
                                onClick={() => sendOrder(row.original.id)}
                                className={s['button-table']}
                            >
                                Назад
                            </p>
                        ) : (
                            ''
                        )}

                        <p onClick={() => handleOpenModal(row)} className={s['button-table']}>
                            {translation('changeButton')}
                        </p>
                        <p
                            className={s['button-table']}
                            onClick={() =>
                                navigate(`/info-china`, {
                                    state: { rowData: row.original },
                                })
                            }
                        >
                            {translation('fullInfoButton')}
                        </p>
                        {role === 'RoleLeadAdmin' ? (
                            <button
                                onClick={() => deleteOrder(row.original.id)}
                                className={s['button-table']}
                            >
                                {translation('delete')}
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                ),
            },
        ],
        [selectedStatus, translation, language, total, role, tableData],
    );
    const tableInstance = useTable({
        columns,
        data: tableData,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    const getStatusClassName = (status) => {
        switch (status) {
            case 'Выдан':
                return s['status-waiting'];
            case 'Новый':
                return s['status-cargo'];
            case 'На складе':
                return s['status-postomat'];
            case 'В Алматы':
                return s['status-lost'];
            case 'Общий':
                return s['status-lost'];
            default:
                return '';
        }
    };

    return (
        <div>
            <div className={s['table-order']}>
                {tableData.length === 0 ? (
                    <ArrayIsEmpty textOfEmpty={translation('emptyPageOrder')} />
                ) : (
                    <>
                        <CustomModal visible={openModal} onClose={handleCloseModal}>
                            <div className={s['modal-div']}>
                                <p className={s['modal-title']}>{translation('editOrder')}</p>
                                <div className={s['input-div']}>
                                    <div className={s['input-container']}>
                                        <input
                                            className={s['input-modal']}
                                            name="name"
                                            value={inputData.name}
                                            onChange={handleInputChange}
                                        />
                                        <label
                                            className={`${s['floating-label']} ${inputData.name ? s.filled : ''}`}
                                        >
                                            {translation('searchInputBracodePla')}
                                        </label>
                                    </div>
                                    <div className={s['input-container']}>
                                        <input
                                            className={s['input-modal']}
                                            name="price"
                                            type={'number'}
                                            value={inputData.price}
                                            onChange={handleInputChange}
                                        />
                                        <label
                                            className={`${s['floating-label']} ${inputData.price ? s.filled : ''}`}
                                        >
                                            {translation('priceCargoInput')}
                                        </label>
                                    </div>
                                    <div className={s['input-container']}>
                                        <input
                                            type={'number'}
                                            className={s['input-modal']}
                                            name="amount"
                                            value={inputData.amount}
                                            onChange={handleInputChange}
                                        />
                                        <label
                                            className={`${s['floating-label']} ${inputData.amount ? s.filled : ''}`}
                                        >
                                            {translation('quantity')}
                                        </label>
                                    </div>
                                    <div className={s['input-container']}>
                                        <input
                                            type={'number'}
                                            className={s['input-modal']}
                                            name="track_code"
                                            value={inputData.track_code}
                                            onChange={handleInputChange}
                                        />
                                        <label
                                            className={`${s['floating-label']} ${inputData.track_code ? s.filled : ''}`}
                                        >
                                            {translation('trekCodeChangeInput')}
                                        </label>
                                    </div>
                                </div>
                                <div className={s['modal-button']}>
                                    <button
                                        className={s['button-modal']}
                                        onClick={handleCloseModal}
                                    >
                                        {translation('otmena')}
                                    </button>
                                    <button className={s['button-modal']} onClick={saveUserChanges}>
                                        {translation('save')}
                                    </button>
                                </div>
                            </div>
                        </CustomModal>
                        <table className={s['table']} {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => {
                                    const { key: headerGroupKey, ...headerGroupRest } =
                                        headerGroup.getHeaderGroupProps();
                                    return (
                                        <tr key={headerGroupKey} {...headerGroupRest}>
                                            {headerGroup.headers.map((column) => {
                                                const { key: columnKey, ...columnRest } =
                                                    column.getHeaderProps();
                                                return (
                                                    <th
                                                        key={columnKey}
                                                        className={s['th-style']}
                                                        {...columnRest}
                                                    >
                                                        {column.render('Header')}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    const { key: rowKey, ...rowRest } = row.getRowProps();
                                    return (
                                        <tr className={s['tbody']} key={rowKey} {...rowRest}>
                                            {row.cells.map((cell) => {
                                                const { key: cellKey, ...cellRest } =
                                                    cell.getCellProps();
                                                return (
                                                    <td
                                                        key={cellKey}
                                                        className={s['td-style']}
                                                        {...cellRest}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className={s['mobile-view']}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <div key={row.id} className={s['mobile-row']}>
                                        {row.cells.map((cell, cellIndex) => (
                                            <div
                                                key={cell.column.id}
                                                className={`${s['mobile-cell']} ${cellIndex === 3 ? s['with-line'] : ''}`}
                                            >
                                                <div className={s['mobile-header']}>
                                                    <span className={s['row-text-1']}>
                                                        {cellIndex === row.cells.length - 1
                                                            ? ''
                                                            : `${cell.column.Header}:`}
                                                    </span>
                                                </div>
                                                <div className={s['mobile-content']}>
                                                    <span className={s['row-text']}>
                                                        {cell.render('Cell')}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
            <div className={s['div-pages']}>
                {totalPages > 1 && (
                    <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
                )}
            </div>
        </div>
    );
};

export default CargoTable;
