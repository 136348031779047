import { Button, Flex, Form, Input, Select, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useUpdateUser } from '../../../api/auth/auth-hooks';
import { Option } from 'antd/es/mentions';
import useCities from '../../../api/common/common-hooks';
import React, { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import './style.css';

const AddressForm = ({ data, closeModal, refretch }) => {
    const { data: cities } = useCities();
    const [city, setCity] = useState();
    const { mutate } = useUpdateUser();
    const decoded = jwtDecode(Cookies.get('token'));

    const handleChange = (value, option) => {
        setCity({ id: value, name: option.children });
    };
    const [form] = useForm();

    const onSubmit = () => {
        mutate(
            {
                fullName: '',
                lastName: '',
                email: '',
                city_id: city.id,
                cityName: city.name,
                filialId: '',
                street: form.getFieldValue('street'),
                house: form.getFieldValue('house'),
                phone: '',
                userId: decoded.id,
            },
            {
                onSuccess: () => {
                    closeModal();
                    refretch();
                },
            },
        );
    };

    return (
        <>
            <Typography.Title
                level={3}
                style={{ color: '#100E71', fontFamily: 'Roboto, san-serif', fontWeight: '600' }}
            >
                Адрес проживания
            </Typography.Title>
            <Select
                className={'selector-style'}
                style={{ width: '100%' }}
                onChange={handleChange}
                placeholder={`Выберите город`}
            >
                {cities?.map((item) => (
                    <Option value={item.id} key={item.id}>
                        {item.name}
                    </Option>
                ))}
            </Select>
            <Form form={form} style={{ padding: '0', marginTop: '20px' }}>
                <Flex gap="24px">
                    <Form.Item name="street" style={{ width: '100%' }}>
                        <Input className={'input-style'} placeholder="Улица" />
                    </Form.Item>
                    <Form.Item name="house" style={{ width: '100%' }}>
                        <Input className={'input-style'} placeholder="Дом" />
                    </Form.Item>
                </Flex>
            </Form>
            <Flex justify="center">
                <Button className={'button-style'} onClick={onSubmit}>
                    Изменить
                </Button>
            </Flex>
        </>
    );
};
export default AddressForm;
