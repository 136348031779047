export function RedirectIcon() {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52148 7C5.25627 7 5.00191 7.10536 4.81438 7.29289C4.62684 7.48043 4.52148 7.73478 4.52148 8V19C4.52148 19.2652 4.62684 19.5196 4.81438 19.7071C5.00191 19.8946 5.25627 20 5.52148 20H16.5215C16.7867 20 17.0411 19.8946 17.2286 19.7071C17.4161 19.5196 17.5215 19.2652 17.5215 19V13C17.5215 12.4477 17.9692 12 18.5215 12C19.0738 12 19.5215 12.4477 19.5215 13V19C19.5215 19.7957 19.2054 20.5587 18.6428 21.1213C18.0802 21.6839 17.3171 22 16.5215 22H5.52148C4.72583 22 3.96277 21.6839 3.40016 21.1213C2.83755 20.5587 2.52148 19.7957 2.52148 19V8C2.52148 7.20435 2.83755 6.44129 3.40016 5.87868C3.96277 5.31607 4.72584 5 5.52148 5H11.5215C12.0738 5 12.5215 5.44772 12.5215 6C12.5215 6.55228 12.0738 7 11.5215 7H5.52148Z"
        fill="#1B63CF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5215 3C14.5215 2.44772 14.9692 2 15.5215 2H21.5215C22.0738 2 22.5215 2.44772 22.5215 3V9C22.5215 9.55228 22.0738 10 21.5215 10C20.9692 10 20.5215 9.55228 20.5215 9V4H15.5215C14.9692 4 14.5215 3.55228 14.5215 3Z"
        fill="#1B63CF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2286 2.29289C22.6191 2.68342 22.6191 3.31658 22.2286 3.70711L11.2286 14.7071C10.8381 15.0976 10.2049 15.0976 9.81438 14.7071C9.42385 14.3166 9.42385 13.6834 9.81438 13.2929L20.8144 2.29289C21.2049 1.90237 21.8381 1.90237 22.2286 2.29289Z"
        fill="#1B63CF"
      />
    </svg>
  );
}
