import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.css';
import { getDeletedCargo } from '../../api/cargo-moderator-api/cargo-moderator-api';

export const columns = [
    'nameOrder',
    'idCode',
    'trackCode',
    'price',
    'amount',
    'taridId',
    'weight',
    'deleted_at',
    'functions',
];

const data = [
    {
        nameOrder: 'dewd',
        idCode: 'dewd',
        trackCode: 'dewd',
        price: 'dewd',
        amount: 'dewd',
        taridId: 'dewd',
        weight: 'dewd',
        deleted_at: 'dewd',
    },
    {
        nameOrder: 'dewd',
        idCode: 'dewd',
        trackCode: 'dewd',
        price: 'dewd',
        amount: 'dewd',
        taridId: 'dewd',
        weight: 'dewd',
        deleted_at: 'dewd',
    },
];

const DeletedCargoPage = ({ role }) => {
    const { t } = useTranslation();

    const [selectedCountry, setSelectedCountry] = useState('china');

    const [searchInput, setSearchInput] = useState('');
    const [deletedCargoList, setDeletedCargoList] = useState([]);

    const handleRecoverCargo = () => {
        console.log();
    };

    const getAndUpdateData = () => {
        getDeletedCargo({
            start: new Date(),
            end: new Date(),
            page: 0,
            number: 100,
            sort: 'ASC',
        }).then((data) => setDeletedCargoList(data.content));
    };

    // useEffect(() => {
    //     if (searchInput) {
    //         getDeletedCargo({ start: new Date(), end: new Date(), page: 0, number: 100 }).then(
    //             (data) => {
    //                 console.log('data ', data);
    //                 setDeletedCargoList(data.content);
    //             },
    //         );
    //     } else {
    //         getAndUpdateData();
    //     }
    // }, [searchInput]);

    // useEffect(() => {
    //     getAndUpdateData();
    // }, []);

    return (
        <div className={s['container']}>
            <h1 className={s['title']}>{t('deletedCargo')}</h1>
            <div className={s['tabs']}>
                <span
                    className={s[selectedCountry === 'turkey' ? 'selectedTab' : 'tab']}
                    onClick={() => setSelectedCountry('turkey')}
                >
                    {t('ordersFromTurkey')}
                </span>
                <span
                    className={s[selectedCountry === 'china' ? 'selectedTab' : 'tab']}
                    onClick={() => setSelectedCountry('china')}
                >
                    {t('china')}
                </span>
            </div>
            <input
                className={s['input-style']}
                placeholder={t('searchPlaceholder')}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />
            <div className={s['table']}>
                <div className={s['table-columns-wrap']}>
                    {columns.map((column, index) => (
                        <span key={index} className={s['table-cell']}>
                            {t(column)}
                        </span>
                    ))}
                </div>
                <div className={s['table-data']}>
                    {data.map((row, index) => (
                        <div key={index} className={s['table-columns-wrap']}>
                            <span className={s['table-cell']}>{row.nameOrder}</span>
                            <span className={s['table-cell']}>{row.idCode}</span>
                            <span className={s['table-cell']}>{row.trackCode}</span>
                            <span className={s['table-cell']}>{row.price}</span>
                            <span className={s['table-cell']}>{row.amount}</span>
                            <span className={s['table-cell']}>{row.taridId}</span>
                            <span className={s['table-cell']}>{row.weight}</span>
                            <span className={s['table-cell']}>{row.deleted_at}</span>
                            <span className={s['table-cell']}>
                                <button
                                    className={s['primary-button']}
                                    onClick={() => handleRecoverCargo(row)}
                                >
                                    {t('recover')}
                                </button>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default DeletedCargoPage;
