import {
    ForbiddenIcon1,
    ForbiddenIcon2,
    ForbiddenIcon3,
    ForbiddenIcon4,
    ForbiddenIcon5,
    ForbiddenIcon6,
    ForbiddenIcon7,
    ForbiddenIcon8,
} from '../../assets';
export const forbiddenData = [
    {
        iconsList: [ForbiddenIcon1, ForbiddenIcon2],
        content: [
            {
                title: 'forbiddenTitle1',
                list: ['forbiddenItem1_1', 'forbiddenItem1_2'],
            },
            {
                title: 'forbiddenTitle2',
                list: [
                    'forbiddenItem2_1',
                    'forbiddenItem2_2',
                    'forbiddenItem2_3',
                    'forbiddenItem2_4',
                    'forbiddenItem2_5',
                    'forbiddenItem2_6',
                    'forbiddenItem2_7',
                ],
            },
        ],
    },
    {
        iconsList: [ForbiddenIcon3],
        content: [
            {
                title: 'forbiddenTitle3',
                list: ['forbiddenItem3_1', 'forbiddenItem3_2'],
            },
            {
                title: 'forbiddenTitle4',
                list: [
                    'forbiddenItem4_1',
                    'forbiddenItem4_2',
                    'forbiddenItem4_3',
                    'forbiddenItem4_4',
                    'forbiddenItem4_5',
                    'forbiddenItem4_6',
                    'forbiddenItem4_7',
                    'forbiddenItem4_8',
                    'forbiddenItem4_9',
                ],
            },
            {
                title: 'forbiddenTitle5',
                list: [
                    'forbiddenItem5_1',
                    'forbiddenItem5_2',
                    'forbiddenItem5_3',
                    'forbiddenItem5_4',
                ],
            },
        ],
    },
    {
        iconsList: [ForbiddenIcon4],
        content: [
            {
                title: 'forbiddenTitle6',
                list: [
                    'forbiddenItem6_1',
                    'forbiddenItem6_2',
                    'forbiddenItem6_3',
                    'forbiddenItem6_4',
                    'forbiddenItem6_5',
                    'forbiddenItem6_6',
                    'forbiddenItem6_7',
                    'forbiddenItem6_8',
                    'forbiddenItem6_9',
                    'forbiddenItem6_10',
                    'forbiddenItem6_11',
                ],
            },
            {
                title: 'forbiddenTitle7',
                list: ['forbiddenItem7_1', 'forbiddenItem7_2'],
            },
        ],
    },
    {
        iconsList: [ForbiddenIcon5],
        content: [
            { title: 'forbiddenTitle8' },
            { title: 'forbiddenTitle9' },
            { title: 'forbiddenTitle10' },
            { title: 'forbiddenTitle11' },
            { title: 'forbiddenTitle12' },
        ],
    },
    {
        iconsList: [ForbiddenIcon6, ForbiddenIcon7],
        content: [
            { title: 'forbiddenTitle13' },
            { title: 'forbiddenTitle14' },
            { title: 'forbiddenTitle15' },
            { title: 'forbiddenTitle16' },
            { title: 'forbiddenTitle17' },
        ],
    },
    {
        iconsList: [ForbiddenIcon5],
        content: [{ title: 'forbiddenTitle18' }, { title: 'forbiddenTitle19' }],
    },
    {
        iconsList: [ForbiddenIcon5],
        content: [{ title: 'forbiddenTitle20' }],
    },
    {
        iconsList: [ForbiddenIcon8],
        content: [{ title: 'forbiddenTitle21' }],
    },
    {
        iconsList: [ForbiddenIcon5],
        content: [{ title: 'forbiddenTitle22' }],
    },
];
export const aggreementData = [
    {
        title: 'aggreementTitle1',
        list: [
            'aggreementItem1_1',
            'aggreementItem1_2',
            'aggreementItem1_3',
            'aggreementItem1_4',
            'aggreementItem1_5',
            'aggreementItem1_6',
            'aggreementItem1_7',
            'aggreementItem1_8',
            'aggreementItem1_9',
            'aggreementItem1_10',
            'aggreementItem1_11',
            'aggreementItem1_12',
        ],
    },
    {
        title: 'aggreementTitle2',
        list: ['aggreementItem2_1', 'aggreementItem2_2'],
    },
    {
        title: 'aggreementTitle3',
        subTitle: 'aggreementSubTitle3',
        isNumbers: true,
        list: [
            'aggreementItem3_1',
            'aggreementItem3_2',
            'aggreementItem3_3',
            'aggreementItem3_4',
            'aggreementItem3_5',
            'aggreementItem3_6',
        ],
    },
    {
        title: 'aggreementTitle4',
        list: [
            'aggreementItem4_1',
            'aggreementItem4_2',
            'aggreementItem4_3',
            'aggreementItem4_4',
            'aggreementItem4_5',
            'aggreementItem4_6',
            'aggreementItem4_7',
            'aggreementItem4_8',
        ],
    },
    {
        title: 'aggreementTitle5',
        list: ['aggreementItem5_1', 'aggreementItem5_2'],
    },
    {
        title: 'aggreementTitle6',
        list: ['aggreementItem6_1', 'aggreementItem6_2'],
    },
];

export const statusesData = [
    {
        title: 'new',
        text: 'status1',
        type: 'grey',
    },
    {
        title: 'forIssuance',
        text: 'status2',
        type: 'blue',
    },
    {
        title: 'given',
        text: 'status3',
        type: 'green',
    },
    {
        title: 'general',
        text: 'status4',
        type: 'orange',
    },
    {
        title: 'inStockChina',
        text: 'status5',
        type: 'orange',
    },
    {
        title: 'inAlmaty',
        text: 'status6',
        type: 'orange',
    },
    {
        title: 'inRou',
        text: 'status7',
        type: 'orange',
    },
    {
        title: 'inBranch',
        text: 'status8',
        type: 'orange',
    },
    {
        title: 'inStock',
        text: 'status9',
        type: 'orange',
    },
    {
        title: 'inFilialFilial',
        text: 'status10',
        type: 'orange',
    },
    {
        title: 'paymentError',
        text: 'status11',
        type: 'red',
    },
    {
        title: 'orderLost',
        text: 'status12',
        type: 'red',
    },
    {
        title: 'rejected',
        text: 'status13',
        type: 'grey',
    },
    {
        title: 'cancelled',
        text: 'status14',
        type: 'grey',
    },
    {
        title: 'refused',
        text: 'status15',
        type: 'grey',
    },
    {
        title: 'completed',
        text: 'status16',
        type: 'grey',
    },
];
