import React from 'react';
import s from './styles.module.css';

const Input = ({ value, onChange, label, id, placeholder, type = 'text' }) => {
    return (
        <div className={s['input-wrap']}>
            <label htmlFor={id} className={s['input-label']}>
                {label}
            </label>
            <input
                className={s['input-style']}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                id={id}
                type={type}
            />
        </div>
    );
};

export default Input;
