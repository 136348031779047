import { useContext, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Button, Form, Input, message, Typography } from 'antd';
import { UserInfoContext } from '../index';
import s from '../styles.module.css';

export default function PasswordStep({ setIsNextStep }) {
    const [form] = useForm();
    const context = useContext(UserInfoContext);
    const [passwordError, setPasswordError] = useState('');

    const onNext = () => {
        const password = form.getFieldValue('password');
        const confirmPassword = form.getFieldValue('confirmPassword');
        const number = context.user.phone;
        if (password.length < 4) {
            setPasswordError('Пароль должен быть длиннее 4 символов');
            return;
        }
        if (password === confirmPassword) {
            setPasswordError('');
            context.setUser({
                username: '',
                phone: number,
                password,
                smsCode: '',
                address: '',
                cityId: '',
                filialId: '',
                house: '',
            });
            setIsNextStep('city');
        } else {
            setPasswordError('Пароли не совпадают!');
            message.error('Пароли не совпадают!');
        }
    };

    return (
        <>
            <Typography.Title style={{ color: 'white', fontWeight: '400' }} level={3}>
                Придумайте пароль
            </Typography.Title>
            <Form style={{ color: 'white' }} layout="vertical" form={form}>
                <Form.Item
                    label={<Typography.Text style={{ color: 'white' }}>Пароль</Typography.Text>}
                    rules={[{ min: 4, message: 'Введите минимум 4 символа' }]}
                    style={{ marginBottom: '12px' }}
                    name="password"
                >
                    <Input type={'password'} placeholder="Пароль" className={s['register-input']} />
                </Form.Item>
                {passwordError && (
                    <Typography.Text style={{ color: 'white', fontSize: '12px' }}>
                        {passwordError}
                    </Typography.Text>
                )}
                <Form.Item
                    label={
                        <Typography.Text style={{ color: 'white' }}>
                            Подтвердите пароль
                        </Typography.Text>
                    }
                    rules={[{ min: 4, message: 'Введите минимум 4 символа' }]}
                    name="confirmPassword"
                >
                    <Input type={'password'} placeholder="Подтвердите пароль" className={s['register-input']} />
                </Form.Item>
                <Button style={{ width: '100%', height: '35px' }} onClick={onNext}>
                    <Typography.Text style={{ color: '#100E71', fontWeight: '500' }}>
                        Продолжить
                    </Typography.Text>
                </Button>
            </Form>
        </>
    );
}