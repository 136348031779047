import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimePicker } from 'antd';
import { changeModeratorPassword, updateBranch } from '../../api/admin/functions';
import CustomModal from '../UI/Modal/Modal';
import dayjs from 'dayjs';

import Input from '../UI/Input';
import s from './styles.module.css';

const EditBranchForm = ({ defaultForm, onBreadcrupmClick }) => {
    const { t } = useTranslation();

    const [form, setForm] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [timeRange, setTimeRange] = useState({});
    const [isEdited, setIsEdited] = useState(false);

    const handleInputChange = (name, value) => {
        setForm((prev) => ({ ...prev, [name]: value }));
        setIsEdited(true);
    };

    const handleTimeRangeChange = (name, value) => {
        setTimeRange((prev) => ({ ...prev, [name]: value }));
    };

    const handleChangePasswordClick = () => {
        changeModeratorPassword(form.moderatorId, password)
            .then(() => setNotificationMessage(t('passwordSuccess')))
            .catch((err) => setNotificationMessage(err.response.data.msg || t('errorMessage')));
    };

    // const handleEditConfirm = () => {
    //     updateBranch(form.id, form)
    //         .then(() => setNotificationMessage(t('branchEditSuccess')))
    //         .catch((err) => setNotificationMessage(err?.response?.data?.msg || t('errorMessage')));
    // };
    const handleEditConfirm = () => {
        const updatedForm = {
            name: form.name,
            city_id: form.city_id,
            address: form.address,
            working_hour: `${timeRange?.fromTime?.slice(0, 5)}-${timeRange?.toTime?.slice(0, 5)}`,
            day_offs: form.dayOffs,
            lunch_break: form.lunchBreak,
            moderator_name: form.moderatorName,
            moderator_lastname: form.moderatorLastname,
            moderator_phone: form.moderatorPhone,
            moderator_password: password || form.moderatorPassword,
            main: true,
        };

        updateBranch(form.id, updatedForm)
            .then(() => setNotificationMessage(t('branchEditSuccess')))
            .catch((err) => setNotificationMessage(err?.response?.data?.msg || t('errorMessage')));
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPassword('');
        setRepeatPassword('');
        setNotificationMessage('');
    };

    useEffect(() => {
        let [startTime, endTime] = defaultForm?.workingHour?.split('-');
        setForm(defaultForm);
        setTimeRange({ fromTime: startTime, toTime: endTime });
    }, [defaultForm]);

    useEffect(() => {
        setTimeout(() => setNotificationMessage(''), 10000);
    }, [notificationMessage]);

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            working_hour: `${timeRange?.fromTime?.slice(0, 5)}-${timeRange?.toTime?.slice(0, 5)}`,
        }));
    }, [timeRange]);

    return (
        <div className={s['container']}>
            <div className={s['buttons-wrap']}>
                <h1 className={s['title']}>{t('branchInfo')}</h1>
                <button
                    className={s['secondary-button']}
                    onClick={handleEditConfirm}
                    disabled={!isEdited}
                >
                    {t('changeButton')}
                </button>
            </div>
            {notificationMessage && (
                <span className={s['notification']}>{notificationMessage}</span>
            )}
            <button className={s['secondary-button']} onClick={onBreadcrupmClick}>
                {t('backToMain')}
            </button>
            <div className={s['edit-form']}>
                <Input
                    id="username"
                    value={form.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    placeholder={t('branchName')}
                    label={t('name')}
                />
                <Input
                    id="address"
                    value={form.address}
                    onChange={(e) => handleInputChange('address', e.target.value)}
                    placeholder={t('address')}
                    label={t('address')}
                />
                <p>{t('schedule')}</p>
                <div className={s['buttons-wrap']}>
                    <TimePicker
                        onChange={(_, value) => handleTimeRangeChange('fromTime', value)}
                        value={dayjs(timeRange.fromTime, 'HH:mm')}
                        placeholder="От"
                        className={s['width-inherit']}
                        size={'large'}
                    />
                    <span className={s['line']} />
                    <TimePicker
                        onChange={(_, value) => handleTimeRangeChange('toTime', value)}
                        placeholder="До"
                        value={dayjs(timeRange.toTime, 'HH:mm')}
                        className={s['width-inherit']}
                        size={'large'}
                    />
                </div>
                <Input
                    id="lunchBreak"
                    value={form.lunchBreak}
                    onChange={(e) => handleInputChange('lunchBreak', e.target.value)}
                    placeholder={t('selectLunchBreak')}
                    label={t('lunchBreak')}
                />
                <Input
                    id="dayOffs"
                    value={form.dayOffs}
                    onChange={(e) => handleInputChange('dayOffs', e.target.value)}
                    placeholder={t('selectDayOffs')}
                    label={t('dayOffs')}
                />
                <p>{t('responsableInfo')}</p>
                <Input
                    id="moderatorName"
                    value={form.moderatorName}
                    onChange={(e) => handleInputChange('moderatorName', e.target.value)}
                    placeholder={t('responsableName')}
                    label={t('responsableName')}
                />
                <Input
                    id="moderatorLastname"
                    value={form.moderatorLastname}
                    onChange={(e) => handleInputChange('moderatorLastname', e.target.value)}
                    placeholder={t('responsableSurname')}
                    label={t('responsableSurname')}
                />
                <p>{t('contactDetails')}</p>
                <Input
                    id="moderatorEmail"
                    value={form.moderatorEmail}
                    onChange={(e) => handleInputChange('moderatorEmail', e.target.value)}
                    placeholder={t('email')}
                    label={t('email')}
                />
                <Input
                    id="moderatorPhone"
                    value={form.moderatorPhone}
                    onChange={(e) => handleInputChange('moderatorPhone', e.target.value)}
                    placeholder={t('telefonNumber')}
                    label={t('telefonNumber')}
                />
                <div className={s['edit-footer']}>
                    <button
                        className={s['change-password-button']}
                        onClick={() => setShowModal(true)}
                    >
                        {t('changePassword')}
                    </button>
                </div>
            </div>
            <CustomModal visible={showModal}>
                <div className={s['container']}>
                    <h1>{t('changePassword')}</h1>
                    <input
                        className={s['input-modal']}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t('password')}
                    />
                    <input
                        className={s['input-modal']}
                        type="password"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        placeholder={t('repeatPassword')}
                    />
                    <span>{notificationMessage}</span>
                    <div className={s['modal-buttons-wrap']}>
                        <button onClick={handleCloseModal} className={s['cancel-button']}>
                            {t('otmena')}
                        </button>
                        <button
                            onClick={handleChangePasswordClick}
                            className={s['continue-button']}
                            disabled={password !== repeatPassword}
                        >
                            {t('save')}
                        </button>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};
export default EditBranchForm;
