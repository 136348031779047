export function PhoneIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.79102 14.147C4.81445 13.1769 3.93555 12.1548 3.1543 11.0806C2.37956 10.0063 1.76432 8.93864 1.30859 7.87744C0.859375 6.80973 0.634766 5.79085 0.634766 4.8208C0.634766 4.18278 0.748698 3.58057 0.976562 3.01416C1.20443 2.44775 1.56576 1.93343 2.06055 1.47119C2.65299 0.904785 3.29102 0.621582 3.97461 0.621582C4.26107 0.621582 4.53125 0.680176 4.78516 0.797363C5.04557 0.914551 5.26693 1.10335 5.44922 1.36377L7.56836 4.34229C7.75065 4.58317 7.88086 4.80778 7.95898 5.01611C8.03711 5.21794 8.07617 5.40674 8.07617 5.58252C8.07617 5.81689 8.01107 6.0415 7.88086 6.25635C7.75716 6.47119 7.58464 6.69255 7.36328 6.92041L6.65039 7.64307C6.55273 7.74723 6.50391 7.87093 6.50391 8.01416C6.50391 8.09229 6.51367 8.16715 6.5332 8.23877C6.55924 8.31038 6.58529 8.37223 6.61133 8.42432C6.7806 8.73682 7.07357 9.13721 7.49023 9.62549C7.9069 10.1073 8.35938 10.5955 8.84766 11.0903C9.33594 11.5851 9.82422 12.0409 10.3125 12.4575C10.8008 12.8677 11.2012 13.1574 11.5137 13.3267C11.5658 13.3527 11.6276 13.3787 11.6992 13.4048C11.7708 13.4308 11.8457 13.4438 11.9238 13.4438C12.0801 13.4438 12.207 13.3918 12.3047 13.2876L13.0176 12.5747C13.2454 12.3468 13.4668 12.1711 13.6816 12.0474C13.8965 11.9237 14.1178 11.8618 14.3457 11.8618C14.528 11.8618 14.7201 11.9041 14.9219 11.9888C15.1237 12.0669 15.3451 12.1906 15.5859 12.3599L18.6035 14.4985C18.8574 14.6808 19.0397 14.8957 19.1504 15.1431C19.2676 15.3905 19.3262 15.6541 19.3262 15.9341C19.3262 16.2661 19.2513 16.5981 19.1016 16.9302C18.9518 17.2622 18.7435 17.5747 18.4766 17.8677C18.0208 18.3755 17.513 18.7433 16.9531 18.9712C16.3932 19.2056 15.7878 19.3228 15.1367 19.3228C14.1797 19.3228 13.1641 19.0949 12.0898 18.6392C11.0156 18.1834 9.93815 17.5649 8.85742 16.7837C7.7832 16.0024 6.76107 15.1235 5.79102 14.147Z"
        fill="#100E71"
      />
    </svg>
  );
}
