import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createTariff, updateTariff } from '../../api/admin/functions';
import doneIcon from '../../assets/done-icon.svg';
import { Input } from 'antd';

import CustomModal from '../UI/Modal/Modal';
import s from './styles.module.css';

const TariffFormModal = ({ visible, defaultForm, onCloseModal, updateData }) => {
    const { t } = useTranslation();

    const [form, setForm] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (name, value) => {
        setForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleCloseModal = () => {
        onCloseModal();
        setForm({});
        updateData();
        setIsSuccess(false);
    };

    const handleConfirmClick = () => {
        if (form.id) {
            updateTariff(form.id, form)
                .then(() => {
                    setIsSuccess(true);
                    updateData();
                })
                .catch((err) => setErrorMessage(err?.response?.data?.msg || t('errorMessage')));
        } else {
            createTariff(form)
                .then(() => {
                    setIsSuccess(true);
                    updateData();
                })
                .catch((err) => setErrorMessage(err?.response?.data?.msg || t('errorMessage')));
        }
    };

    useEffect(() => {
        setForm(defaultForm);
        return () => setIsSuccess(false);
    }, [defaultForm]);

    return (
        <CustomModal visible={visible}>
            <div className={s['modal-container']}>
                {isSuccess ? (
                    <div className={s['success-modal']}>
                        <p className={s['modal-title']}>{t('successNotif')}</p>
                        <img src={doneIcon} alt="done" />
                        <button
                            onClick={onCloseModal}
                            className={s['continue-button']}
                            style={{ width: '80%' }}
                        >
                            {t('continue')}
                        </button>
                    </div>
                ) : (
                    <>
                        <p className={s['modal-title']}>{t('createTariff')}</p>
                        <div className={s['form-wrap']}>
                            <p className={s['input-header']}>{t('tariffName')}</p>
                            <Input
                                className={s['input-modal']}
                                name="name"
                                value={form.name}
                                onChange={(e) => handleInputChange('name', e.target.value)}
                                placeholder={t('enterTariffName')}
                            />
                            <p className={s['input-header']}>{t('price')}</p>
                            <Input
                                className={s['input-modal']}
                                name="price"
                                value={form.price}
                                onChange={(e) => handleInputChange('price', e.target.value)}
                                placeholder={t('enterPrice')}
                                type="number"
                                prefix="$"
                            />
                            <p className={s['input-header']}>{t('west_price')}</p>
                            <Input
                                id="west_price"
                                className={s['input-modal']}
                                value={form.west_price}
                                onChange={(e) =>
                                    handleInputChange('west_price', e.target.value)
                                }
                                placeholder={t('enterWestPrice')}
                                type="number"
                                prefix="$"
                            />
                            <p className={s['input-header']}>{t('work_price')}</p>
                            <Input
                                id="work_price"
                                className={s['input-modal']}
                                value={form.work_price}
                                onChange={(e) =>
                                    handleInputChange('work_price', e.target.value)
                                }
                                placeholder={t('enterWorkPrice')}
                                type="number"
                                prefix="$"
                            />
                            <p className={s['input-header']}>{t('weight')}</p>
                            <Input
                                id="weight"
                                className={s['input-modal']}
                                value={form.weight}
                                onChange={(e) =>
                                    handleInputChange('weight', e.target.value)
                                }
                                placeholder={t('enterWeight')}
                                type="number"
                                prefix="кг"
                            />
                        </div>
                        <div className={s['modal-buttons-wrap']}>
                            <button onClick={handleCloseModal} className={s['cancel-button']}>
                                {t('otmena')}
                            </button>
                            <button onClick={handleConfirmClick} className={s['continue-button']}>
                                {t('save')}
                            </button>
                        </div>
                        {errorMessage && <p>{errorMessage}</p>}
                    </>
                )}
            </div>
        </CustomModal>
    );
};
export default TariffFormModal;
