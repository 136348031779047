import s from "../../pages/info-page/styles.module.css";

export function CubeIcons({styleSVG}) {
    return (
        <svg className={s[`${styleSVG}`]} viewBox="0 0 25 25"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.23047 3.17627C2.23047 2.62398 2.67818 2.17627 3.23047 2.17627H10.2305C10.7828 2.17627 11.2305 2.62398 11.2305 3.17627V10.1763C11.2305 10.7286 10.7828 11.1763 10.2305 11.1763H3.23047C2.67818 11.1763 2.23047 10.7286 2.23047 10.1763V3.17627ZM4.23047 4.17627V9.17627H9.23047V4.17627H4.23047Z"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.2305 3.17627C13.2305 2.62398 13.6782 2.17627 14.2305 2.17627H21.2305C21.7828 2.17627 22.2305 2.62398 22.2305 3.17627V10.1763C22.2305 10.7286 21.7828 11.1763 21.2305 11.1763H14.2305C13.6782 11.1763 13.2305 10.7286 13.2305 10.1763V3.17627ZM15.2305 4.17627V9.17627H20.2305V4.17627H15.2305Z"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.2305 14.1763C13.2305 13.624 13.6782 13.1763 14.2305 13.1763H21.2305C21.7828 13.1763 22.2305 13.624 22.2305 14.1763V21.1763C22.2305 21.7286 21.7828 22.1763 21.2305 22.1763H14.2305C13.6782 22.1763 13.2305 21.7286 13.2305 21.1763V14.1763ZM15.2305 15.1763V20.1763H20.2305V15.1763H15.2305Z"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.23047 14.1763C2.23047 13.624 2.67818 13.1763 3.23047 13.1763H10.2305C10.7828 13.1763 11.2305 13.624 11.2305 14.1763V21.1763C11.2305 21.7286 10.7828 22.1763 10.2305 22.1763H3.23047C2.67818 22.1763 2.23047 21.7286 2.23047 21.1763V14.1763ZM4.23047 15.1763V20.1763H9.23047V15.1763H4.23047Z"
            />
        </svg>
    )
}