import { useState } from "react";
import Cookies from "js-cookie";

export const useLocalstorage = (key, defaultValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const cookieValue = Cookies.get(key);
            if (cookieValue) {
                return JSON.parse(cookieValue);
            } else {
                const localValue = localStorage.getItem(key);
                if (localValue) {
                    return JSON.parse(localValue);
                } else {
                    localStorage.setItem(key, JSON.stringify(defaultValue));
                    return defaultValue;
                }
            }
        } catch (e) {
            console.error(e);
            return defaultValue;
        }
    });

    const setValue = (newValue) => {
        try {
            localStorage.setItem(key, JSON.stringify(newValue));
            Cookies.set(key, JSON.stringify(newValue));
            setStoredValue(newValue);
        } catch (e) {
            console.error(e);
        }
    };

    return [storedValue, setValue];
};
