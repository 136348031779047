import axios from "axios";
import { baseURL } from "../baseURL";
import Cookies from "js-cookie";

export default async function getOrderReport() {
  const { data } = await axios.get(`${baseURL}amantrans/orders/report`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  });

  return data;
}
