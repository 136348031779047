import { useMutation, useQuery } from 'react-query';
import { axiosInstances } from '../axios-instances';
import Cookies from 'js-cookie';

export const useCities = () => {
    return useQuery('cities', async () => {
        const response = await axiosInstances.get('/amantrans/cities');
        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }
        return response.data;
    });
};
export const useBuyers = () => {
    return useQuery('buyers', async () => {
        const response = await axiosInstances.get('/amantrans/moderators/buyers');
        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }
        return response.data;
    });
};
export const useFilias = (cityId) => {
    return useQuery(
        ['filias', cityId],
        async () => {
            if (!cityId) return null;
            const response = await axiosInstances.get('/amantrans/filials-by-filter', {
                params: { cityId },
            });
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        {
            enabled: !!cityId,
        },
    );
};

export const useCreateUser = () => {
    return useMutation(async (user) => {
        const response = await axiosInstances.post(`/amantrans/customers/admin/create`, user, {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}`,
            },
        });
        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }
        return response.data;
    });
};
