import { Typography } from 'antd';
import './style.css';

const SelectItem = ({ title, value, icon, onOpen }) => {
    return (
        <div
            style={{
                margin: '20px',
                padding: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
            }}
            onClick={onOpen}
        >
            <div style={{ display: 'flex' }}>
                <div className="icon-container">{icon}</div>
                <div>
                    <Typography.Title level={5} style={{ margin: '0' }}>
                        {title}
                    </Typography.Title>
                    <Typography.Text level={3} style={{ color: '#AAADB2' }}>
                        {value}
                    </Typography.Text>
                </div>
            </div>
            <div>
                <Typography.Text style={{ fontWeight: '700', fontSize: '25px', color: '#100E71' }}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6L8.58997 7.41Z"
                            fill="#100E71"
                        />
                    </svg>
                </Typography.Text>
            </div>
        </div>
    );
};
export default SelectItem;
