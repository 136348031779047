import s from './styles.module.css';
import React, { useMemo, useState } from "react";
import { useTable } from "react-table";
import ArrayIsEmpty from "../../UI/Array-is-empty/Array-is-empty";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getBarcode } from "../../../api/cargo-barcode-api/cargo-barcode-api";
import {axiosInstances} from "../../../api/axios-instances";
import {DonwloadIocns} from "../../../icons/cargo-barcode-icons/donwloadIocns";
import Spinner from "../../UI/Spinner/Spinner";

const CargoBarcode = ({ translation }) => {
    const queryClient = useQueryClient();
    const { data: initialData, error, isLoading } = useQuery(['barcodes'], getBarcode, { keepPreviousData: true });

    const [tableData, setTableData] = useState([]);

    useMemo(() => {
        if (initialData) {
            setTableData(initialData.map((city) => ({
                city: city.name,
                id: city.id,
                amount: 1,
                plusInfo: 'забрать',
            })));
        }
    }, [initialData]);
    const getBarcodeChina = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.post(`/amantrans/admin/china-box-code/generate`, user, {
                responseType: 'blob',
            });
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'barcode.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            queryClient.invalidateQueries(['barcodes']);
        },
        onError: (error) => {
            console.error('donwload error:', error);
        },
    });
    const getBarcodeChinaAll = useMutation({
        mutationFn: async () => {
            const response = await axiosInstances.post(`/amantrans/admin/china-box-code/generate-all`, '', {
                responseType: 'blob',
            });
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'barcode.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            queryClient.invalidateQueries(['barcodes']);
        },
        onError: (error) => {
            console.error('donwload error:', error);
        },
    });
    const getBarcodeAllHandler = () => {
        getBarcodeChinaAll.mutate();
    }
    const getBarcodeChinaHandler = (value) => {
        if (!value.id || !value.amount) {
            console.error("Invalid value provided:", value);
            return;
        }
        const body = {
            country: "China",
            boxes: [
                {
                    cityId: value.id,
                    boxCount: value.amount,
                }
            ]
        };
        getBarcodeChina.mutate(body);
    };

    const handleAmountChange = (rowIndex, value) => {
        const newData = [...tableData];
        newData[rowIndex].amount = value;
        setTableData(newData);
    };

    const columns = useMemo(
        () => [
            {
                Header: translation('city'),
                accessor: 'city',
            },
            {
                Header: translation('amountOrder'),
                accessor: 'amount',
                Cell: ({ row, value }) => (
                    <select
                        value={value}
                        onChange={(e) => handleAmountChange(row.index, parseInt(e.target.value))}
                    >
                        {[...Array(10).keys()].map(i => (
                            <option key={i + 1} value={i + 1}>
                                {i + 1}
                            </option>
                        ))}
                    </select>
                ),
            },
            {
                Header: translation("plus-information"),
                accessor: 'plusInfo',
                Cell: ({ row }) => (
                    <div className={s['button-div-table']}>
                        <p onClick={() => getBarcodeChinaHandler(row.original)} className={s['button-table']}>
                            {translation('take')}
                        </p>
                    </div>
                ),
            },
        ],
        [tableData]
    );

    const tableInstance = useTable({
        columns,
        data: tableData,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    if (isLoading) return <Spinner />;
    if (error) return <div>There was an error!</div>;

    return (
        <div className={s['content']}>

            <div className={s['donwload-div']}>
                <button onClick={() => getBarcodeAllHandler()} className={s['donwload-button']}>
                    <DonwloadIocns/>
                    {translation('tableDonwload')}
                </button>
            </div>
            <div className={s['table-order']}>
                {tableData.length === 0 ? (
                    <ArrayIsEmpty textOfEmpty={translation('emptyPageOrder')} />
                ) : (
                    <>
                        <table className={s['table']} {...getTableProps()}>
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th key={column.id} className={s['th-style']} {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td key={cell.column.id} className={s['td-style']} {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        <div className={s['mobile-view']}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <div key={row.id} className={s['mobile-row']}>
                                        {row.cells.map((cell, cellIndex) => (
                                            <div
                                                key={cell.column.id}
                                                className={`${s['mobile-cell']} ${cellIndex === 3 ? s['with-line'] : ''}`}
                                            >
                                                <div className={s['mobile-header']}>
                                                    <p className={s['row-text-1']}>
                                                        {cellIndex === row.cells.length - 1 ? '' : `${cell.column.Header}:`}
                                                    </p>
                                                </div>
                                                <div className={s['mobile-content']}>
                                                    <p className={s['row-text']}>{cell.render('Cell')}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default CargoBarcode;
