import { useMutation, useQuery } from 'react-query';
import { message } from 'antd';
import createUser, { changePassword, getUserDetail, loginUser, updateUser } from './auth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

export function useLoginUser() {
    const navigate = useNavigate();

    const { mutate, isSuccess, data } = useMutation(
        ['loginUser'],
        ({ password, phoneNumber, clientType }) =>
            loginUser({
                phoneNumber,
                password,
                clientType,
            }),
        {
            onSuccess: (data) => {
                if (data.token) {
                    Cookies.set('token', data.token, { expires: 3650 });
                    const decodedToken = jwtDecode(data.token);
                    if (
                        decodedToken.role === 'RoleAdmin' ||
                        decodedToken.role === 'RoleLeadAdmin'
                    ) {
                        navigate('/admin-main');
                    } else if (decodedToken.role === 'RoleClient') {
                        navigate('/client-main');
                    } else {
                        message.error('Неизвестная роль пользователя');
                    }
                } else {
                    message.error(data.name);
                }
            },
            onError: (error) => {
                message.error('Ошибка авторизации');
            },
        },
    );

    return { isSuccess, mutate, data };
}

export default function useCreateUser(setIsNext) {
    const navigate = useNavigate();
    const { mutate, isSuccess } = useMutation(
        ['createUser'],
        ({ username, phone, password, smsCode, cityId, filialId, address, house }) =>
            createUser({
                username,
                phone,
                password,
                smsCode,
                cityId,
                filialId,
                address,
                house,
            }),
        {
            onSuccess: (data) => {
                if (data.token) {
                    Cookies.set('token', data.token, { expires: 3650 });
                    const decodedToken = jwtDecode(data.token);
                    if (
                        decodedToken.role === 'RoleAdmin' ||
                        decodedToken.role === 'RoleLeadAdmin'
                    ) {
                        navigate('/admin-main');
                    } else if (decodedToken.role === 'RoleClient') {
                        navigate('/client-main');
                    } else {
                        message.error('Неизвестная роль пользователя');
                    }
                } else {
                    message.error(data.name);
                }
                if (data.name === 'Пользователь с таким номером уже существует') {
                    setIsNext('first');
                }
            },
            onError: (error) => {
                message.error('Ошибка авторизации');
            },
        },
    );

    return { isSuccess, mutate };
}

export function useUpdateUser() {
    const { mutate, isSuccess } = useMutation(
        ['updateUser'],
        ({
            fullName,
            lastName,
            email,
            iin,
            cityName,
            filialId,
            street,
            house,
            phone,
            userId,
            cityId,
        }) =>
            updateUser({
                fullName,
                lastName,
                email,
                iin,
                cityId,
                cityName,
                filialId,
                street,
                house,
                phone,
                userId,
            }),
    );

    return { isSuccess, mutate };
}

export function useChangePassword() {
    const { mutate, isSuccess } = useMutation(['password'], ({ password, data }) =>
        changePassword({
            password,
            data,
        }),
    );

    return { isSuccess, mutate };
}

export function useUserDetail() {
    const { data, refetch } = useQuery(['userDetail'], getUserDetail);

    return { data, refetch };
}
