import {axiosInstances} from "../axios-instances";
import Cookies from "js-cookie";

export const getHistoryStatus = async ({ id, country }) => {
    const response = await axiosInstances.get('/amantrans/order_turkey/get-status-info', {
        params: {
            id: id,
            country: country
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`
        }
    });
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
export const getHistoryStatusChina = async ({ id, country }) => {
    const response = await axiosInstances.get('/amantrans/order_turkey/get-status-info', {
        params: {
            id: id,
            country: 'China'
        },
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`
        }
    });
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
export const getBarcodeTurkey = async () => {
    const response = await axiosInstances.post(`/amantrans/admin/turkey-order-code/generate`, );
    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};


