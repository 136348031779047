// import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { Select, TimePicker } from 'antd';
// import { Option } from 'antd/es/mentions';
// import { createBranch } from '../../api/admin/functions';
// import dayjs from 'dayjs';
// import useCities from '../../api/common/common-hooks';
// import doneIcon from '../../assets/done-icon.svg';
//
// import CustomModal from '../UI/Modal/Modal';
// import s from './styles.module.css';
//
// const CreateBranchModal = ({ visible, onCloseModal, updateData }) => {
//     const { t } = useTranslation();
//     const { data: cities } = useCities();
//
//     const [step, setStep] = useState(1);
//     const [form, setForm] = useState({ main: true });
//     const [timeRange, setTimeRange] = useState({});
//     const [repeatPassword, setRepeatPassword] = useState('');
//     const [isSuccess, setIsSuccess] = useState(false);
//     const [errorMessage, setErrorMessage] = useState('');
//
//     const handleInputChange = (name, value) => {
//         setForm((prev) => ({ ...prev, [name]: value }));
//     };
//
//     const handleTimeRangeChange = (name, value) => {
//         setTimeRange((prev) => ({ ...prev, [name]: value }));
//     };
//
//     const handleNextClick = () => {
//         if (step === 4) {
//             confirmCreateBranch();
//         } else {
//             setStep((prev) => prev + 1);
//         }
//     };
//
//     const handleCloseModal = () => {
//         onCloseModal();
//         setStep(1);
//         setForm({});
//         updateData();
//     };
//
//     const confirmCreateBranch = () => {
//         createBranch(form)
//             .then(() => setIsSuccess(true))
//             .catch((err) => setErrorMessage(err?.response?.data?.msg || t('errorMessage')));
//     };
//
//     useEffect(() => {
//         setForm((prev) => ({
//             ...prev,
//             working_hour: `${timeRange?.fromTime?.slice(0, 5)}-${timeRange?.toTime?.slice(0, 5)}`,
//         }));
//     }, [timeRange]);
//
//     return (
//         <CustomModal visible={visible}>
//             <div className={s['modal-container']}>
//                 {isSuccess ? (
//                     <div className={s['success-modal']}>
//                         <p className={s['modal-title']}>{t('branchSuccess')}</p>
//                         <img src={doneIcon} alt="done" />
//                         <button
//                             onClick={onCloseModal}
//                             className={s['continue-button']}
//                             style={{ width: '80%' }}
//                         >
//                             {t('continue')}
//                         </button>
//                     </div>
//                 ) : (
//                     <>
//                         <p className={s['modal-title']}>{t('addBranch')}</p>
//                         {step !== 1 && (
//                             <button
//                                 className={s['secondary-button']}
//                                 onClick={() => setStep((prev) => prev - 1)}
//                             >
//                                 {t('backToMain')}
//                             </button>
//                         )}
//                         {step === 1 && (
//                             <div className={s['form-wrap']}>
//                                 <p className={s['input-header']}>{t('generalData')}</p>
//                                 <input
//                                     className={s['input-modal']}
//                                     name="name"
//                                     value={form.name}
//                                     onChange={(e) => handleInputChange('name', e.target.value)}
//                                     placeholder={t('branchName')}
//                                 />
//                                 <Select
//                                     style={{ width: '100%', height: '3.5rem' }}
//                                     onChange={(e) => handleInputChange('city_id', e)}
//                                     placeholder={t('selectCity')}
//                                     size={'large'}
//                                 >
//                                     {cities?.map((item) => (
//                                         <Option value={item.id} key={item.id}>
//                                             {item.name}
//                                         </Option>
//                                     ))}
//                                 </Select>
//                                 <input
//                                     className={s['input-modal']}
//                                     name="address"
//                                     value={form.address}
//                                     onChange={(e) => handleInputChange('address', e.target.value)}
//                                     placeholder={t('address')}
//                                 />
//                                 <p className={s['input-header']}>{t('schedule')}</p>
//                                 <div className={s['buttons-wrap']}>
//                                     <TimePicker
//                                         onChange={(_, value) =>
//                                             handleTimeRangeChange('fromTime', value)
//                                         }
//                                         defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
//                                         placeholder="От"
//                                         className={s['width-inherit']}
//                                         size={'large'}
//                                     />
//                                     <span className={s['line']} />
//                                     <TimePicker
//                                         onChange={(_, value) =>
//                                             handleTimeRangeChange('toTime', value)
//                                         }
//                                         defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
//                                         placeholder="До"
//                                         className={s['width-inherit']}
//                                         size={'large'}
//                                     />
//                                 </div>
//                                 <input
//                                     id="lunchBreak"
//                                     className={s['input-modal']}
//                                     value={form.lunch_break}
//                                     onChange={(e) =>
//                                         handleInputChange('lunch_break', e.target.value)
//                                     }
//                                     placeholder={t('selectLunchBreak')}
//                                     label={t('lunchBreak')}
//                                 />
//                                 <input
//                                     id="dayOffs"
//                                     className={s['input-modal']}
//                                     value={form.day_offs}
//                                     onChange={(e) => handleInputChange('day_offs', e.target.value)}
//                                     placeholder={t('selectDayOffs')}
//                                     label={t('dayOffs')}
//                                 />
//                             </div>
//                         )}
//                         {step === 2 && (
//                             <div className={s['form-wrap']}>
//                                 <p className={s['input-header']}>{t('responsableInfo')}</p>
//                                 <input
//                                     className={s['input-modal']}
//                                     name="responsableName"
//                                     value={form.moderator_name}
//                                     onChange={(e) =>
//                                         handleInputChange('moderator_name', e.target.value)
//                                     }
//                                     placeholder={t('responsableName')}
//                                 />
//                                 <input
//                                     className={s['input-modal']}
//                                     name="responsableSurname"
//                                     value={form.moderator_lastname}
//                                     onChange={(e) =>
//                                         handleInputChange('moderator_lastname', e.target.value)
//                                     }
//                                     placeholder={t('responsableSurname')}
//                                 />
//                             </div>
//                         )}
//                         {step === 3 && (
//                             <div className={s['form-wrap']}>
//                                 <p className={s['input-header']}>{t('contactDetails')}</p>
//                                 <input
//                                     className={s['input-modal']}
//                                     name="name"
//                                     value={form.moderator_email}
//                                     onChange={(e) =>
//                                         handleInputChange('moderator_email', e.target.value)
//                                     }
//                                     placeholder={t('email')}
//                                 />
//                                 <p className={s['input-header']}>{t('contactDetails')}</p>
//                                 <input
//                                     className={s['input-modal']}
//                                     name="address"
//                                     value={form.moderator_phone}
//                                     onChange={(e) =>
//                                         handleInputChange('moderator_phone', e.target.value)
//                                     }
//                                     placeholder={t('phone')}
//                                     type={'number'}
//                                 />
//                             </div>
//                         )}
//                         {step === 4 && (
//                             <div className={s['form-wrap']}>
//                                 <input
//                                     className={s['input-modal']}
//                                     name="moderator_password"
//                                     type="password"
//                                     value={form.moderator_password}
//                                     onChange={(e) =>
//                                         handleInputChange('moderator_password', e.target.value)
//                                     }
//                                     placeholder={t('password')}
//                                 />
//                                 <input
//                                     className={s['input-modal']}
//                                     name="repeatPassword"
//                                     type="password"
//                                     value={repeatPassword}
//                                     onChange={(e) => setRepeatPassword(e.target.value)}
//                                     placeholder={t('repeatPassword')}
//                                 />
//                                 {errorMessage && <p className={s['error-msg']}>{errorMessage}</p>}
//                             </div>
//                         )}
//                         <div className={s['modal-buttons-wrap']}>
//                             <button onClick={handleCloseModal} className={s['cancel-button']}>
//                                 {t('otmena')}
//                             </button>
//                             <button
//                                 onClick={handleNextClick}
//                                 className={s['continue-button']}
//                                 disabled={
//                                     step === 4 && form.moderator_password !== repeatPassword
//                                         ? true
//                                         : false
//                                 }
//                             >
//                                 {t('continue')}
//                             </button>
//                         </div>
//                     </>
//                 )}
//             </div>
//         </CustomModal>
//     );
// };
// export default CreateBranchModal;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, TimePicker } from 'antd';
import { Option } from 'antd/es/mentions';
import { createBranch } from '../../api/admin/functions';
import dayjs from 'dayjs';
import useCities from '../../api/common/common-hooks';
import doneIcon from '../../assets/done-icon.svg';

import CustomModal from '../UI/Modal/Modal';
import s from './styles.module.css';

const CreateBranchModal = ({ visible, onCloseModal, updateData }) => {
    const { t } = useTranslation();
    const { data: cities } = useCities();

    const [step, setStep] = useState(1);
    const [form, setForm] = useState({ main: true });
    const [timeRange, setTimeRange] = useState({});
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (name, value) => {
        setForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleTimeRangeChange = (name, value) => {
        setTimeRange((prev) => ({ ...prev, [name]: value }));
    };

    const handleNextClick = () => {
        if (step === 4) {
            confirmCreateBranch();
        } else {
            setStep((prev) => prev + 1);
        }
    };

    const handleCloseModal = () => {
        onCloseModal();
        setStep(1);
        setForm({});
        updateData();
    };

    const confirmCreateBranch = () => {
        createBranch(form)
            .then(() => setIsSuccess(true))
            .catch((err) => setErrorMessage(err?.response?.data?.msg || t('errorMessage')));
    };

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            working_hour: `${timeRange?.fromTime?.slice(0, 5)}-${timeRange?.toTime?.slice(0, 5)}`,
        }));
    }, [timeRange]);

    return (
        <CustomModal visible={visible}>
            <div className={s['modal-container']}>
                {isSuccess ? (
                    <div className={s['success-modal']}>
                        <p className={s['modal-title']}>{t('branchSuccess')}</p>
                        <img src={doneIcon} alt="done" />
                        <button
                            onClick={onCloseModal}
                            className={s['continue-button']}
                            style={{ width: '80%' }}
                        >
                            {t('continue')}
                        </button>
                    </div>
                ) : (
                    <>
                        <p className={s['modal-title']}>{t('addBranch')}</p>
                        {step !== 1 && (
                            <button
                                className={s['secondary-button']}
                                onClick={() => setStep((prev) => prev - 1)}
                            >
                                {t('backToMain')}
                            </button>
                        )}
                        {step === 1 && (
                            <div className={s['form-wrap']}>
                                <p className={s['input-header']}>{t('generalData')}</p>
                                <input
                                    className={s['input-modal']}
                                    name="name"
                                    value={form.name}
                                    onChange={(e) => handleInputChange('name', e.target.value)}
                                    placeholder={t('branchName')}
                                />
                                <Select
                                    style={{ width: '100%', height: '3.5rem' }}
                                    onChange={(e) => handleInputChange('city_id', e)}
                                    placeholder={t('selectCity')}
                                    size={'large'}
                                >
                                    {cities?.map((item) => (
                                        <Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                                <input
                                    className={s['input-modal']}
                                    name="address"
                                    value={form.address}
                                    onChange={(e) => handleInputChange('address', e.target.value)}
                                    placeholder={t('address')}
                                />
                                <p className={s['input-header']}>{t('schedule')}</p>
                                <div className={s['buttons-wrap']}>
                                    <TimePicker
                                        onChange={(_, value) =>
                                            handleTimeRangeChange('fromTime', value)
                                        }
                                        defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                        placeholder="От"
                                        className={s['width-inherit']}
                                        size={'large'}
                                    />
                                    <span className={s['line']} />
                                    <TimePicker
                                        onChange={(_, value) =>
                                            handleTimeRangeChange('toTime', value)
                                        }
                                        defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                        placeholder="До"
                                        className={s['width-inherit']}
                                        size={'large'}
                                    />
                                </div>
                                <input
                                    id="lunchBreak"
                                    className={s['input-modal']}
                                    value={form.lunch_break}
                                    onChange={(e) =>
                                        handleInputChange('lunch_break', e.target.value)
                                    }
                                    placeholder={t('selectLunchBreak')}
                                    label={t('lunchBreak')}
                                />
                                <input
                                    id="dayOffs"
                                    className={s['input-modal']}
                                    value={form.day_offs}
                                    onChange={(e) => handleInputChange('day_offs', e.target.value)}
                                    placeholder={t('selectDayOffs')}
                                    label={t('dayOffs')}
                                />
                            </div>
                        )}
                        {step === 2 && (
                            <div className={s['form-wrap']}>
                                <p className={s['input-header']}>{t('responsableInfo')}</p>
                                <input
                                    className={s['input-modal']}
                                    name="responsableName"
                                    value={form.moderator_name}
                                    onChange={(e) =>
                                        handleInputChange('moderator_name', e.target.value)
                                    }
                                    placeholder={t('responsableName')}
                                />
                                <input
                                    className={s['input-modal']}
                                    name="responsableSurname"
                                    value={form.moderator_lastname}
                                    onChange={(e) =>
                                        handleInputChange('moderator_lastname', e.target.value)
                                    }
                                    placeholder={t('responsableSurname')}
                                />
                            </div>
                        )}
                        {step === 3 && (
                            <div className={s['form-wrap']}>
                                <p className={s['input-header']}>{t('contactDetails')}</p>
                                <input
                                    className={s['input-modal']}
                                    name="address"
                                    value={form.moderator_phone}
                                    onChange={(e) =>
                                        handleInputChange('moderator_phone', e.target.value)
                                    }
                                    placeholder={t('phone')}
                                    type={'number'}
                                />
                            </div>
                        )}
                        {step === 4 && (
                            <div className={s['form-wrap']}>
                                <input
                                    className={s['input-modal']}
                                    name="moderator_password"
                                    type="password"
                                    value={form.moderator_password}
                                    onChange={(e) =>
                                        handleInputChange('moderator_password', e.target.value)
                                    }
                                    placeholder={t('password')}
                                />
                                <input
                                    className={s['input-modal']}
                                    name="repeatPassword"
                                    type="password"
                                    value={repeatPassword}
                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                    placeholder={t('repeatPassword')}
                                />
                                {errorMessage && <p className={s['error-msg']}>{errorMessage}</p>}
                            </div>
                        )}
                        <div className={s['modal-buttons-wrap']}>
                            <button onClick={handleCloseModal} className={s['cancel-button']}>
                                {t('otmena')}
                            </button>
                            <button
                                onClick={handleNextClick}
                                className={s['continue-button']}
                                disabled={
                                    step === 4 && form.moderator_password !== repeatPassword
                                        ? true
                                        : false
                                }
                            >
                                {t('continue')}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </CustomModal>
    );
};
export default CreateBranchModal;
