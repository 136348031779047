import s from './styles.module.css'
import React from "react";


const NewSelector =({
                        toggleDropdown,
                        number,
                        dropdownVisible,
                        handleSelectValue,
                        t
}) => {
    return (
        <div
            className={`${s['select-container']}`}
            onClick={toggleDropdown}
        >
            <div className={s['select-wrapper']}>
                <span className={s['label-style']} htmlFor="orders">{t('selectorChoose')}</span>
                <div className={s['select-style']} id="orders">
                    <span className={s['static-text']}>{t('selectorShow')}</span>
                    {number}
                </div>
                {dropdownVisible && (
                    <div className={s['dropdown']}>
                        <div
                            className={`${s['option-style']} ${number === 10 ? s['selected'] : ''}`}
                            onClick={() => handleSelectValue(10)}
                        >
                            10
                        </div>
                        <div
                            className={`${s['option-style']} ${number === 50 ? s['selected'] : ''}`}
                            onClick={() => handleSelectValue(50)}
                        >
                            50
                        </div>
                        <div
                            className={`${s['option-style']} `}
                            onClick={() => handleSelectValue(100)}
                        >
                            100
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default NewSelector