import React, { useEffect, useState } from 'react';
import { getBranchListByFilter } from '../../api/admin/functions';
import { useTranslation } from 'react-i18next';
import CreateBranchModal from './CreateBranchModal';
import EditBranchForm from './EditBranchForm';

import s from './styles.module.css';

export const columns = [
    'city',
    'address',
    'index',
    'datetime',
    'branchResponsible',
    'email',
    'phoneNumber',
    'additional',
];

const AdminBranchPage = ({ role }) => {
    const { t } = useTranslation();

    const [searchInput, setSearchInput] = useState('');
    const [filialsList, setFilialisList] = useState([]);

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState({});

    const handleEditBranchClick = (branch) => {
        setSelectedBranch(branch);
        setShowForm(true);
    };

    const getAndUpdateData = () => {
        getBranchListByFilter({}).then((data) => setFilialisList(data.content));
    };

    useEffect(() => {
        if (searchInput) {
            getBranchListByFilter({ name: searchInput }).then((data) =>
                setFilialisList(data.content),
            );
        } else {
            getAndUpdateData();
        }
    }, [searchInput]);
    useEffect(() => {
        getAndUpdateData();
    }, []);

    return (
        <div className={s['container']}>
            {showForm ? (
                <EditBranchForm
                    defaultForm={selectedBranch}
                    onBreadcrupmClick={() => {
                        setShowForm(false);
                        getAndUpdateData();
                    }}
                />
            ) : (
                <>
                    <div className={s['search-wrap']}>
                        <h1 className={s['title']}>{t('branches')}</h1>
                        {role === 'RoleLeadAdmin' && (
                            <button
                                className={s['primary-button']}
                                onClick={() => setOpenCreateModal(true)}
                            >
                                {t('createBranch')}
                            </button>
                        )}
                    </div>
                    <input
                        className={s['input-style']}
                        placeholder={t('searchInputBranch')}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />

                    <div className={s['table-outside']}>
                        <div className={s['table-columns-wrap']}>
                            {columns.map((column, index) => (
                                <span key={index} className={s['table-cell']}>
                                    {t(column)}
                                </span>
                            ))}
                        </div>
                        <div className={s['table-data']}>
                            {filialsList.map((row, index) => (
                                <div key={index} className={s['table-columns-wrap']}>
                                    <span className={s['table-cell']}>{row.name}</span>
                                    <span className={s['table-cell']}>{row.address}</span>
                                    <span className={s['table-cell']}>{row.index}</span>
                                    <span className={s['table-cell']}>{row.workingHour}</span>
                                    <span
                                        className={s['table-cell']}
                                    >{`${row.moderator_lastname || ''} ${row.moderator_name || ''}`}</span>
                                    <span className={s['table-cell']}>{row.moderator_email}</span>
                                    <span className={s['table-cell']}>{row.moderator_phone}</span>
                                    <span className={s['table-cell']}>
                                        {role === 'RoleLeadAdmin' && (
                                            <button
                                                className={s['secondary-button']}
                                                onClick={() => handleEditBranchClick(row)}
                                            >
                                                {t('changeButton')}
                                            </button>
                                        )}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
            <CreateBranchModal
                visible={openCreateModal}
                onCloseModal={() => setOpenCreateModal(false)}
                updateData={getAndUpdateData}
            />
        </div>
    );
};
export default AdminBranchPage;
