import axios from 'axios';
import { baseURL } from '../baseURL';
import Cookies from 'js-cookie';

export const getReportTurkey = async () => {
    const { data } = await axios.post(`${baseURL}amantrans/report/turkey`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
        },
    });

    return data;
};

export const getReportChina = async () => {
    const { data } = await axios.post(`${baseURL}amantrans/report/china`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
        },
    });

    return data;
};

export const getChinaParameters = async () => {
    const { data } = await axios.get(`${baseURL}amantrans/report-parameters/china`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
        },
    });

    return data;
};

export const getTurkeyParameters = async () => {
    const { data } = await axios.get(`${baseURL}amantrans/report-parameters/turkey`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
        },
    });

    return data;
};
