import s from "../../pages/info-page/styles.module.css";

export function TarifIcons ({styleSVG}) {
    return (
        <svg className={s[`${styleSVG}`]} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18.2305 9.17627C18.7828 9.17627 19.2305 9.62398 19.2305 10.1763V20.1763C19.2305 20.7286 18.7828 21.1763 18.2305 21.1763C17.6782 21.1763 17.2305 20.7286 17.2305 20.1763V10.1763C17.2305 9.62398 17.6782 9.17627 18.2305 9.17627Z"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.2305 3.17627C12.7828 3.17627 13.2305 3.62398 13.2305 4.17627V20.1763C13.2305 20.7286 12.7828 21.1763 12.2305 21.1763C11.6782 21.1763 11.2305 20.7286 11.2305 20.1763V4.17627C11.2305 3.62398 11.6782 3.17627 12.2305 3.17627Z"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.23047 13.1763C6.78275 13.1763 7.23047 13.624 7.23047 14.1763V20.1763C7.23047 20.7286 6.78275 21.1763 6.23047 21.1763C5.67818 21.1763 5.23047 20.7286 5.23047 20.1763V14.1763C5.23047 13.624 5.67818 13.1763 6.23047 13.1763Z"
            />
        </svg>
    )
}