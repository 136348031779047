import { useContext, useState } from "react";
import { UserInfoContext } from "../index";

import { Button, Input, Typography } from "antd";
import s from "../styles.module.css";
import useCreateUser from "../../../../api/auth/auth-hooks";

export default function SmsCodeForm({ setIsNextStep }) {
  const [smsCode, setSmsCode] = useState("");
  const { mutate, } = useCreateUser();
  const context = useContext(UserInfoContext);
    const number= context.user.phone
  const onChange = (e) => {
    setSmsCode(e.target.value);
  };
  const onNext = () => {
    context.setUser({
      username: "",
        phone: number,
      password: "",
      smsCode,
        cityId: "",
        filialId: "",
      address: "",
      house: "",
    });
    mutate({
      username: "",
        phone: number,
        cityId: "",
        filialId: "",
      password: "",
      smsCode,
      address: "",
      house: "",
    });
    setIsNextStep("password");
  };

  return (
    <>
      <Typography.Title style={{ color: "white", fontWeight: "400" }} level={3}>
        Введите СМС-код
      </Typography.Title>
      <div style={{ textAlign: "center" }}>
        <Typography.Text style={{ color: "white", fontWeight: "700" }}>
          Мы отправили код на номер
        </Typography.Text>
      </div>
      <div style={{ textAlign: "center" }}>
        <Typography.Text style={{ color: "white" }}>
          {context.user.phoneNumber}
        </Typography.Text>
      </div>
      <Input
        style={{ marginTop: "20px" }}
        placeholder="КОД"
        type={'number'}
        className={s["register-input"]}
        onChange={onChange}
        autoComplete="one-time-code"
      />
      <Button style={{ width: "100%", marginTop: "20px" }}>
        <Typography.Text
          style={{ color: "#100E71", fontWeight: "500" }}
          onClick={onNext}
        >
          Продолжить
        </Typography.Text>
      </Button>
    </>
  );
}
