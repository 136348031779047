import React from 'react';
import s from './styles.module.css';

const CustomSelector = ({ options, value, onChange }) => {
    return (
        <select className={s['customSelect']} value={value} onChange={onChange}>
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default CustomSelector;